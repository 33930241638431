import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CertificateCalc from "./CertificateCalc";
import CertificateResult from "./CertificateResult";
import "./CertificateValidation.scss";
import { isEmpty } from "lodash";

const CertificateValidation = () => {
  const [calcVisible, setCalcVisible] = useState(true);
  const [resultData, setResultData] = useState(null);
  const { t } = useTranslation("translation", {
    keyPrefix: "certCalc",
  });

  useEffect(() => {
    if (resultData) {
      setCalcVisible(false);
    }
  }, [resultData]);

  const onReturn = () => {
    setCalcVisible(true);
    setResultData(null);
  };

  return (
    <div
      className="certificate-validation-tool"
      data-testid="certificate-validation-test"
    >
      <div className="certificate-validation-tool__content">
        <div className="certificate-validation-tool__text-content">
          <h2 className="certificate-validation-tool__title">{t("title")}</h2>
          <p className="certificate-validation-tool__description">
            {t("description")}
          </p>
        </div>
        {calcVisible && <CertificateCalc setData={setResultData} />}
        {!calcVisible && !isEmpty(resultData) && !isEmpty(resultData?.certType) && (
            <CertificateResult
              data={resultData}
              onReturn={onReturn}
              certType={resultData?.certType}
            />
          )}
      </div>
    </div>
  );
};

export default CertificateValidation;
