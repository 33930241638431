import axios from "axios"

const fetchResults = (query, params) => {
  const storedLanguage = localStorage.getItem("i18nextLng");
  const data = axios.get(process.env.REACT_APP_FULL_URL + '/api/v1/search/' + query, {
    params: {
      show_more: params.show_more ? params.show_more : undefined,
      search_dropdown: params.search_dropdown ? 1 : undefined,
      "f[0]": 'materials:' + params.materials,
      items_per_page: params.items_per_page,
      page: params.page,
      sort_by: params.sort_by,
      language: storedLanguage,
      ...params.topics
    }
  })
    .then(resp => {
      return resp.data;
    })

  return data;
}

export default fetchResults;