import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./SaudiLaborSystemHero.scss";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";

const App = ({header, content}) => {
  return (
    <Container fluid className="saudi-labor-system-hero">
      <Breadcrumbs theme="light">
        <span>{header}</span>
      </Breadcrumbs>
      <Row className="">
        <p className="saudi-labor-system-hero__heading">{header}</p>
        <Col lg={7}>
        <p className="saudi-labor-system-hero__text">
        {content}
        </p>
        </Col>
      </Row>
    </Container>
  );
};

export default App;
