import React, { useContext,useState,useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./ServerError.scss";
import classnames from "classnames";
import { MainContext } from "../../../contexts/context";
import { fetchErrorData } from "../../../../FetchError.js";

function ServerError({ code}) {
  const navigate = useNavigate();
  const { theme } = useContext(MainContext);
  const [errorData, setErrorData] = useState({});

  const { t } = useTranslation("translation", {
    keyPrefix: "errors.500error",
  });
  useEffect(() => {
    const fetchData = async () => {
      const fetchCode = code === 404 ? "404?include=field_relevant_page" : code;
      const data = await fetchErrorData(fetchCode);
      if (localStorage.getItem("i18nextLng") === "ar") {
        if (data && data.data) {
          setErrorData(data.data[1]);
        }
      } else {
        if (data && data.data) {
          setErrorData(data.data[0]);
        }
      }

    };

    fetchData();
  }, [code, localStorage.getItem("i18nextLng")]);
  const { attributes } = errorData;

  const refreshPage = () => {
    navigate(-1);
  };

  return (
    <Container
      fluid
      className={classnames({
        "server-container": true,
        "server-container--ltr": theme === "english",
        "server-container--rtl": theme !== "english",
      })}
    >
      <Row>
        <Col className="server-content">
          <Breadcrumbs />
          <p className="server-header">{attributes?.field_label}</p>
          <p className="server-error-code">
            {t("errorCode", { code})}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: attributes?.field_description?.value,
            }}
          ></p>
          <button className="server-button" onClick={refreshPage} data-testid='test-server-error-button'>
            <p className="button-text">{t("button")}</p>
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default ServerError;

ServerError.defaultProps = {
  code: 500,
};