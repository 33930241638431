import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import { composeDate } from "../../utils/composeDate";
import { mockedData } from "./mockedData";
import classnames from "classnames";
import { Message, Box, Text } from "@takamol/qiwa-design-system/components";

const CertificateResult = ({ data, onReturn }) => {
  const dateFields = ["SCCreationDate", "SCExpiryDate", "ServiceStartDate", "ServiceEndDate", "SaudiCertIssueDate", "SaudiCertExpiryDate"];
  const { CertificateStatus } = data;
  const { t } = useTranslation("translation", {
    keyPrefix: "certCalc",
  });

  const certStatuses = ['Active', 'Inactive', 'Expired', "Verified"];

  const rowsVariants = {
    "Nationalization certificate": "saudizationCertificateRows",
    "Salary Certificate": "salaryCertificateRows",
    "Compliance certificate": "complianceCertRows",
    "Service Certificate": "serviceCertificateRows",
    "Debt Certificate": "debtCertificateRows"
  }

  const certTypeTranslations = {
    "Nationalization certificate": t("certOptions.saudizationCert"),
    "Salary Certificate": t("certOptions.salaryCert"),
    "Compliance certificate": t("certOptions.complianceCert"),
    "Service Certificate": t("certOptions.serviceCert"),
    "Debt Certificate": t("certOptions.debtCert"),
  }

  return (
    <div
      className="certificate-validation-tool__calc-result"
      data-testid="cert-calc-result-test"
    >
      <button
        onClick={onReturn}
        className="certificate-validation-tool__return-btn certificate-validation-tool__return-btn--top"
        data-testid="cert-calc-result-return-test"
      >
        <Icon name="chevron-left" />
        {t("backBtn")}
      </button>
      <ul className="certificate-validation-tool__result-list">
        <li className="certificate-validation-tool__result-item">
          <p className="certificate-validation-tool__validation-result">
            {t("result.title")}
          </p>
          {CertificateStatus && <p
            className={classnames(
              "certificate-validation-tool__cert-status",
              CertificateStatus && ("certificate-validation-tool__cert-status--" + CertificateStatus.CertificateStatusEn?.toLowerCase()),
              "certificate-validation-tool__cert-status--" +
                (certStatuses.includes(CertificateStatus.CertificateStatusEn) ? "" : "default")
            )}
          >
            <Icon
              name={
                (CertificateStatus.CertificateStatusEn !== "Active" && CertificateStatus.CertificateStatusEn !== "Verified") ? "close" : "checkmark"
              }
              height="16"
              width="16"
            />
            <span className="translation--en">
              {CertificateStatus.CertificateStatusEn}
            </span>
            <span className="translation--ar">
              {CertificateStatus.CertificateStatusAr}
            </span>
          </p>}
        </li>
        <li>
          <Message variant={"info"} >
            <Box>
              <Text variant="body-m">
                {t('resultDisclaimer')}
              </Text>
            </Box>
          </Message>
        </li>
        {mockedData[`${rowsVariants[`${data.certType}`]}`].map((row, index) => {
          return (
            <li
              className="certificate-validation-tool__result-item"
              key={index}
            >
              <p className="certificate-validation-tool__result-item-label">
                {t(`result.${row.field}`)}
              </p>
              {row.field === "CertificateType" && (
                <p className="certificate-validation-tool__result-item-value">
                  {certTypeTranslations[data.certType]}
                </p>
              )}
              {row.field !== "CertificateType" &&
                typeof data[row.field] === "object" && (
                  <p className="certificate-validation-tool__result-item-value">
                    <span className="translation--en">
                      {data[row.field][`${row.field}En`]
                        ? data[row.field][`${row.field}En`]
                        : data[row.field][`${row.field}Eng`]
                        ? data[row.field][`${row.field}Eng`]
                        : data[row.field][`${row.field}Ar`]}
                    </span>
                    <span className="translation--ar">
                      {data[row.field][`${row.field}Ar`]
                        ? data[row.field][`${row.field}Ar`]
                        : data[row.field][`${row.field}En`]
                        ? data[row.field][`${row.field}En`]
                        : data[row.field][`${row.field}Eng`]}
                    </span>
                  </p>
                )}
              {row.field !== "CertificateType" &&
                typeof data[row.field] !== "object" && (
                  <p className="certificate-validation-tool__result-item-value">
                    {row.field !== "CertificateType" &&
                    dateFields.includes(row.field)
                      ? composeDate(data[row.field])
                      : data[row.field]}{" "}
                    {row.field === "TotalSalary" && (
                      <span>{t("currency")}</span>
                    )}
                  </p>
                )}
            </li>
          );
        })}
      </ul>
      <button
        onClick={onReturn}
        className="certificate-validation-tool__return-btn certificate-validation-tool__return-btn--bottom"
      >
        {t("backBtn")}
      </button>
    </div>
  );
};

CertificateResult.propTypes = {
  data: PropTypes.shape({
    CertificateStatus: PropTypes.shape({
      CertificateStatusEn: PropTypes.string,
      CertificateStatusAr: PropTypes.string,
    }),
    certType: PropTypes.string,
  }),
  onReturn: PropTypes.func,
};

CertificateResult.defaultProps = {
  data: {
    CertificateStatus: {
      CertificateStatusEn: "",
    },
    certType: "Service Certificate"
  },
  onReturn: () => {
    return;
  },
};

export default CertificateResult;
