import React, { useEffect, useState } from "react";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";
import {
  NumericField,
  Message,
  Box,
  Text,
} from "@takamol/qiwa-design-system/components";

const RangeInput = ({
  id,
  register,
  getValues,
  setValue,
  farmersCountExceedLimit,
  totalCountExceedLimit,
}: Props): React.ReactNode => {
  const { t } = useTranslation("translation", {
    keyPrefix: "wpCalc.calc.bottomBlock",
  });
  const [inputValue, setInputValue] =useState<any>(0);
  const [isFocused, setIsFocused] = useState(false);
  
  useEffect(() => {
    setInputValue(getValues(id) || 0);
  }, [id, getValues]);

  const updateValue = (newValue: number) => {
    setValue(id, newValue);
    setInputValue(newValue);
  };

  const onButtonClick = (type: string) => {
    const currentValue = inputValue;
    const newValue = type === "increase" ? currentValue + 1 : currentValue - 1;

    if (newValue >= 0) {
      updateValue(newValue);
    }
  };

  const onInputChange = (value: string) => {
    const numericValue = value === '' ? 0 : parseInt(value, 10);
    updateValue(numericValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (inputValue === 0) {
      setInputValue('');
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (inputValue === null) {
      setInputValue(getValues(id) || 0); 
    }
  };

  return (
    <>
      {farmersCountExceedLimit && id === "farmers" && (
        <Message variant={"danger"}>
          <Box>
            <Text variant="body-m">{t("errors.farmersCountExceedLimit")}</Text>
          </Box>
        </Message>
      )}

      <div className="wp-calculator__range-input-wrapper">
        <div className="wp-calculator__range-input-label">
          <h6>{t(`inputs.${id}.title`)}</h6>
          <p>{t(`inputs.${id}.description`)}</p>
        </div>
        <div className="wp-calculator__range-input">
          <button
            onClick={() => onButtonClick("decrease")}
            type="button"
            className="wp-calculator__range-button"
          >
            <Icon name="minus" />
          </button>

          <NumericField
            {...register(`${id}`, { valueAsNumber: true })}
            fieldWidth={"100%"}
            onChange={(e) => onInputChange(e.target.value)}
            min={0}
            data-testid={id + "-test-id"}
            allowNumericSymbols={false}
            disabled={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={isFocused || inputValue === null ? "" : "0"}
            value={inputValue === null ? "" : inputValue}
            isError={
              (farmersCountExceedLimit && id === "farmers") ||
              totalCountExceedLimit
                ? true
                : false
            }
          />

          <button
            onClick={() => onButtonClick("increase")}
            type="button"
            className="wp-calculator__range-button"
          >
            <Icon name="plus" />
          </button>
        </div>
      </div>
    </>
  );
};

type Props = {
  id: string;
  register: any;
  disabled: boolean;
  setDisabledFields: any;
  getValues: any,
  setValue: any,
  getLimit: any,
  farmersCountExceedLimit: any,
  totalCountExceedLimit: any;
};

RangeInput.propTypes = {
  farmersCountExceedLimit: () => {
    /** */
  },
};


export default RangeInput;
