import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./LaborEducationArticles.scss";
import Helmet from "react-helmet";
import ArticleBody from "../../Articles/ArticleContainer/Sections/ArticleBody/ArticleBody";
import Text from "../../Articles/Shared/Text/Text";
import TableOfContents from "../../Articles/Shared/TableOfContent/TableOfContent";
import Feedback from "../../../Feedback/Feedback";
import IconCardsGroup from "../../../IconCardsGroup/IconCardsGroup";
import { MainContext } from "../../../contexts/context";
import classnames from "classnames";
import _, { isEmpty } from "lodash";
import Accordion from "../../Articles/Shared/Accordion/Accordion";
import RelatedTopics from "../../Articles/Shared/RelatedTopics/RelatedTopics";
import NotFoundError from "../../ErrorPages/NotFound/NotFound";
import ServerError from "../../ErrorPages/ServerError/ServerError";
import { useTranslation } from "react-i18next";
import { checkLanguageInUrl } from "../../../../utils/checkLanguageInUrl";
import { PageLoader } from "../../../Loader/PageLoader";
import useNavigationHistory from "../../../../hooks/useNavigationHistory";
import { extractSpecialUrlsFromHistory } from "../../../../utils/extractSpecialUrlsFromHistory";
import LaborEducationArticleHero from "./LaborEducationArticleHero";
import generalDefinitions from "./mockData";

const Article = () => {
  const { alias } = useParams();
  const contextValue = useContext(MainContext);
  const { theme } = contextValue;
  const [jsonData, setJsonData] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const [openGeneralIndex, setOpenGeneralIndex] = useState(null);
  const [isServerError, setIsServerError] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const navigationHistory = useNavigationHistory();
  const specialUrls = extractSpecialUrlsFromHistory(navigationHistory);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setIsScrolled(position > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        setIsDataLoading(true);
        const storedLanguage = localStorage.getItem("i18nextLng");
        const urlLanguage = checkLanguageInUrl(window.location.pathname);
        const language =
          storedLanguage === urlLanguage ? storedLanguage : urlLanguage;
        const urlPath = [alias].filter(Boolean).join("/");
        let endpoint = `${process.env.REACT_APP_FULL_URL}/api/v1/content?alias=/labor-education/${urlPath}`;

        if (
          specialUrls?.serviceCategory == "/labor-education"
        ) {
          endpoint += `&category=${specialUrls.serviceCategory}`;
        }
        if (language === "ar") {
          endpoint += "&language=ar";
        }
        const response = await fetch(endpoint);
        if (!response.ok) {
          setIsDataLoading(false);
          if (response.status === 404) {
            setIsNotFound(true);
            return;
          }
          if ([500, 502, 503, 504].includes(response.status)) {
            setIsServerError(response.status);
            return;
          }
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const content = data.content;

        const orderedComponents = Object.keys(content).map((key) => {
          return {
            order: parseInt(key, 10),
            ...content[key],
          };
        });

        const sortedComponents = _.sortBy(orderedComponents, ["order"]);
        setJsonData(sortedComponents);
        setIsDataLoading(false);
      } catch (error) {
        setIsDataLoading(false);
        console.error(error);
      }
    };

    fetchArticleData();
  }, [alias, theme]);

  function removeTrailingChars(str, chars) {
    let end = str.length;
    while (end > 0 && chars.includes(str[end - 1])) {
      end--;
    }
    return str.substring(0, end);
  }
  useEffect(() => {
    if (jsonData) {
      if (window.location.hash) {
        let hashValue = decodeURIComponent(window.location.hash.substring(1));
        const normalizedHash = removeTrailingChars(hashValue, "-؟?");
        let element = document.getElementById(hashValue);
        if (!element) {
          element =
            document.getElementById(`${normalizedHash}?`) ||
            document.getElementById(`${normalizedHash}-؟`) ||
            document.getElementById(`${normalizedHash}؟`);
        }
        if (!element) {
          element = document.getElementById(normalizedHash);
        }
        if (element) {
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset - 162;
          window.scrollTo({ top: elementPosition, behavior: "instant" });
        }
      } else {
        window.scrollTo({ top: 0, behavior: "instant" });
      }
    }
  }, [jsonData]);

  const formatTitleForHash = (title) => {
    return title.replace(/\s+/g, "-").replace(/؟/g, "-؟").toLowerCase();
  };

  const toggleFaqAccordion = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };
  const toggleGeneralAccordion = (index) => {
    setOpenGeneralIndex(openGeneralIndex === index ? null : index);
  };

  if (isNotFound) {
    return <NotFoundError />;
  }

  if (isServerError) {
    return <ServerError />;
  }

  const headline =
    jsonData?.find((section) => section.type === "hero_banner_labor_education")
      ?.title || "Article";
  sessionStorage.setItem("pageTitle", headline);

  const extractHeadings = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const headings = [];
    doc.querySelectorAll("h1, h2").forEach((heading) => {
      headings.push({
        text: heading.textContent,
        id: formatTitleForHash(heading.textContent),
        level: heading.tagName.toLowerCase(),
      });
    });
    return headings;
  };

  const tableOfContentsData = jsonData
    ?.filter((item) => item.type === "text")
    .flatMap((item) => {
      const headings = extractHeadings(item.content);
      return headings.map((heading) => ({
        text: heading.text,
        link: `#${heading.id}`,
        level: heading.level,
      }));
    });

  return (
    <div className="labor-education-article">
      <Helmet>
        <title>{headline}</title>
        <meta property="og:title" content={`${headline} | Qiwa`} />
      </Helmet>
      {isDataLoading && <PageLoader />}
      {jsonData?.map((component, index) => {
        if (component.type === "hero_banner_labor_education") {
          return (
            <div key={index}>
              <LaborEducationArticleHero
                data={component}
                breadcrumbs={component.breadcrumbs}
              />
            </div>
          );
        }
        return null;
      })}

      <Container className="labor-education-article__container p-0">
        <Row className="m-0">
          {jsonData?.some(
            (component) =>
              component.type === "table_of_contents" &&
              component.isActive &&
              !isEmpty(tableOfContentsData)
          ) && (
            <Col lg={4} className="table-of-contents-sticky p-0">
              <div
                className={classnames({
                  "sticky-top": true,
                  "sticky-top--rtl": theme !== "english",
                  scrolled: isScrolled,
                })}
              >
                {jsonData?.some(
                  (component) =>
                    component.type === "table_of_contents" && component.isActive
                ) && (
                  <TableOfContents
                    title="Table of contents"
                    contents={tableOfContentsData}
                  />
                )}
              </div>
            </Col>
          )}
          <Col
            lg={8}
            className={classnames({
              "labor-education-article__body": true,
              "labor-education-article__body--rtl": theme !== "english",
            })}
          >
            {jsonData?.map((component, index) => {
              switch (component.type) {
                case "text":
                  return (
                    <Text
                      key={index}
                      id={formatTitleForHash(component.title)}
                      title={component.title}
                      text={component.content}
                      copyText={true}
                    />
                  );

                case "faq":
                  return (
                    <div
                      key={index}
                      className="labor-education-article__questions"
                    >
                      <p className="labor-education-article__popular-questions">
                        {component.title}
                      </p>
                      {component.questions.map((questionItem, qIndex) => (
                        <div
                          className="labor-education-article__question-container"
                          key={qIndex}
                        >
                          <Accordion
                            question={questionItem.question}
                            index={formatTitleForHash(questionItem.question)}
                            isOpen={openFaqIndex === qIndex}
                            toggleAccordion={() => toggleFaqAccordion(qIndex)}
                          >
                            <ArticleBody data={questionItem.answer} />
                          </Accordion>
                        </div>
                      ))}
                    </div>
                  );

                default:
                  return null;
              }
            })}
            <div className="labor-education-article__questions">
              <p className="labor-education-article__popular-questions">
                {t("generalDefinitions")}
              </p>
              {generalDefinitions.map((questionItem, index) => {
                
                const questionText =
                  theme === "arabic"
                    ? questionItem.question_ar
                    : questionItem.question;
                const answerText =
                  theme === "arabic"
                    ? questionItem.answer_ar
                    : questionItem.answer;

                return (
                  <div
                    key={questionItem}
                    className={`labor-education-article__question-container ${
                      theme === "ar" ? "rtl" : ""
                    }`}
                  >
                    <Accordion
                      question={questionText}
                      index={formatTitleForHash(questionText)}
                      isOpen={openGeneralIndex == index}
                      toggleAccordion={() => toggleGeneralAccordion(index)}
                      isCopy={false}
                    >
                      <ArticleBody data={answerText} />
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
      {jsonData?.map((component, index) => {
        if (component.type === "related_labor_education_articles") {
          return <RelatedTopics key={index} data={component.articles} />;
        }
        return null;
      })}
      <Feedback />
      {jsonData?.map((component, index) => {
        if (component.type === "why_qiwa_platform") {
          return (
            <IconCardsGroup key={index} groupData={component} variant="dark" />
          );
        }
        return null;
      })}
    </div>
  );
};

export default Article;
