import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import ActionTilesGroup from "../../../ActionTilesGroup/ActionTilesGroup";
import { MainContext } from "../../../contexts/context";
import { AccordionOuterWrapper } from "../../../../utils/AccordionOuterWrapper";
import useFetchContent from "../../../../hooks/useFetchContent";
import FilteredTiles from "./FilteredTiles";

const IndividualsTab = () => {
  const contextValue = useContext(MainContext);
  const {
    getHired,
    manageYourCurrentJob,
    manageHomeWorkers,
    manageQiwaIndividualAccount,
    laborOfficeServices,
    toolsAndCalculators,
  } = contextValue;
  const { contentByType } = useFetchContent();

  return (
    <AccordionOuterWrapper>
      <div className="individuals-tab" data-testid="individuals-tab-test">
        {getHired && (
          <ActionTilesGroup
            title={getHired.title}
            titleLink={getHired.titleLink}
            cards={getHired.cards}
            description={getHired.description}
            groupCta={getHired.groupCta}
            theme={getHired.theme}
            useAccordion={true}
            eventKey={"1"}
          />
        )}
        {manageYourCurrentJob && (
          <ActionTilesGroup
            title={manageYourCurrentJob.title}
            titleLink={manageYourCurrentJob.titleLink}
            cards={manageYourCurrentJob.cards}
            description={manageYourCurrentJob.description}
            groupCta={manageYourCurrentJob.groupCta}
            theme={manageYourCurrentJob.theme}
            useAccordion={true}
          />
        )}
        {manageHomeWorkers && (
          <ActionTilesGroup
            title={manageHomeWorkers.title}
            titleLink={manageHomeWorkers.titleLink}
            cards={manageHomeWorkers.cards}
            description={manageHomeWorkers.description}
            groupCta={manageHomeWorkers.groupCta}
            theme={manageHomeWorkers.theme}
            useAccordion={true}
          />
        )}
        {manageQiwaIndividualAccount && (
          <ActionTilesGroup
            title={manageQiwaIndividualAccount.title}
            titleLink={manageQiwaIndividualAccount.titleLink}
            cards={manageQiwaIndividualAccount.cards}
            description={manageQiwaIndividualAccount.description}
            groupCta={manageQiwaIndividualAccount.groupCta}
            theme={"individuals"}
            useAccordion={true}
          />
        )}
        {laborOfficeServices && (
          <ActionTilesGroup
            title={laborOfficeServices.title}
            titleLink={laborOfficeServices.titleLink}
            cards={laborOfficeServices.cards}
            description={laborOfficeServices.description}
            groupCta={laborOfficeServices.groupCta}
            theme={"individuals"}
            useAccordion={true}
          />
        )}
        <div className="tools-block">
          {toolsAndCalculators && (
            <ActionTilesGroup
              title={toolsAndCalculators.title}
              description={toolsAndCalculators.description}
              cards={toolsAndCalculators.cards}
              useAccordion={false}
            />
          )}
          {contentByType?.tiles && (
            <FilteredTiles
              tiles={contentByType.tiles}
              categoryFilters={["For Employees","للموظفين"]}
            />
          )}
        </div>
      </div>
    </AccordionOuterWrapper>
  );
};

export default IndividualsTab;
