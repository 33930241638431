const mockedData = {
  business: [
    {
      label: "manageQiwaAccount",
      url: "/service-overview/business-owners/manage-qiwa-business-account/manage-qiwa-account",
    },
    {
      label: "subscriptionsAndUserManagement",
      url: "/service-overview/business-owners/manage-qiwa-business-account/subscriptions-and-user-management",
    },
    {
      label: "instantWorkVisa",
      url: "/service-overview/business-owners/hire-employees/instant-work-visas",
    },
    {
      label: "employeeTransfer",
      url: "/service-overview/business-owners/hire-employees/employee-transfer"
    },
    {
      label: "workPermits",
      url: "/service-overview/business-owners/hire-employees/work-permits",
    },
    {
      label: "occupationManagement",
      url: "/service-overview/business-owners/manage-current-employees/occupation-management",
    },
    {
      label: "contractManagement",
      url: "/service-overview/business-owners/hire-employees/contract-management",
    },
    {
      label: "certificates",
      url: "/service-overview/business-owners/manage-establishment/certificates",
    },
    {
      label: "internalWorkPolicy",
      url: "/service-overview/business-owners/manage-establishment/internal-work-policy",
    },
    {
      label: "establishmentViolations",
      url: "/service-overview/business-owners/manage-establishment/establishment-violations",
    },
    {
      label: "trainingManagement",
      url: "/service-overview/business-owners/manage-current-employees/training-management",
    },
    {
      label: "laborOfficeAppointments",
      url: "/service-overview/labor-office-services/labor-office-services/labor-office-appointments",
    },
    {
      label: "toolsAndCalculators",
    },
    {
      label: "indicators",
      url: "/service-overview/business-owners/establishment-performance/indicators",
    },
    {
      label: "establishmentMonthlyReport",
      url: "/service-overview/business-owners/establishment-performance/establishment-monthly-report",
    },
    {
      label: "governmentContracts",
      url: "/service-overview/business-owners/manage-establishment/nationalization-operation-and-maintenance-contracts",
    },
    {
      label: "establishmentManagement",
      url: "/service-overview/business-owners/manage-establishment/establishment-management",
    },
    {
      label: "establishmentRegistration",
      url: "/service-overview/business-owners/create-new-establishment/establishment-registration",
    },
    {
      label: "disbursementWageDeduction",
      url: "/service-overview/business-owners/manage-current-employees/disbursement-wage-deductions",
    },
    {
      label: "locationManagement",
      url: "/service-overview/business-owners/manage-establishment/locations-management",
    },
  ],
  individuals: [
    {
      label: "manageQiwaAccount",
      url: "/service-overview/business-owners/manage-qiwa-business-account/manage-qiwa-account",
    },
    {
      label: "resumeManagement",
      url: "/service-overview/employees/manage-your-career-qiwa/resume-management",
    },
    {
      label: "employmentContracts",
      url: "/service-overview/employees/manage-your-current-job/employment-contracts",
    },
    {
      label: "trainingManagement",
      url: "/service-overview/business-owners/manage-current-employees/training-management",
    },
    {
      label: "employmentCertificates",
      url: "/service-overview/employees/manage-your-current-job/employment-certificates",
    },
    {
      label: "homeWorkers",
      url: "/service-overview/employees/manage-your-home-workers/home-workers",
    },
    {
      label: "workPolicy",
      url: "/service-overview/employees/manage-your-current-job/work-policy",
    },
    {
      label: "employeeTransfer",
      url: "/service-overview/business-owners/hire-employees/employee-transfer",
    },
    {
      label: "changeOccupation",
      url: "/service-overview/employees/manage-your-current-job/change-occupation",
    },
    {
      label: "laborOfficeAppointments",
      url: "/service-overview/labor-office-services/labor-office-services/labor-office-appointments",
    },
    {
      label: "toolsAndCalculators",
    },
  ],
  providers: [
    {
      label: "manageQiwaAccount",
      url: "/service-overview/business-owners/manage-qiwa-business-account/manage-qiwa-account",
    },
    {
      label: "workPolicy",
      url: "/service-overview/employees/manage-your-current-job/work-policy",
    },
    {
      label: "toolsAndCalculators",
    },
  ],
  general: [
    {
      label: "qiwaUnifiedNumber",
      url: "",
      number: "920000105",
      inquiryResponseTime: "instant"
    },
    {
      label: "qiwaEmailAddress",
      url: "",
      mail: "support@qiwa.sa"
    },
    {
      label: "whatsApp",
      url: "https://api.whatsapp.com/send/?phone=966594172813&text=&type=phone_number&app_absent=0",
    },
    {
      label: "liveChat",
      url: "",
      zendesk: true
    },
    {
      label: "signLanguageSupport",
      url: "/customer-video-calling-service",
    },
  ]
};

export default mockedData;
