export function extractSpecialUrlsFromHistory(navigationHistory) {
  const result = {
    serviceCategory: null,
    serviceOverview: null,
    definitionsBeforeLast: null,
    toolsAndCalculators: null,
    saudiLaborSystem: null
  };
  
  for (let i = navigationHistory.length - 1; i >= 0; i--) {
    const url = navigationHistory[i].url;
      if (!result.serviceCategory && url.includes('/service-category') || url.includes("/labor-education")) {
        result.serviceCategory = url;
      }
      if (!result.saudiLaborSystem && url.includes('/labor-law')) {
        result.saudiLaborSystem = url;
      }
  
      if (!result.serviceOverview && url.includes('/service-overview')) {
        result.serviceOverview = url;
      }
  
      // For /definitions, find the one before the last URL
      if (url.includes('/definitions') && i > 0 && !result.definitionsBeforeLast) {
        // Ensure there's a URL before the /definitions URL
        result.definitionsBeforeLast = navigationHistory[i - 1].url;
      }
  
      // Early exit if all special URLs are found
      if (!result.toolsAndCalculators && url.match(/^\/tools-and-calculators\/?$/)) {
        result.toolsAndCalculators = url;
      }
  
      // Early exit if all special URLs are found
      if (result.serviceCategory && result.serviceOverview && result.definitionsBeforeLast && result.toolsAndCalculators && result.saudiLaborSystem ) {
        break;
      }
    }

  // Ensure the results are what's expected; otherwise, keep them null
  return {
    serviceCategory: result.serviceCategory || null,
    serviceOverview: result.serviceOverview || null,
    definitionsBeforeLast: result.definitionsBeforeLast || null,
    toolsAndCalculators: result.toolsAndCalculators || null,
    saudiLaborSystem: result.saudiLaborSystem || null
  };
}
