import React, { useRef, useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../Loader/Loader";
import { MainContext } from "../contexts/context";
import "./EndOfServiceReward.scss";
import { mockedData } from "./mockedData";
import axios from "axios";
import classnames from "classnames";
import {
  Select,
  NumericField,
  DatePicker,
  RadioTileGroup,
  RadioTile,
  Button,
  ToastStacking,
  useToastMethodsContext,
} from "@takamol/qiwa-design-system/components";

const EndOfServiceRewardCalc = ({ setData }) => {
  const {
    register,
    handleSubmit,
    formState,
    control,
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm();
  const { t } = useTranslation("translation", {
    keyPrefix: "endOfServicePageCalc",
  });
  const { theme } = useContext(MainContext);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractType, setContractType] = useState("1");
  const [contractTypeLabel, setContractTypeLabel] = useState("Limited period");
  const [reasonLabel, setReasonLabel] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRecaptchaValid, setRecaptchaValid] = useState(false);
  const [contractReasons, setContractReasons] = useState([]);
  const {
    endOfServiceCalcTranslations: {
      typeOfContract,
      chooseReason,
      limitedPeriod,
      unlimitedPeriod,
      endOfContactReason,
      salary,
      contractStartDate,
      contractEndDate,
    },
  } = mockedData;

  const { addToast } = useToastMethodsContext();

  const storedLanguage = localStorage.getItem("i18nextLng");

  const recaptchaRef = useRef(null);

  const fetchReasons = async () => {
    await axios
      .post(process.env.REACT_APP_FULL_URL + "/api/v1/end-of-service", {})
      .then((resp) => {
        if (resp.status === 200) {
          const { ContractEndReason } =
            resp.data.EndOfServiceRewardLookUpRs.Body.EndOfServiceRewardLookUp;
          const mappedContractReasons = ContractEndReason.map(
            (reason, index) => {
              return {
                ...reason,
                id: "reason-" + index,
              };
            }
          );
          setContractReasons(mappedContractReasons);
        }
        return resp;
      })
      .catch((err) => {
        addToast({
          message: t("defaultError"),
          variant: "danger",
        });
        console.log(err);
        return err;
      });
  };

  const fetchResult = async (data) => {
    const reasonCode = contractReasons.find(
      (reason) => reason.id === reasonLabel
    );
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const cleanStartDate = `${startDateObj.getFullYear()}-${`${
      startDate.getMonth() + 1
    }`.padStart(2, "0")}-${`${startDate.getDate()}`.padStart(2, "0")}`;
    const cleanEndDate = `${endDateObj.getFullYear()}-${`${
      endDateObj.getMonth() + 1
    }`.padStart(2, "0")}-${`${endDateObj.getDate()}`.padStart(2, "0")}`;
    await axios
      .post(
        process.env.REACT_APP_FULL_URL +
          `/api/v1/end-of-service-lookup?StartDate=${cleanStartDate}&EndDate=${cleanEndDate}&Salary=${data.salary}&ContractTypeCode=${data.contractType}&ContractEndReasonCode=${reasonCode.ContractEndReasonCode}`,
        {}
      )
      .then((resp) => {
        if (resp.status === 200) {
          setLoading(false);
          const respData = resp.data;
          setData({
            endOfContractReason: reasonCode,
            salary: respData.Salary,
            typeOfContract: contractTypeLabel,
            contractStartDate: respData.StartDate,
            contractEndDate: respData.EndDate,
            RewardAmount: respData.RewardAmount,
          });
        } else {
          setLoading(false);
          addToast({
            message: t("defaultError"),
            variant: "danger",
          });
        }
        return resp;
      })
      .catch((err) => {
        setLoading(false);
        addToast({
          message: t("defaultError"),
          variant: "danger",
        });
        console.log(err);
        return err;
      });
  };

  useEffect(() => {
    fetchReasons();
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    fetchResult(data);
  };

  const onErrors = (errors) => {
    if (
      errors.captchaError &&
      recaptchaRef.current.getValue() &&
      recaptchaRef.current.getValue().length
    ) {
      clearErrors("captchaError");
      onSubmit(getValues());
    }
  };

  const handleStartDateChange = (e) => {
    const today = new Date();
    const selectedStartDate = e;
    if (selectedStartDate <= today) {
      setStartDate(selectedStartDate);
      if (selectedStartDate <= endDate) {
        setStartDate(selectedStartDate);
      }
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e;
    if (selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    }
  };

  const handleSalaryChange = (e) => {
    const value = parseFloat(e.target.value) || 0;

    if (value > 1000000000000000) {
      setValue("salary", 1000000000000000);
      setError("salary", {
        type: "max",
        message: t("incorrectData"),
      });
    } else if (value < 1) {
      setError("salary", {
        type: "min",
        message: t("salaryMin"),
      });
    } else {
      setValue("salary", value);
      clearErrors("salary");
    }
  };

  return (
    <div
      className="end-of-service-reward__calc-content"
      data-testid="end-of-service-reward-calc-test"
    >
      {loading && (
        <div className="end-of-service-reward__loader">
          <Loader />
        </div>
      )}
      <Form
        onSubmit={handleSubmit(onSubmit, onErrors)}
        data-testid="end-of-service-reward-calc-form"
      >
        <Form.Group className="end-of-service-reward__calc-content__form mb-4">
          <Form.Label>{typeOfContract[theme]}</Form.Label>
          <RadioTileGroup
            ariaLabel="Type of contract"
            direction="vertical"
            name="contractTypeGroup"
            onChange={(value) => {
              setContractType(value);
              setContractTypeLabel(
                value === "1" ? limitedPeriod.english : unlimitedPeriod.english
              );
              setValue("contractType", value);
              setReasonLabel("");
            }}
            value={contractType}
          >
            <RadioTile
              {...register("contractType", { required: true })}
              id="end-of-service-reward-contract-limited"
              label={limitedPeriod[theme]}
              value="1"
              isChecked={contractType === "1"}
            />
            <RadioTile
              {...register("contractType", { required: true })}
              id="end-of-service-reward-contract-unlimited"
              label={unlimitedPeriod[theme]}
              value="2"
              isChecked={contractType === "2"}
            />
          </RadioTileGroup>

          <Form.Label>{endOfContactReason[theme]}</Form.Label>

          <Controller
            name="endOfContractReason"
            rules={{ required: t("required") }}
            control={control}
            render={({
              field: { onChange, onBlur },
              fieldState: { error },
            }) => (
              <Select
                id="calc-content-select"
                data-testid="end-of-service-reward-select"
                variant="business"
                errorMessage={t("required")}
                isError={error}
                options={contractReasons
                  .filter((item) => item.ContractTypeCode === contractType)
                  .sort(
                    (a, b) =>
                      Number(a.ContractEndReasonCode) -
                      Number(b.ContractEndReasonCode)
                  )
                  .map((reason) => {
                    return {
                      option:
                        storedLanguage === "en"
                          ? reason.EnDescription
                          : reason.ArDescription,
                      value: reason.id,
                    };
                  })}
                placeholder={chooseReason[theme]}
                maxInputWidth={"100%"}
                onChange={(newValue) => {
                  onChange(newValue);
                  setReasonLabel(newValue);
                }}
                onBlur={onBlur}
                value={reasonLabel.length ? reasonLabel : ""}
                placement="bottom"
                areOptionsLoading={contractReasons.length === 0}
              />
            )}
          />
        </Form.Group>
        <Form.Group
          className={classnames(
            "mb-4",
            "end-of-service-reward__salary-input",
            storedLanguage === "en"
              ? "end-of-service-reward__salary-input--ltr"
              : "end-of-service-reward__salary-input--rtl"
          )}
          controlId="salary"
        >
          <Form.Label>{salary[theme]}</Form.Label>
          <NumericField
            {...register("salary", {
              required: true,
              valueAsNumber: true,
              min: 1,
              max: 1000000000000000,
            })}
            fieldWidth={"100%"}
            isError={formState.errors.salary}
            suffix={storedLanguage === "en" ? t("currency") : undefined}
            prefix={storedLanguage === "ar" ? t("currency") : undefined}
            errorMessage={
              formState.errors.salary?.message ||
              (formState.errors.salary &&
                formState.errors.salary.type === "required" &&
                t("required"))
            }
            min={0}
            max={1000000000000000}
            data-testid="end-of-service-reward-input"
            onChange={handleSalaryChange}
          />
        </Form.Group>
        <div className="end-of-service-reward__calc-content__date-container">
          <Form.Group
            className={classNames({
              "end-of-service-reward__calc-content__date-control": true,
              "mb-4": true,
            })}
            controlId="certificateNumber"
          >
            <Form.Label>{contractStartDate[theme]}</Form.Label>
            {formState?.errors?.contractEndDate &&
              !formState?.errors?.contractStartDate && (
                <div className="end-of-service-reward__empty-error-for-align"></div>
              )}
            <DatePicker
              {...register("contractStartDate", { required: true })}
              value={startDate}
              onChange={(nextDate) => handleStartDateChange(nextDate)}
              errorMessage={t("required")}
              isError={formState.errors.contractStartDate}
              data-testid="end-of-service-reward-start-date"
              width={'100%'}
              confirmButtonLabel={t("selectDate")}
              closeButtonLabel={t("cancel")}
              selectDateLabel={t("selectStartDate")}
              calendarSystem="gregorian"
              pastYears={60}
              futureYears={10}
              maxDate={endDate || undefined}
            />
          </Form.Group>
          <Form.Group
            className={classNames({
              "end-of-service-reward__calc-content__date-control": true,
              "mb-4": true,
              "end-of-service-reward__calc-content__date-control--mr":
                theme === "arabic",
            })}
            controlId="certificateNumber"
          >
            <Form.Label>{contractEndDate[theme]}</Form.Label>
            {formState?.errors?.contractStartDate &&
              !formState?.errors?.contractEndDate && (
                <div className="end-of-service-reward__empty-error-for-align">
                  {" "}
                </div>
              )}
            <DatePicker
              {...register("contractEndDate", { required: true })}
              value={endDate}
              onChange={(nextDate) => handleEndDateChange(nextDate)}
              errorMessage={t("required")}
              isError={formState.errors.contractEndDate}
              data-testid="end-of-service-reward-end-date"
              width={'100%'}
              confirmButtonLabel={t("selectDate")}
              closeButtonLabel={t("cancel")}
              selectDateLabel={t("selectEndDate")}
              calendarSystem="gregorian"
              pastYears={60}
              futureYears={10}
              minDate={startDate || undefined}
            />
          </Form.Group>
        </div>
        {showErrorMsg && (
          <p className="certificate-validation-tool__error-msg">
            Sorry, something went wrong. Please, check your inputs and try
            again.
          </p>
        )}
        <Button
          variant="business_primary_filled"
          type="submit"
          data-testid="end-of-service-reward-submit"
        >
          {t("calcButtonLabel")}
        </Button>
      </Form>
      <ToastStacking />
    </div>
  );
};

EndOfServiceRewardCalc.defaultProps = {
  setData: () => {
    return;
  },
};

export default EndOfServiceRewardCalc;
