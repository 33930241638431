import React, { useState, useEffect, useRef } from "react";
import Link from "../../Link/Link";
import Icon from "../../Icon/Icon";
import { Col, Row, Container, Tab } from 'react-bootstrap';
import MainMobilePane from "./MainMobilePane";
import SecondaryMobilePane from "./SecondaryMobilePane";
import NavigationSearch from "../../Search/NavigationSearch/NavigationSearch.tsx";

const MobileMainMenu = ({links}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSecondaryPane, setShowSecondaryPane] = useState(false);
  const paneRef = useRef(null);

  const [lastScrollY, setLastScrollY] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsSticky(false);
      }
      else{
        setIsSticky(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleCloseMenu = () => {
    setShowMenu(false);
    setShowSecondaryPane(false);
  }

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('body-freeze')
    } else {
      document.body.classList.remove('body-freeze')
    }
  }, [showMenu])


  const addHeightVariable = () => {
    paneRef.current.style.setProperty(
      '--window-inner-height',
      `${window.innerHeight}px`
  );
  }

  useEffect(() => {
    addHeightVariable();

    window.addEventListener('resize', addHeightVariable);
    return () => {
      window.removeEventListener('resize', addHeightVariable);
    }
  }, []);

  return (
    <div className={`sticky-header ${isSticky ? " sticky-header-visible" : ""}`} data-testid='mobile-main-menu-test'>
     <div className={`main-menu-navbar main-menu-navbar--mobile ${showMenu ? 'main-menu-navbar--show' : ''} `}>
        <Container>
          <Row className="main-menu-navbar__action-bar">
            <Col xs="8" className="main-menu-navbar__action-bar-logo">
              <Link to='/'>
                <Icon name='logo-light' height='41' width='49' viewBox='0 0 64 56' />
              </Link>
            </Col>
            <Col xs="4" className="main-menu-navbar__actions">    
              {!showMenu && <NavigationSearch resolution="mobile" />}
              <button data-testid='mobile-main-menu-burger-button-test' className='main-menu-navbar__burger-button' onClick={() => showMenu ? handleCloseMenu() : setShowMenu(true)}>
                <Icon name='burger' className="main-menu-navbar__burger-button--burger-icon" />
                <Icon name='close' className="main-menu-navbar__burger-button--close-icon" />
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <div data-testid='mobile-menu-general-pane-test' className={`mobile-menu-pane ${showMenu ? 'mobile-menu-pane--open' : ''}`} ref={paneRef}>
        <Tab.Container id="secondary-mobile-pane-tabs" onSelect={() => setShowSecondaryPane(true)}>
          <MainMobilePane firstLevelLinks={links} closeMenu={handleCloseMenu} />
          <SecondaryMobilePane showPane={showSecondaryPane} onReturn={setShowSecondaryPane} closeMenu={handleCloseMenu}/>
        </Tab.Container>
      </div>
    </div>
  )
};

MobileMainMenu.defaultProps = {
  links: []
}

export default MobileMainMenu;