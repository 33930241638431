import React from "react";
import { Card } from "react-bootstrap";
import Link from "../Link/Link";

import "./ArticleCard.scss";

const ArticleCard = ({ title, description, cta, image, smallCard }: Props) => {
  return (
    <div className="article-card-wrapper" data-testid="article-card-test">
      <Card className="article-card">
        {smallCard && (
          <Link
            textLink={false}
            className="article-card__entire-card-link"
            to={cta.url}
          ></Link>
        )}
        <Card.Img variant="top" src={image} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
          <Card.Link
            as={(props: any) => (
              <Link
                {...props}
                textLink={true}
                underlined={true}
                variant="business"
                to={cta.url}
                label={cta.label}
              ></Link>
            )}
          ></Card.Link>
        </Card.Body>
      </Card>
    </div>
  );
};

type Props = {
  title: string;
  description: string;
  cta: {
    label: string;
    url: string;
  };
  image: string;
  smallCard: boolean;
};

ArticleCard.defaultProps = {
  title: "",
  description: "",
  cta: {
    label: "",
    url: "",
  },
  image: "",
  smallCard: false,
};

export default ArticleCard;
