import React from "react";
import { useTranslation } from "react-i18next";
import EconomicActivity from "./EconomicActivity";

import "./LinkingOccupations.scss";

const LinkingOccupations = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "linkingOccupations",
  });

  return (
    <div className="linking-occupations" data-testid="linking-occupations-test">
      <div className="linking-occupations__content">
        <div className="linking-occupations__text-content">
          <h2 className="linking-occupations__title">{t("title")}</h2>
          <p className="linking-occupations__description">{t("description")}</p>
        </div>
        <EconomicActivity />
      </div>
    </div>
  );
};

export default LinkingOccupations;
