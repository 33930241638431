import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    let timeoutId = null;
    const saveScrollPosition = () => {
      sessionStorage.setItem(`${location.pathname}_scrollPosition`, window.scrollY.toString());
    };

    const handleScroll = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(saveScrollPosition, 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location.search]);

  useEffect(() => {
    const savedPosition = sessionStorage.getItem(`${location.pathname}_scrollPosition`);
    if (savedPosition !== null) {
      setTimeout(() => {
        window.scrollTo({ top: parseInt(savedPosition, 10), behavior: 'instant' });
        sessionStorage.removeItem(`${location.pathname}_scrollPosition`);
      }, 0);
    } else {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, [location.pathname]);

  return null;
};

export default useScrollToTop;