import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.scss";

export const PageLoader = () => {
  useEffect(() => {
    document.body.classList.add("body-freeze");

    return () => {
      document.body.classList.remove("body-freeze");
    };
  }, []);

  return (
    <div className="custom-page-loader custom-page-loader__wrapper">
      <div className="custom-page-loader__spinner-block">
        <Spinner className="custom-page-loader__spinner" />
        <span>Loading...</span>
      </div>
    </div>
  );
};
