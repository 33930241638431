import React, { useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CertificateValidation from "../../../../CertificateValidation/CertificateValidation";
import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import Banner from "../../../../Banner/Banner";
import Link from "../../../../Link/Link";
import { MainContext } from "../../../../contexts/context";
import Feedback from "../../../../Feedback/Feedback";
import useNavigationHistory from "../../../../../hooks/useNavigationHistory";
import { extractSpecialUrlsFromHistory } from "../../../../../utils/extractSpecialUrlsFromHistory";

const CertificateCalcPage = () => {
  const { setHeaderData, headerData } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "breadcrumbs",
  });
  const navigationHistory = useNavigationHistory();
  const specialUrls = extractSpecialUrlsFromHistory(navigationHistory); 

  useEffect(() => {
    setHeaderData({ ...headerData, variant: "light" });
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      setHeaderData({ ...headerData, variant: "dark" });
    };
  }, [setHeaderData]);

  return (
    <div
      className="certificate-calc-page"
      data-testid="certificate-calc-page-test"
    >
      <Container>
        <Breadcrumbs theme="dark">
        {(specialUrls?.toolsAndCalculators && (
            <Link
              to={specialUrls?.toolsAndCalculators}
              label={t("tools")}
            />
          ))}
          {navigationHistory?.length > 0 && (
            <span>{navigationHistory[navigationHistory.length - 1]?.title }</span>
          )}
        </Breadcrumbs>
        <CertificateValidation />
      </Container>
      <Feedback />
      <Banner variant={"body"} />
    </div>
  );
};

export default CertificateCalcPage;
