import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Link from "../Link/Link";
import DesktopToc from "./DesktopToc";
import MobileToc from "./MobileToc";
import { useLocation } from 'react-router-dom'
import { unshift } from "lodash";
import './TableOfContents.scss';
import { MainContext } from "../contexts/context";
import {useTranslation} from "react-i18next";

const TableOfContents = ({headings, links, email, number}) => {
  const { t } = useTranslation("translation", { keyPrefix: "contact" });
  let location = useLocation();
  const [showContact, setShowContact] = useState(false);
  const [tocHeadings, setTocHeadings] = useState([]);
  const { isMobile } = useContext(MainContext);

  useEffect(() => {
    if (location.pathname.includes('/contact')) {
      setShowContact(true)
    } else {
      setShowContact(false)
    }

    if (!showContact) {
      parseHeadings()
    }
  }, [headings, location])

  const hasH2 = (array) => {
    return array.some(element => element.nodeName === "H2");
  };
  
  const findFirstLevel = (array, target) => {
    let firstLevelParent = null;
    for (let i = 0; i <= array.length; i++) {
      if (array[i].nodeName === "H2" && target.nodeName !== 'H2') {
        firstLevelParent = array[i];
      }
      if (array[i] === target) {
        return firstLevelParent;
      }
    }
    return firstLevelParent;
  };

  const parseHeadings = () => {
    let processedHeadings = Array.from(headings);

    if (!hasH2(processedHeadings)) {
      const newH2 = document.createElement("H2");
      processedHeadings.unshift(newH2);
    }

    if(processedHeadings.length > 0) {
      for (let element of processedHeadings) {
        let anchorText = element.innerText;
        let elementId = anchorText?.replaceAll(" ", "-").toLowerCase();
        element.id = elementId;
      }

      const headingsArray = processedHeadings?.map((h) => {
        return {
          id: h.id,
          text: h.innerText,
          parent: findFirstLevel(processedHeadings, h),
          nodeName: h.nodeName,
        };
      });

      const parsedHeadingsArray = headingsArray?.filter(el => el.nodeName === 'H2').map(h => {
        let subLinks = headingsArray?.filter(el => el.parent && el.parent.id === h.id);
        h.subLinks = subLinks;
        return h;
      });

      setTocHeadings(parsedHeadingsArray);
    }
  };

  return (
    <div className="table-of-contents__wrapper" data-testid='table-of-contents-test'>
      <div className={`table-of-contents ${showContact ? 'table-of-contents--contact' : ''}`}>      
        {!showContact && !isMobile && <DesktopToc headings={tocHeadings} location={location} />}
        {!showContact && isMobile && <MobileToc headings={tocHeadings} location={location} />}
        {showContact &&
          <div className="table-of-contents__wrapper-100">
            <div className="table-of-contents__contact-title">{t("findUsOn")}</div>
            {number && <div className="table-of-contents__contact">
              <Icon name="phone" />
              <span>{number}</span>
            </div>}
            {email && <div className="table-of-contents__contact">
              <Icon name="mail" />
              <span>{email}</span>
            </div>}
            <ul className='table-of-contents__social-links-list'>
              {links && links.map((link, index) => {
                return (
                  <li key={index} className="table-of-contents__social-list-item">
                    {link.uri && <Link to={link.uri} className='table-of-contents__social-link'>{link.title && <Icon name={link.title}/>}</Link>}
                  </li>
                )
              })}
            </ul>
          </div>
        }
      </div>
    </div>
  )
};

TableOfContents.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      nodeName: PropTypes.string, 
      innerText: PropTypes.string,
      id: PropTypes.string, 
      subLinks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string, 
          text: PropTypes.string, 
        })
      ),
    })
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string, 
      title: PropTypes.string, 
    })
  ), 
  email: PropTypes.string, 
  number: PropTypes.string, 
};

TableOfContents.defaultProps = {
  headings: [],
  links: [],
  email: '',
  number: ''
}

export default TableOfContents;