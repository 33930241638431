import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import ActionTilesGroup from "../../../ActionTilesGroup/ActionTilesGroup";
import { MainContext } from "../../../contexts/context";
import { AccordionOuterWrapper } from "../../../../utils/AccordionOuterWrapper";
import useFetchContent from "../../../../hooks/useFetchContent";
import FilteredTiles from "./FilteredTiles";

const BusinessTab = () => {
  const contextValue = useContext(MainContext);
  const {
    hireEmployees,
    manageCurrentEmployees,
    manageEstablishment,
    createNewEstablishment,
    manageQiwaBusinessAccount,
    laborOfficeServices,
    establishmentPerformance,
    toolsAndCalculators,
  } = contextValue;
  const { contentByType } = useFetchContent();

  return (
    <AccordionOuterWrapper>
      <div className="business-tab" data-testid="business-tab-test">
        {hireEmployees && (
          <ActionTilesGroup
            title={hireEmployees.title}
            titleLink={hireEmployees.titleLink}
            cards={hireEmployees.cards}
            description={hireEmployees.description}
            groupCta={hireEmployees.groupCta}
            theme={hireEmployees.theme}
            useAccordion={true}
            eventKey={"1"}
          />
        )}
        {manageCurrentEmployees && (
          <ActionTilesGroup
            title={manageCurrentEmployees.title}
            titleLink={manageCurrentEmployees.titleLink}
            cards={manageCurrentEmployees.cards}
            description={manageCurrentEmployees.description}
            groupCta={manageCurrentEmployees.groupCta}
            theme={manageCurrentEmployees.theme}
            useAccordion={true}
          />
        )}
        {manageEstablishment && (
          <ActionTilesGroup
            title={manageEstablishment.title}
            titleLink={manageEstablishment.titleLink}
            cards={manageEstablishment.cards}
            description={manageEstablishment.description}
            groupCta={manageEstablishment.groupCta}
            theme={manageEstablishment.theme}
            useAccordion={true}
          />
        )}
        {establishmentPerformance && (
          <ActionTilesGroup
            title={establishmentPerformance.title}
            titleLink={establishmentPerformance.titleLink}
            cards={establishmentPerformance.cards}
            description={establishmentPerformance.description}
            groupCta={establishmentPerformance.groupCta}
            theme={establishmentPerformance.theme}
            useAccordion={true}
          />
        )}
        {createNewEstablishment && (
          <ActionTilesGroup
            title={createNewEstablishment.title}
            titleLink={createNewEstablishment.titleLink}
            cards={createNewEstablishment.cards}
            description={createNewEstablishment.description}
            groupCta={createNewEstablishment.groupCta}
            theme={createNewEstablishment.theme}
            useAccordion={true}
          />
        )}
        {manageQiwaBusinessAccount && (
          <ActionTilesGroup
            title={manageQiwaBusinessAccount.title}
            titleLink={manageQiwaBusinessAccount.titleLink}
            cards={manageQiwaBusinessAccount.cards}
            description={manageQiwaBusinessAccount.description}
            groupCta={manageQiwaBusinessAccount.groupCta}
            theme={manageQiwaBusinessAccount.theme}
            useAccordion={true}
          />
        )}
        {laborOfficeServices && (
          <ActionTilesGroup
            title={laborOfficeServices.title}
            titleLink={laborOfficeServices.titleLink}
            cards={laborOfficeServices.cards}
            description={laborOfficeServices.description}
            groupCta={laborOfficeServices.groupCta}
            theme={laborOfficeServices.theme}
            useAccordion={true}
            underline={false}
          />
        )}
        <div className="tools-block">
          {toolsAndCalculators && (
            <ActionTilesGroup
              title={toolsAndCalculators.title}
              description={toolsAndCalculators.description}
              cards={toolsAndCalculators.cards}
              useAccordion={false}
            />
          )}
          {contentByType?.tiles && (
            <FilteredTiles
              tiles={contentByType.tiles}
              categoryFilters={["For Business owners", "لأصحاب الأعمال"]}
            />
          )}
        </div>
      </div>
    </AccordionOuterWrapper>
  );
};

export default BusinessTab;
