export const salaryCertReq = (setLoading, setData, certType, endpoint, axios, showErrorMsg, setErrorMsg) => {
  return axios
    .request({ url: endpoint, method: "POST" })
    .then((resp) => {
      if (resp.status === 200) {
        const { Header, Body } = resp.data.SearchSCRequestsRs;
        if (Header.ResponseStatus.Status === "SUCCESS") {
          setLoading(false);
          setData({ ...Body.RequestDetailsList.RequestDetails, certType });
          showErrorMsg(false);
          return resp.data;
        } else if (Header.ResponseStatus.Status === "ERROR") {
          setLoading(false);
          setErrorMsg(Header.ResponseStatus.EnglishMsg);
          showErrorMsg(true);
          return resp;
        }
      } else {
        setLoading(false);
        showErrorMsg(true);
        return resp;
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMsg(true);
      console.log(err);
      return err;
    });
};

export const nationalizationCertReq = (setLoading, setData, certType, endpoint, axios, showErrorMsg, setErrorMsg) => {
  return axios
    .request({ url: endpoint, method: "POST" })
    .then((resp) => {
      if (resp.status === 200) {
        const { Header, Body } = resp.data.GetSaudiCertificateRs;
        if (Header.ResponseStatus.Status === "SUCCESS") {
          setLoading(false);
          setData({
            ...Body.SCDetailsList.SCDetailsItem,
            EstablishmentId:
              Body.SCDetailsList.SCDetailsItem.LaborOfficeId +
              "-" +
              Body.SCDetailsList.SCDetailsItem.SequenceNumber,
            certType,
            CertificateStatus: {
              CertificateStatusEn: Body.SCDetailsList.SCDetailsItem.SaudiCertStatus.SaudiCertStatusEn,
              CertificateStatusAr: Body.SCDetailsList.SCDetailsItem.SaudiCertStatus.SaudiCertStatusAr,
            }
          });
          showErrorMsg(false);
          return resp.data;
        } else if (Header.ResponseStatus.Status === "ERROR") {
          setLoading(false);
          setErrorMsg(Header.ResponseStatus.EnglishMsg);
          showErrorMsg(true);
          return resp;
        }
      } else {
        setLoading(false);
        showErrorMsg(true);
        return resp;
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMsg(true);
      console.log(err);
      return err;
    });
}

const certStatusLabel = {
  1: {
    en: "Active",
    ar: "نشط"
  },
  2: {
    en: "Expired",
    ar: "منتهي الصلاحية",
  },
  3: {
    en: "Inactive",
    ar: "منتهي الصلاحية",
  }
};

export const debtCertReq = (setLoading, setData, certType, endpoint, axios, showErrorMsg, certNumber, estNumber) => {
  const laborOfficeId = estNumber?.split('-')[0];
  const sequenceNumber = estNumber?.split('-')[1];
  return axios
    .post(endpoint, {
      certificateNumber: certNumber,
      LaborOfficeId: laborOfficeId,
      SequenceNumber: sequenceNumber
    })
    .then((resp) => {
      if (resp.status === 200) {
        setLoading(false);
        const { data } = resp;
        setData({
          certType,
          CertificateNumber: data.DebitCertDetailslist.CertificateNumber,
          EstablishmentId: data.DebitCertDetailslist.LaborOfficeId + '-' + data.DebitCertDetailslist.SequenceNumber,
          EstablishmentName: data.DebitCertDetailslist.EstablishmentName,
          SCCreationDate: data.DebitCertDetailslist.CertificateIssueDate,
          SCExpiryDate: data.DebitCertDetailslist.ValidUntil,
          CertificateStatus: {
            CertificateStatusEn: certStatusLabel[data.DebitCertDetailslist.DebtCertStatusId].en,
            CertificateStatusAr: certStatusLabel[data.DebitCertDetailslist.DebtCertStatusId].ar,
          },
          ...data
        })
      } else {
        setLoading(false);
        showErrorMsg(true);
        return resp;
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMsg(true);
      console.log(err);
      return err;
    });
};

export const complianceCertReq = (setLoading, setData, certType, endpoint, axios, showErrorMsg) => {
  return axios
    .get(endpoint)
    .then((resp) => {
      if (resp.status === 200) {
        setLoading(false);
        const { data } = resp;
        setData({
          certType,
          CertificateNumber: data.certificateNumber,
          EstablishmentId: data.establishmentNumber,
          EstablishmentName: data.establishmentName,
          SCCreationDate: data.issueDate,
          SCExpiryDate: data.expirationDate,
          CertificateStatus: {
            CertificateStatusEn: data.statusEn,
            CertificateStatusAr: data.statusAr,
          },
          ...data
        })
      } else {
        setLoading(false);
        showErrorMsg(true);
        return resp;
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMsg(true);
      console.log(err);
      return err;
    });
};

export const serviceCertReq = (setLoading, setData, certType, endpoint, axios, showErrorMsg) => {
  return axios
    .get(endpoint)
    .then((resp) => {
      if (resp.status === 200) {
        setLoading(false);
        const { data } = resp;
        setData({
          CertificateStatus: {
            CertificateStatusEn: "Verified",
            CertificateStatusAr: "موثقة",
          },
          certType,
          CertificateType: certType,
          CertificateId: data.certificateInfo.certificateIdentifier,
          LaborerName: `${data.employeeInfo.firstName} ` + (data.employeeInfo.secondName === '-' ? '' : `${data.employeeInfo.secondName} `) + (data.employeeInfo.thirdName === '-' ? '' : `${data.employeeInfo.thirdName} `) + (data.employeeInfo.fourthName === '-' ? '' : data.employeeInfo.fourthName),
          EmployerName: {
            EmployerNameEn: data.certificateInfo.experience[0].company.nameEn,
            EmployerNameAr: data.certificateInfo.experience[0].company.nameAr,
          },
          Nationality: {
            NationalityEn: data.employeeInfo.nationality.nationalityNameEn,
            NationalityAr: data.employeeInfo.nationality.nationalityNameAr,
          },
          JobTitle: {
            JobTitleEn: data.certificateInfo.experience[0].position.nameEn,
            JobTitleAr: data.certificateInfo.experience[0].position.nameAr
          },
          JobType: {
            JobTypeEn: data.certificateInfo.experience[0].statusEn,
            JobTypeAr: data.certificateInfo.experience[0].statusAr,
          },
          ServiceStartDate: data.certificateInfo.experience[0].startDate,
          ServiceEndDate: data.certificateInfo.experience[0].endDate,
          ...data
        })
      } else {
        setLoading(false);
        showErrorMsg(true);
        return resp;
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMsg(true);
      console.log(err);
      return err;
    });
};

