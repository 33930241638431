import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";
import { Button } from "@takamol/qiwa-design-system/components";
import classnames from "classnames";

const SearchMobileFilters = ({
  onClose,
  response,
  materials,
  onMobileFiltersApply,
  selectedTopics,
  activeMaterial,
}: Props) => {
  const [showPaneType, setShowPaneType] = useState("main");
  const [materialSelected, setMaterialSelected] = useState("!");
  const [selectedMobileTopics, setSelectedMobileTopics] = useState<string[]>(
    []
  );
  const [forceUpdate, setForceUpdate] = useState(0);
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const paneRef = useRef<HTMLInputElement>(null);

  const onBackButton = () => {
    if (showPaneType === "main") {
      onClose();
    } else {
      setShowPaneType("main");
    }
  };

  const parseTitle = (type: string) => {
    switch (type) {
      case "main":
        return t("filters");
      case "materials":
        return t("materials");
      case "topics":
        return t("topics");
      default:
        break;
    }
  };

  const onMaterialChange = (material: string) => {
    setMaterialSelected(material);
  };

  const onTopicRemove = (id: string) => {
    let newTopics = selectedMobileTopics;
    newTopics = selectedMobileTopics.filter(function (topics) {
      return topics !== id;
    });
    setSelectedMobileTopics(newTopics);
  };

  const onTopicChange = (id: string) => {
    if (selectedMobileTopics.includes(id)) {
      onTopicRemove(id);
    } else {
      const newTopics = selectedMobileTopics;
      newTopics.push(id);
      setSelectedMobileTopics(newTopics);

      setForceUpdate(forceUpdate + 1);
    }
  };

  const resetTopics = () => {
    setSelectedMobileTopics([]);
  };

  const onApply = () => {
    onClose();
    onMobileFiltersApply({
      material: materialSelected,
      topics: selectedMobileTopics,
    });
  };

  const storedLanguage = localStorage.getItem("i18nextLng");

  const addHeightVariable = () => {
    paneRef?.current?.style.setProperty(
      "--window-inner-height",
      `${window.innerHeight - 62}px`
    );
  };

  useEffect(() => {
    setSelectedMobileTopics(selectedTopics);
    setMaterialSelected(activeMaterial);

    addHeightVariable();

    window.addEventListener("resize", addHeightVariable);
    return () => {
      window.removeEventListener("resize", addHeightVariable);
    };
  }, []);

  return (
    <div className="search-mobile-filters" ref={paneRef}>
      <Container>
        <button
          onClick={onBackButton}
          className="search-mobile-filters__back-btn"
        >
          <Icon
            className={""}
            name={storedLanguage === "en" ? "chevron-left" : "chevron-right"}
          />
          {t("goBack")}
        </button>
        <h5 className="search-mobile-filters__pane-title">
          {parseTitle(showPaneType)}
          {showPaneType !== "main" &&
            ((showPaneType === "materials" && materialSelected !== "!") ||
              (showPaneType === "topics" &&
                selectedMobileTopics.length !== 0)) && (
              <button
                className="search-mobile-filters__clear-btn"
                onClick={() =>
                  showPaneType === "materials"
                    ? onMaterialChange("!")
                    : resetTopics()
                }
              >
                {t("clear")}
              </button>
            )}
        </h5>
        <div className="search-mobile-filters__changeable-pane">
          {showPaneType === "main" && (
            <div className="search-mobile-filters__main-pane">
              <ul className="search-mobile-filters__filters-list">
                <li>
                  <button onClick={() => setShowPaneType("materials")}>
                    <div className="search-mobile-filters__filters-list-label">
                      <div className="search-mobile-filters__filters-list-label--main">
                        {t("materials")}
                      </div>
                      <div>{activeMaterial === "!" ? "" : activeMaterial}</div>
                    </div>
                    <Icon
                      className={""}
                      name={
                        storedLanguage === "en"
                          ? "chevron-right"
                          : "chevron-left"
                      }
                    />
                  </button>
                </li>
                <li>
                  <button onClick={() => setShowPaneType("topics")}>
                    <div className="search-mobile-filters__filters-list-label">
                      <div className="search-mobile-filters__filters-list-label--main">
                        {t("topics")}{" "}
                        {selectedMobileTopics.length
                          ? `(${selectedMobileTopics.length})`
                          : ""}
                      </div>
                      <div>
                        {selectedMobileTopics.length
                          ? selectedMobileTopics.join(", ")
                          : ""}
                      </div>
                    </div>
                    <Icon
                      className={""}
                      name={
                        storedLanguage === "en"
                          ? "chevron-right"
                          : "chevron-left"
                      }
                    />
                  </button>
                </li>
              </ul>
            </div>
          )}
          {showPaneType !== "main" && (
            <div className="search-mobile-filters__second-pane">
              {showPaneType === "materials" && (
                <fieldset className="search-filters__materials-radio-wrapper">
                  <li className="search-filters__material-list-item">
                    <input
                      id={"mobile-material-all"}
                      type="radio"
                      name="mobile-material"
                      checked={materialSelected === "!"}
                      onChange={() => {
                        onMaterialChange("!");
                      }}
                    />
                    <label htmlFor={"mobile-material-all"}>
                      {t("allMaterials")}
                    </label>
                  </li>
                  {materials &&
                    materials.map((material: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          {material[storedLanguage ? storedLanguage : "en"] !==
                            "!" && (
                            <li className="search-filters__material-list-item">
                              <input
                                id={"mobile-material-" + index}
                                type="radio"
                                name="mobile-material"
                                defaultChecked={
                                  material[
                                    storedLanguage ? storedLanguage : "en"
                                  ] === materialSelected
                                }
                                onChange={() => {
                                  onMaterialChange(
                                    material[
                                      storedLanguage ? storedLanguage : "en"
                                    ]
                                  );
                                }}
                              />
                              <label htmlFor={"mobile-material-" + index}>
                                {
                                  material[
                                    storedLanguage ? storedLanguage : "en"
                                  ]
                                }
                              </label>
                            </li>
                          )}
                        </React.Fragment>
                      );
                    })}
                </fieldset>
              )}
              {showPaneType === "topics" && (
                <ul className={"search-filters__topics-list"}>
                  {response &&
                    response.facets &&
                    response.facets[1] &&
                    response.facets[1][0]?.topics?.map(
                      (topic: any, index: number) => {
                        return (
                          <li
                            key={index}
                            className={"search-filters__topics-list-item"}
                          >
                            <input
                              id={"topics-mobile-" + index}
                              type="checkbox"
                              checked={selectedMobileTopics.includes(
                                topic.values.value
                              )}
                              onChange={() => {
                                onTopicChange(topic.values.value);
                              }}
                              disabled={topic.values.count === 0}
                            />
                            <label
                              className={classnames(
                                "search-filters__topic-label",
                                topic.values.count === 0 &&
                                  "search-filters__topic-label--disabled"
                              )}
                              htmlFor={"topics-mobile-" + index}
                            >
                              <span>{topic.values.value}</span>
                              <span>{topic.values.count}</span>
                            </label>
                          </li>
                        );
                      }
                    )}
                </ul>
              )}
            </div>
          )}
          <div className="search-mobile-filters__apply-btn-wrapper">
            {showPaneType !== "main" && (
              <Button variant="business_primary_filled" onClick={onApply}>
                {t("apply")}
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

type Props = {
  onClose: any;
  response: any;
  materials: Array<object>;
  setActiveMaterial: any;
  getTopics: any;
  activeMaterial: string;
  onTopicClick: any;
  selectedTopics: Array<any>;
  onMobileFiltersApply: any;
};

export default SearchMobileFilters;
