import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MainContext } from "../contexts/context";
import takamolLogo from "../../images/takamol-footer.png";
import takamolPalmLogo from "../../images/takamol-palm-footer.png";
import DgaLogo from "./DgaLogo";
import { Container, Row, Col } from "react-bootstrap";
import Icon from "../Icon/Icon";
import Link from "../Link/Link";
import { useTranslation } from "react-i18next";

import "./Footer.scss";
import apple from "../../images/apple.svg";
import google from "../../images/google.svg";
import huawei from "../../images/huawei.svg";
import AccessibilityTools from "../AccessibilityTools/AccessibilityTools";
import { Divider } from "@takamol/qiwa-design-system/components";

const Footer = ({
  sections,
  ctaBar,
  socialLinks,
  secondaryLinks,
  footerLogos,
  copyright,
}) => {
  const { isMobile } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "siteFooter",
  });

  return (
    <div className="footer" data-testid="footer-test">
      <Container>
        <Row className="footer__logos-row">
          <Col sm="5" md="5" lg="5" className="footer__logos-col--left">
            <Link
              textLink={false}
              to={
                footerLogos.footerMainLogo
                  ? footerLogos.footerMainLogo.url
                  : "/"
              }
            >
              {footerLogos.footerMainLogo ? (
                <img
                  className="footer__footer-main-logo"
                  src={footerLogos.footerMainLogo.img}
                  alt="takamol footer main logo"
                />
              ) : (
                <Icon
                  name="footer-logo"
                  viewBox="0 0 73 64"
                  className="footer__footer-main-logo"
                />
              )}
            </Link>
            <Divider />
          </Col>
          <Col sm="7" md="7" lg="7" className="footer__logos-col--right">
            <div className="footer__logo-container footer__takamol-logo-container">
              <Link
                textLink={false}
                to={
                  footerLogos.footerTakamolLogo
                    ? footerLogos.footerTakamolLogo.url
                    : "https://www.takamolholding.com/en/"
                }
              >
                <img
                  className="footer__takamol-logo"
                  src={
                    footerLogos.footerTakamolLogo
                      ? footerLogos.footerTakamolLogo.img
                      : takamolLogo
                  }
                  alt="takamol footer logo"
                />
              </Link>
            </div>
            <div className="footer__logo-container">
              <Link
                textLink={false}
                to={
                  footerLogos.footerArabicTakamolLogo
                    ? footerLogos.footerArabicTakamolLogo.url
                    : "https://hrsd.gov.sa/"
                }
              >
                <img
                  className="footer__arabic-takamol-logo"
                  src={
                    footerLogos.footerArabicTakamolLogo
                      ? footerLogos.footerArabicTakamolLogo.img
                      : takamolPalmLogo
                  }
                  alt="takamol palm footer logo"
                />
              </Link>
            </div>
            <div className="footer__logo-container footer__logo-container--dga-logo">
              <Link
                aria-label={t("dgaLogoLabel")}
                textLink={false}
                to="https://raqmi.dga.gov.sa/platforms/platforms/5b6415e9-ab75-44ba-b359-a2148b1ec8bc/platform-license"
              >
                <DgaLogo
                  width={isMobile ? "132" : "190"}
                  height={isMobile ? "44" : "64"}
                />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="footer__sections-row" md="2" lg="4">
          {sections.length &&
            sections.map((section, index) => {
              return (
                <Col
                  xs="12"
                  md="3"
                  key={index}
                  className="footer__sections-col"
                >
                  <h5 className="footer__section-title">
                    {section?.url ? (
                      <Link
                        textLink={true}
                        underlined={false}
                        variant="black"
                        label={section?.title}
                        to={section?.url}
                      ></Link>
                    ) : (
                      section?.title
                    )}
                  </h5>
                  <ul className="footer__section-links-list">
                    {section?.sectionLinks?.map((link, index) => {
                      return (
                        <li key={index} className="footer__section-list-item">
                          {link.url && (
                            <Link
                              textLink={true}
                              to={link.url}
                              variant="black"
                              className="footer__section-link"
                              label={link.label}
                            ></Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
        </Row>
        <Row>
          <Col>
            {ctaBar.text && (
              <div className="footer__cta-bar">
                <p>{ctaBar.text}</p>
                {ctaBar.cta.url && (
                  <Link
                    to={`${ctaBar.cta.url}#report-a-problem`}
                    className="footer__cta-bar-link"
                  >
                    {ctaBar.cta.label}
                  </Link>
                )}
              </div>
            )}
          </Col>
        </Row>
        <div className="footer__control-bar">
          <AccessibilityTools />
        </div>
        <div className="footer__social-footer">
          <Row className="footer__social-footer-row" id="social-footer-row">
            <Col
              xs="12"
              lg="3"
              className="footer__social-footer-col footer__social-footer-col--social"
            >
              <ul className="footer__social-links-list">
                {socialLinks.map((link, index) => {
                  return (
                    <li key={index} className="footer__social-list-item">
                      {link.url && (
                        <Link
                          textLink={false}
                          to={link.url}
                          className="footer__social-link"
                        >
                          {link.icon && <Icon name={link.icon} />}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col
              xs="12"
              lg="6"
              className="footer__social-footer-col footer__social-footer-col--secondary"
            >
              <ul className="footer__social-links-list">
                {secondaryLinks.map((link, index) => {
                  return (
                    <li key={index} className="footer__social-list-item">
                      {link.url && (
                        <Link
                          textLink={true}
                          to={link.url}
                          className="footer__social-link"
                          label={link.label}
                        ></Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col lg="3" className="footer__copyright">
              {copyright ? copyright : "© Copyrights 2023 by Qiwa Takamol."}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      sectionLinks: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ),
  ctaBar: PropTypes.shape({
    text: PropTypes.string,
    cta: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  secondaryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  footerLogos: PropTypes.shape({
    footerMainLogo: PropTypes.shape({
      img: PropTypes.string,
      url: PropTypes.string,
    }),
    footerTakamolLogo: PropTypes.shape({
      img: PropTypes.string,
      url: PropTypes.string,
    }),
    footerArabicTakamolLogo: PropTypes.shape({
      img: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  copyright: PropTypes.string,
};

Footer.defaultProps = {
  sections: [
    {
      title: "",
      url: "",
      sectionLinks: [
        {
          url: "",
          label: "",
        },
      ],
    },
  ],
  ctaBar: {
    text: "Have a problem or see any opportunities to upgrade our site?",
    cta: {
      label: "Contact us",
      url: "/",
    },
  },
  socialLinks: [
    {
      label: false,
      icon: "linkedin",
      url: "/",
    },
  ],
  secondaryLinks: [
    {
      label: "Term of use",
      icon: false,
      url: "/",
    },
  ],
  footerLogos: {
    footerMainLogo: {
      img: "",
      url: "/",
    },
    footerTakamolLogo: {
      img: "",
      url: "/",
    },
    footerArabicTakamolLogo: {
      img: "",
      url: "/",
    },
  },
  copyright: "",
};

export default Footer;
