import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ActionTilesGroup from "../../ActionTilesGroup/ActionTilesGroup";
import HeroSearchInput from "../../Search/HeroSearchInput/HeroSearchInput";
import he from "he";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import "./LaborEducationMaterials.scss";
import { AccordionOuterWrapper } from "../../../utils/AccordionOuterWrapper";
import Feedback from "../../Feedback/Feedback";
import { MainContext } from "../../contexts/context";
import NumbersBlock from "../../NumbersBlock/NumbersBlock";
import useFetchContent from "../../../hooks/useFetchContent";
import { isEmpty } from "lodash";
import IconCardsGroup from "../../IconCardsGroup/IconCardsGroup";
import ShowMoreLessButton from "./ShowMoreLessButton";

interface LaborEducationMaterialsProps {
  data: {
    search_results?: Record<string, any[]>;
    facets?: Array<Array<{ beneficiaries?: any[] }>>;
    facets_metadata?: {
      user_type?: Record<
        string,
        { id: string; ar: string; en: string; user_type_id?: string }
      >;
    };
  };
}

const LaborEducationMaterials: React.FC<LaborEducationMaterialsProps> = ({
  data,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "laborEducation" });
  const { laborSectorNumbers, theme } = useContext(MainContext);
  const accordionRefs = useRef<Record<string, HTMLDivElement>>({});

  const searchResults = data?.search_results || {};

  const [userTypes, setUserTypes] = useState<
    Array<{ option: string; value: string; id: string; user_type_id: string }>
  >([]);
  const [persona, setPersona] = useState(
    sessionStorage.getItem("previousPersona") || "all"
  );
  const [selectedUserType, setSelectedUserType] = useState(persona);
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState<string[]>(
    []
  ); // Handle multiple beneficiaries
  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >({});
  const [openAccordions, setOpenAccordions] = useState<Record<string, boolean>>(
    {}
  );
  const prevExpandedCategories = useRef<Record<string, boolean>>({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const { contentByType }: { contentByType: any } = useFetchContent();

  const beneficiaries = (data?.facets?.[0]?.[0]?.beneficiaries || []).filter(
    (beneficiary) => beneficiary.raw_value !== "!"
  );

  useEffect(() => {
    setPersona(sessionStorage.getItem("previousPersona") || "all");
    setSelectedUserType(persona || "all");
    setSelectedBeneficiaries([]); // Reset beneficiaries on load
  }, [theme, window.location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    Object.keys(expandedCategories).forEach((categoryKey) => {
      if (
        prevExpandedCategories.current[categoryKey] &&
        !expandedCategories[categoryKey]
      ) {
        // The accordion has just been collapsed
        const accordionElement = accordionRefs.current[categoryKey];
        if (accordionElement) {
          accordionElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    });
    // Update the previous expandedCategories state
    prevExpandedCategories.current = { ...expandedCategories };
  }, [expandedCategories]);

  useEffect(() => {
    const updatedUserTypes = data?.facets_metadata?.user_type
      ? Object.values(data.facets_metadata.user_type)
          .filter((type) => type.id)
          .map((type) => ({
            option: theme === "arabic" ? type.ar : type.en,
            value: type.id,
            id: type.id,
            user_type_id:
              type.id === "All types" ? "all" : type.user_type_id || "",
          }))
      : [];
    setUserTypes(updatedUserTypes);
  }, [theme, data]);

  const getFilteredData = () => {
    let results = { ...searchResults };

    // If "all" is selected, return all search results without filtering by beneficiaries
    if (selectedBeneficiaries.includes("All")) {
      return results;
    }

    // Filter by user type if it's not "all"
    if (selectedUserType !== "all") {
      results = { [selectedUserType]: searchResults[selectedUserType] || [] };
    }

    // Apply filtering by beneficiaries if there are selected beneficiaries
    if (selectedBeneficiaries.length > 0) {
      Object.keys(results).forEach((key) => {
        results[key] = results[key]?.filter((card) => {
          const cardBeneficiaries = card.beneficiaries
            .split(",")
            .map((b: any) => b.trim().toLowerCase());
          return selectedBeneficiaries.some((selected) =>
            cardBeneficiaries.includes(selected.toLowerCase())
          );
        });
      });
    }

    return results;
  };

  const filteredData = getFilteredData();

  const getTitleForUserType = (categoryKey: string) => {
    const foundUserType = userTypes.find(
      (type) => type.user_type_id === categoryKey
    );

    return foundUserType ? foundUserType.option : t("all");
  };

  const handleUserTypeChange = (newUserType: string) => {
    setSelectedUserType(newUserType);
  };

  const handleBeneficiariesChange = (newBeneficiaries: string[]) => {
    setSelectedBeneficiaries(newBeneficiaries); // Update the selected beneficiaries
  };

  const handleShowMore = (categoryKey: string) =>
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryKey]: true,
    }));

  const handleShowLess = (categoryKey: string) =>
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryKey]: false,
    }));

  const handleAccordionToggle = (categoryKey: string, isOpen: boolean) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [categoryKey]: isOpen,
    }));
  };

  return (
    <div>
      <Container className="labor-education-materials">
        <Row>
          {!isMobile && (
            <Col lg={4} className="labor-education-materials__filters-section">
              <Filter
                userTypes={userTypes}
                selectedUserType={selectedUserType}
                onFilterChange={handleUserTypeChange}
                beneficiaries={beneficiaries}
                selectedBeneficiaries={selectedBeneficiaries}
                onBeneficiaryChange={handleBeneficiariesChange}
              />
            </Col>
          )}
          <Col lg={8}>
            <h2 className="labor-education-materials__material-header">
              {t("title")}
            </h2>
            <HeroSearchInput />
            {isMobile && (
              <div className="labor-education-materials__mobile-filters">
                <Filter
                  userTypes={userTypes}
                  selectedUserType={selectedUserType}
                  onFilterChange={handleUserTypeChange}
                  beneficiaries={beneficiaries}
                  selectedBeneficiaries={selectedBeneficiaries}
                  onBeneficiaryChange={handleBeneficiariesChange}
                />
              </div>
            )}
            {Object.keys(filteredData)
              .sort((a, b) => {
                return theme === "arabic"
                  ? a.localeCompare(b, "ar")
                  : a.localeCompare(b, "en");
              })
              .map((categoryKey) => {
                const cards = filteredData[categoryKey] || [];
                if (cards.length === 0) return null;

                const decodedCards = cards.map((card) => ({
                  ...card,
                  title: he.decode(card.title),
                }));

                const title = getTitleForUserType(categoryKey);
                const isExpanded = expandedCategories[categoryKey] || false;
                const isAccordionOpen = openAccordions[categoryKey] || false;

                return (
                  <div
                    key={categoryKey}
                    className="labor-education-materials__materials-section"
                    ref={(el) => {
                      if (el) {
                        accordionRefs.current[categoryKey] = el;
                      }
                    }}
                  >
                    <AccordionOuterWrapper defaultActiveKey={categoryKey}>
                      <ActionTilesGroup
                        eventKey={categoryKey}
                        underline={false}
                        title={title}
                        desktopGrid={2}
                        groupCta={false}
                        cards={
                          isExpanded ? decodedCards : decodedCards.slice(0, 4)
                        }
                        useAccordion={true}
                        onAccordionToggle={(isOpen: any) =>
                          handleAccordionToggle(categoryKey, isOpen)
                        }
                      />
                    </AccordionOuterWrapper>
                    {cards.length > 4 &&
                      ((openAccordions[categoryKey] && isMobile) ? (
                        <ShowMoreLessButton
                          isExpanded={isExpanded}
                          onShowMore={handleShowMore}
                          onShowLess={handleShowLess}
                          categoryKey={categoryKey}
                          t={t}
                        />
                      ) : null)}
                      {cards.length > 4 &&
                      (!isMobile ? (
                        <ShowMoreLessButton
                          isExpanded={isExpanded}
                          onShowMore={handleShowMore}
                          onShowLess={handleShowLess}
                          categoryKey={categoryKey}
                          t={t}
                        />
                      ) : null)}
                  </div>
                );
              })}
          </Col>
        </Row>
      </Container>
      <Feedback />
      {laborSectorNumbers && (
        <NumbersBlock
          title={laborSectorNumbers.title}
          tabs={laborSectorNumbers.tabs}
        />
      )}
      {!isEmpty(contentByType?.why_qiwa_platform as any) && (
        <IconCardsGroup groupData={contentByType?.why_qiwa_platform} />
      )}
    </div>
  );
};

export default LaborEducationMaterials;
