import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { Loader } from "../Loader/Loader";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import { MainContext } from "../contexts/context";
import { Select, Button, Field } from "@takamol/qiwa-design-system/components";

const EconomicActivity = () => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "linkingOccupations.economicActivity",
  });
  const [occupationsList, setOccupationsList] = useState([]);
  const [filteredOccupationsList, setFilteredOccupationsList] = useState([]);
  const [economicActivityList, setEconomicActivityList] = useState([]);
  const [displayResult, setDisplayResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedActivity, setSelecttedActivity] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("defaultError"));
  const resultsRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    setErrorMessage(t("defaultError"));
  }, [theme]);

  const handleFilterChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchQuery(searchText); // Store the search text.
    setFilteredOccupationsList(
      occupationsList.filter(({ descriptionEn, descriptionAr }) =>
        theme === "english"
          ? descriptionEn.toLowerCase().includes(searchText)
          : descriptionAr.toLowerCase().includes(searchText)
      )
    );
  };

  function highlightMatches(str, query) {
    if (!query) return str;

    const regex = new RegExp(`(${query})`, 'ig');
    return str.replace(regex, '<mark>$1</mark>');
  }

  const count = occupationsList.length;

  useEffect(() => {
    fetchEconomicActivityList();
  }, []);

  useEffect(() => {
    if (displayResult && resultsRef.current) {
      resultsRef.current?.scrollIntoView({ behavior: "instant", block: "start" });
    }
  }, [displayResult]);

  const fetchEconomicActivityList = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_FULL_URL}/linking_list/get-nitaqat-activities`,
          {}
        )
        .then((response) => {
          if (response.data) {
            setEconomicActivityList(Object.values(response.data));
            setShowError(false);
          }
        });
    } catch {
      setShowError(true);
    }
  };

  const fetchOccupationList = async () => {
    try {
      setLoading(true);
      setDisplayResult(false);
      await axios
        .post(
          `${process.env.REACT_APP_FULL_URL}/api/linking-occupations/${selectedActivity}`,
          {}
        )
        .then((response) => {
          if (response.data) {
            setOccupationsList(response.data.occupationsList);
            setFilteredOccupationsList(response.data.occupationsList);
            setDisplayResult(true);
            setLoading(false);
            setShowError(false);
            setErrorMessage(
              ""
            );
          }
        });
    } catch {
      setDisplayResult(false);
      setShowError(true);
      setLoading(false);
      return;
    }
  };

  const handleSearchButton = () => {
    if (selectedActivity) fetchOccupationList();
  };
  

  return (
    <div
      className={classNames({
        "linking-occupations__economic-activity": true,
        "linking-occupations__economic-activity--rtl": theme === "arabic",
        height: !displayResult,
        "linking-occupations__economic-activity--with-error": !!errorMessage.length
      })}
      data-testid="economic-activity-test"
    >
      {showError && (
        <p className="linking-occupations__economic-activity__error-msg">
          {errorMessage}
        </p>
      )}
      <Form.Label htmlFor="economicActivity">{t("title")}</Form.Label>
      <div className="linking-occupations__economic-activity__search">
        <Select
          id="economicActivity"
          data-testid="economic-activity-select-test"
          variant="business"
          options={economicActivityList.map((activity, index) => {
            return {
              option: activity.name,
              value: activity.activity_id
            }
          })}
          maxInputWidth={'100%'}
          width={"100%"}
          onChange={(newValue) => {
            setSelecttedActivity(newValue)
          }}
          placeholder={t("selectTitle")}
          value={selectedActivity}
        />
        <Button
          data-testid="search-btn"
          type="button"
          variant="business_primary_filled"
          onClick={handleSearchButton}
          ref={resultsRef}
        >
          {t("search")}
        </Button>
      </div>

      {loading ? (
        <div className="linking-occupations__economic-activity__loader">
          <Loader />
        </div>
      ) : (
        displayResult && (
          <>
            <div className="linking-occupations__economic-activity__border"></div>
            <div className="linking-occupations__economic-activity__filter">
              <Icon
                className={
                  "linking-occupations__economic-activity__filter--icon"
                }
                name="search"
              />
              <Field
                placeholder={t("searchPlaceHolder")}
                data-testid="economic-activity-filter-test"
                onChange={handleFilterChange}
                fieldWidth={'100%'}
              />
            </div>
            <span className="linking-occupations__economic-activity__result-text" dangerouslySetInnerHTML={{
              __html: t("resultsFound", { count, interpolation: { escapeValue: false } })
            }}>
            </span>
            <ul
              className={"linking-occupations__economic-activity__result-list"}
            >
              <li className="linking-occupations__economic-activity__result-list__item sticky">
                {t("occupation")}
              </li>
              <div
                className="linking-occupations__economic-activity__result-list__data"
                data-testid="occupation-item-test"
              >
                {filteredOccupationsList.map((occupation) => {
                  const occupationDescription = theme === "english"
                    ? occupation.descriptionEn
                    : occupation.descriptionAr;

                  const highlightedDescription = highlightMatches(occupationDescription, searchQuery);

                  return (
                    <li
                      key={occupation.code}
                      className="linking-occupations__economic-activity__result-list__item"
                    >
                      <div dangerouslySetInnerHTML={{ __html: highlightedDescription }} />
                    </li>
                  );
                })}
              </div>
            </ul>
          </>
        )
      )}
    </div>
  );
};

export default EconomicActivity;
