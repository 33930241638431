import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Lottie from "lottie-react";
import womenEmpowermentAnimation from "../../images/animations/women-empowerment.json";
import stimulationInvestmentAnimation from "../../images/animations/stimulation-investment.json";
import nationalizationAnimation from "../../images/animations/nationalization.json";
import useVisibility from "../../hooks/useVisibility";
import Icon from "../Icon/Icon";
import AnimatedNumber from "./AnimatedNumber";
import { MainContext } from "../contexts/context";

import "./NumbersBlock.scss";

const NumbersBlock = ({ title, tabs }) => {
  const [activeTab, setActiveTab] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabsViewedState, setTabsViewedState] = useState([]);
  const [animationDomLoaded, setAnimationDomLoaded] = useState(false);
  const animationRef = useRef();
  const animationMobileRef = useRef();
  const [wasVisible, setWasVisible] = useState(false);
  const [isVisible, currentElement] = useVisibility();
  const { isMobile, isTablet, theme } = useContext(MainContext);


  useEffect(() => {
    const handleResize = () => {
        onAnimationDOMLoaded();  
    };

    window.addEventListener('resize', handleResize); 

    return () => {
        window.removeEventListener('resize', handleResize); 
    };
}, []);


  useEffect(() => {
    if (tabs[0]) {
      setActiveTab(tabs[0].key);
      const parsedTabs = tabs.map((tab) => {
        return { tabName: tab, triggered: false };
      });
      setTabsViewedState(parsedTabs);
    }
  }, [tabs]);

  useEffect(() => {
    if (isVisible) {
      setWasVisible(true);
    }
    if (animationDomLoaded) {
      if (wasVisible && !tabsViewedState[activeTabIndex].triggered) {
        triggerAnimation();
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (animationDomLoaded) {
      if (wasVisible && !tabsViewedState[activeTabIndex].triggered) {
        triggerAnimation();
      }
    }
  }, [wasVisible, animationDomLoaded]);

  const triggerAnimation = () => {
    const newTabsState = tabsViewedState;
    const activeIndex = tabs.findIndex((t) => t.key === activeTab);
    newTabsState[activeIndex].triggered = true;
    setTabsViewedState(newTabsState);
    if (window.innerWidth <= 992) {
      animationMobileRef.current.goToAndPlay(0, true);
    } else {
      animationRef.current.goToAndPlay(0, true);
    }
  };

  const changeActiveTab = (tab) => {
    setAnimationDomLoaded(false);
    setActiveTab(tab);
    setActiveTabIndex(tabs.findIndex((t) => t.key === tab));

    if (window.innerWidth <= 992) {
      const activeNavLink = document.querySelector(
        `.nav-link[data-rr-ui-event-key=${tab}]`
      );
      activeNavLink.scrollIntoView({ block: "nearest", inline: "center" });
    }
  };

  const animationVariants = {
    women: womenEmpowermentAnimation,
    nationalization: nationalizationAnimation,
    investment: stimulationInvestmentAnimation,
  };

  const onAnimationDOMLoaded = () => {
    setAnimationDomLoaded(true);
    if (window.innerWidth <= 992) {
      const duration = animationMobileRef.current.getDuration(true);
      animationMobileRef.current.goToAndStop(duration, true);
    } else {
      const duration = animationRef.current.getDuration(true);
      animationRef.current.goToAndStop(duration, true);
    }
  };

  return (
    <div className="numbers-block" data-testid="numbers-block-test">
      <Container>
        <Row xs="1" lg="2">
          <Col lg="5">
            <div ref={currentElement} className="numbers-block__media-wrapper">
              <Lottie
                lottieRef={animationRef}
                animationData={animationVariants[activeTab]}
                className="numbers-block__image numbers-block__image--desktop"
                autoplay={false}
                loop={false}
                onDOMLoaded={onAnimationDOMLoaded}
              />
            </div>
          </Col>
          <Col lg="7" className="numbers-block__tabs-column">
            <h3 className="numbers-block__title">{title}</h3>
            <Tab.Container
              id="numbers-block-tabs"
              activeKey={activeTab}
              onSelect={(k) => changeActiveTab(k)}
            >
              <div className="numbers-block__nav-wrapper">
                <Nav className="numbers-block__tabs-list">
                  {tabs.map((tab, index) => {
                    return (
                      <Nav.Item key={index}>
                        <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
                {(isMobile || isTablet) && activeTab !== tabs[0].key && (
                  <button
                    className="numbers-block__prev-button"
                    data-testid="numbers-block-prev-button-test"
                    onClick={() =>
                      changeActiveTab(tabs[activeTabIndex - 1].key)
                    }
                  >
                    <Icon name={theme === "arabic" ? "chevron-right" : "chevron-left"} />
                  </button>
                )}
                {(isMobile || isTablet) && activeTab !== tabs[tabs.length - 1].key && (
                  <button
                    className="numbers-block__next-button"
                    data-testid="numbers-block-next-button-test"
                    onClick={() =>
                      changeActiveTab(tabs[activeTabIndex + 1].key)
                    }
                  >
                    <Icon name={theme === "arabic" ? "chevron-left" : "chevron-right"} />
                  </button>
                )}
              </div>
              <div className="numbers-block__tab-content-row">
                <div className="numbers-block__tab-content-row--column">
                  <Lottie
                    lottieRef={animationMobileRef}
                    animationData={animationVariants[activeTab]}
                    className="numbers-block__image numbers-block__image--mobile"
                    autoplay={false}
                    loop={false}
                    onDOMLoaded={onAnimationDOMLoaded}
                  />
                </div>
                <Tab.Content>
                  {tabs.map((tab, index) => {
                    return (
                      <Tab.Pane
                        key={index}
                        eventKey={tab.key}
                        title={tab.title}
                        className="numbers-block__tab-content"
                      >
                        <div className="numbers-block__tab-content-row">
                          <div>
                            <h4 className="numbers-block__tab-title">
                              {tab.title}
                            </h4>
                            <Row
                              xs="2"
                              lg="3"
                              className="numbers-block__numbers-wrapper"
                            >
                              {tab.numbers.map((number, index) => {
                                return (
                                  <Col
                                    lg="4"
                                    key={index}
                                    className={`numbers-block__numbers-container ${
                                      tab.numbers.length > 4
                                        ? " numbers-block__numbers-container--small"
                                        : ""
                                    }`}
                                  >
                                    <div className="numbers-block__number-wrapper">
                                      <AnimatedNumber
                                        startAnimation={
                                          wasVisible &&
                                          !tabsViewedState[activeTabIndex]
                                            .triggered
                                        }
                                        number={number.value}
                                        tab={tab.key}
                                        activeTab={activeTab}
                                      />
                                      <span>%</span>
                                    </div>
                                    <div className="numbers-block__number-label">{number.label}</div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </div>
                      </Tab.Pane>
                    );
                  })}
                </Tab.Content>
              </div>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NumbersBlock.propTypes = {
  title: PropTypes.string, 
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string, 
      key: PropTypes.string, 
      numbers: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ),
};

NumbersBlock.defaultProps = {
  title: "",
  tabs: [
    {
      title: "",
      key: "",
      numbers: [
        {
          value: "",
          label: "",
        },
      ],
    },
  ],
};

export default NumbersBlock;
