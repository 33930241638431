/* istanbul ignore file */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { loadDesignSystemFonts } from '@takamol/qiwa-design-system/utils/loadDesignSystemFonts';
import { Loader } from './components/Loader/Loader';

import './i18n';
import './styles/styles.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

loadDesignSystemFonts();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense>
        <App />
    </Suspense>
);

reportWebVitals();
