import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MainContext } from "../components/contexts/context";

const useFetchContent = () => {
  const [contentByType, setContentByType] = useState({});
  const [contentLoading, setContentLoading] = useState(true);
  const [contentError, setContentError] = useState(null);
  const { theme } = useContext(MainContext);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setContentLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_FULL_URL}/api/v1/content?alias=homepage${
            "arabic" === theme ? "&language=ar" : ""
          }`
        );
        const data = response.data;

        if (data.content) {
          const processedContent = {};
          const typeCounts = {};

          Object.values(data.content).forEach((item) => {
            const { type } = item;
            if (type) {
              if (!processedContent[type]) {
                processedContent[type] = item;
                typeCounts[type] = 1;
              } else {
                if (typeCounts[type] === 1) {
                  processedContent[type] = [processedContent[type], item];
                } else {
                  processedContent[type].push(item);
                }
                typeCounts[type] += 1;
              }
            }
          });
          setContentByType(processedContent);
        }
      } catch (error) {
        console.error("Error fetching content:", error);
        setContentError(error);
      } finally {
        setContentLoading(false);
      }
    };

    fetchContent();
  }, [theme]);

  return { contentByType, contentLoading, contentError };
};

export default useFetchContent;
