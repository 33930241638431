import React, { useContext } from "react";
import Banner from "../../Banner/Banner";
import IconCardsGroup from "../../IconCardsGroup/IconCardsGroup";
import NumbersBlock from "../../NumbersBlock/NumbersBlock";
import ArticleCardsGroup from "../../ArticleCardsGroup/ArticleCardsGroup";
import OurMissionBanner from "../../OurMissionBanner/OurMissionBanner";
import Quote from "../../Quote/Quote";
import { mockData, mockDataAR } from "./mockData";
import BenefitsBanner from "../../BenefitsBanner/BenefitsBanner";
import StartExploringBanner from "../../StartExploringBanner/StartExploringBanner";
import MobileFriendlyBanner from "../../MobileFriendlyBanner/MobileFriendlyBanner";
import { MainContext } from "../../contexts/context";
import { getSignInLink } from "../../../utils/getSignInLink";
import { mockedData } from "../../../mockedData";
import useFetchContent from "../../../hooks/useFetchContent";

const LandingPage = () => {
  const contextValue = useContext(MainContext);
  const { laborSectorNumbers } = contextValue;
  const { theme } = useContext(MainContext);
  const env = process.env.REACT_APP_NODE_ENV;
  const { contentByType } = useFetchContent();

  const signInLink = getSignInLink(env, theme);

  return (
    <div data-testid="landing-page-test">
      <Banner variant={"hero"} />
      <IconCardsGroup groupData={contentByType?.why_qiwa_platform}/>
      <BenefitsBanner signInLink={signInLink} />
      <StartExploringBanner signInLink={signInLink} />
      <MobileFriendlyBanner signInLink={signInLink} />
      {laborSectorNumbers && (
        <NumbersBlock
          title={laborSectorNumbers.title}
          tabs={laborSectorNumbers.tabs}
          image={laborSectorNumbers.image}
        />
      )}
      <OurMissionBanner />
      <Quote quotes={theme === "english" ? mockData : mockDataAR} />
      {mockedData.landingPageWhatsNew.showOnPage && (
        <ArticleCardsGroup
          allArticlesLink={mockedData.landingPageWhatsNew.allArticlesLink}
          title={mockedData.landingPageWhatsNew.title}
          description=""
          cards={mockedData.landingPageWhatsNew.cards}
          smallCards={true}
        />
      )}
      <Banner variant={"grey-dots"} />
      <Banner variant={"body"} />
    </div>
  );
};

export default LandingPage;
