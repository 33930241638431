import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import ResultRow from "../ResultRow/ResultRow.tsx";
import { Loader } from "../../Loader/Loader.tsx";
import { useTranslation } from "react-i18next";
import Link from "../../Link/Link.tsx";

import "./SearchResults.scss";
import { Select } from "@takamol/qiwa-design-system/components";

const SearchResults = ({
  response,
  loading,
  query,
  onSortChange,
  activeSort,
  showMoreServices,
}: Props): React.ReactNode => {
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const showMoreUrl =
    window.location.pathname.substring(3) +
    window.location.search +
    "&show_all_services=1";

  const [charLimit, setCharLimit] = useState(400);

  useEffect(() => {
    const calculateCharLimit = () => {
      const windowWidth = window.innerWidth;
      const baseWidth = 568;
      const baseCharLimit = 400;
      let newCharLimit;

      if (windowWidth < baseWidth) {
        const difference = baseWidth - windowWidth;
        newCharLimit = Math.floor(baseCharLimit - difference * 1.4);
        newCharLimit = Math.max(50, newCharLimit);
      } else {
        newCharLimit = 400;
      }

      setCharLimit(newCharLimit);
    };

    calculateCharLimit();

    window.addEventListener("resize", calculateCharLimit);

    return () => {
      window.removeEventListener("resize", calculateCharLimit);
    };
  }, []);

  const mapCategoryTitleToType = (title: string) => {
    switch (title?.toLowerCase()) {
      case "services":
        return "service";
      case "tools and calculators":
        return "calculators";
      default:
        return "knowledge";
    }
  };
  const stripHTML = (htmlString: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  
  const cleanText = response.search_results?.definition?.[0]
    ? stripHTML(response.search_results.definition[0].body)
    : "";


  return (
    <div className="search-results">
      {loading && (
        <div className="certificate-validation-tool__loader">
          <Loader />
        </div>
      )}

      {!loading && (
        <>
          <div
            className={`search-results__header ${
              !response?.empty_page && "search-results__header__border-bottom"
            }`}
          >
            <div className="search-results__result-description">
              {response.search_results && (
                <>
                  <p>
                    {t("weFound")}{" "}
                    <span>
                      {response.pager.total_items}{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("resultsFor", {
                            interpolation: { escapeValue: false },
                          }),
                        }}
                      />
                    </span>{" "}
                    <span>“{query}”.</span>
                  </p>
                  <p className="search-results__result-description--suggestion">
                    {t("tryTypingMore")}
                  </p>
                </>
              )}
              {!response.search_results && (
                <span>{response?.empty_page?.searchPageMessage?.title}</span>
              )}
            </div>
            <div className="search-results__sort-dropdown-wrapper">
              <Form.Group>
                <Form.Label>{t("sortBy")}</Form.Label>
                <Select
                  id="results-sort-select"
                  data-testid="result-sort-select-test"
                  variant="business"
                  options={[
                    { value: "relevance_descending", option: t("relevance") },
                    { value: "changed_desc", option: t("newestFirst") },
                    { value: "changed_asc", option: t("oldestFirst") },
                  ]}
                  maxInputWidth={"100%"}
                  onChange={(newValue) => {
                    onSortChange(newValue);
                  }}
                  value={activeSort}
                />
              </Form.Group>
            </div>
          </div>
          <div className="search-results__results">
            {!response?.search_results && (
              <>
                <div
                  className="search-results__no-results"
                  dangerouslySetInnerHTML={{
                    __html: response?.empty_page?.searchPageMessage?.body,
                  }}
                />
                <div className="search-results__popular-on-qiwa__title">
                  {t("popularOnQiwa")}
                </div>
                {response?.empty_page?.categories.map(
                  (category: any, index: number) => (
                    <div
                      className="search-results__results-block search-results__results-block__popular"
                      key={index}
                    >
                      <h6 className="search-results__results-block-title">
                        {category?.title}
                      </h6>
                      {category?.searchItems.map((item: any, idx: number) => (
                        <ResultRow
                          {...item}
                          key={idx}
                          type={mapCategoryTitleToType(category?.title)}
                        />
                      ))}
                    </div>
                  )
                )}
              </>
            )}
            {response.search_results && response.search_results.definition && (
              <div className="search-results__definition-block">
                <div className="search-results__definition-block__container">
                  <div className="search-results__definition-block__title-section">
                    <p className="search-results__definition-block__title">
                      {t("definition")}
                    </p>
                  </div>
                  <div className="search-results__definition-block__content">
                    <p
                      className="search-results__definition-block__content__text"
                      dangerouslySetInnerHTML={{
                        __html: response.search_results.definition[0].body,
                      }}
                    />
                  </div>
                  {cleanText.length > charLimit && (
                    <Link
                      textLink={true}
                      label={t("showMore")}
                      to={response.search_results.definition[0].alias}
                      underlined={true}
                    />
                  )}
                </div>
              </div>
            )}
            {response.search_results && response.search_results.services && (
              <div className="search-results__results-block">
                <h6 className="search-results__results-block-title">
                  {t("services")}
                  <span className="search-results__results-block-amount--mobile">{` (${response.pager.total_items_categories.services})`}</span>
                  <span className="search-results__results-block-amount">{`${
                    response.pager.total_items_categories.services
                  } ${t("results", {
                    pluralEnding:
                      response.pager.total_items_categories.services === 1
                        ? ""
                        : "s",
                  })}`}</span>
                </h6>
                {response.search_results.services.map(
                  (result: any, index: number) => (
                    <ResultRow {...result} type="service" key={index} />
                  )
                )}

                {response.pager.total_items_categories.services > 5 &&
                  response.pager.current_page === 0 &&
                  response.pager.items_per_page === "10" &&
                  showMoreServices === 0 && (
                    <div className="search-results__show-more-services-block">
                      <Link
                        to={showMoreUrl}
                        label={t("showMoreServices", {
                          results:
                            response.pager.total_items_categories.services - 5,
                        })}
                        textLink={true}
                        underlined={true}
                      />
                    </div>
                  )}
              </div>
            )}
            {response.search_results &&
              response.search_results.tools_and_calculators && (
                <div className="search-results__results-block">
                  <h6 className="search-results__results-block-title">
                    {t("toolsAndCalculators")}
                    <span className="search-results__results-block-amount--mobile">{` (${response.pager.total_items_categories.tools_and_calculators})`}</span>
                    <span className="search-results__results-block-amount">{`${
                      response.pager.total_items_categories
                        .tools_and_calculators
                    } ${t("results", {
                      pluralEnding:
                        response.pager.total_items_categories
                          .tools_and_calculators === 1
                          ? ""
                          : "s",
                    })}`}</span>
                  </h6>
                  {response.search_results.tools_and_calculators.map(
                    (result: any, index: number) => (
                      <ResultRow {...result} type="calculators" key={index} />
                    )
                  )}
                </div>
              )}
            {response.search_results &&
              response.search_results.knowledge_center && (
                <div className="search-results__results-block">
                  <h6 className="search-results__results-block-title">
                    {t("knowledgeCenterMaterials")}
                    <span className="search-results__results-block-amount--mobile">{` (${response.pager.total_items_categories.knowledge_center})`}</span>
                    <span className="search-results__results-block-amount">{`${
                      response.pager.total_items_categories.knowledge_center
                    } ${t("results", {
                      pluralEnding:
                        response.pager.total_items_categories
                          .knowledge_center === 1
                          ? ""
                          : "s",
                    })}`}</span>
                  </h6>
                  {response.search_results.knowledge_center.map(
                    (result: any, index: number) => (
                      <ResultRow
                        {...result}
                        type="knowledge"
                        key={index}
                        activeSort={activeSort}
                      />
                    )
                  )}
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
};

type Props = {
  response: any;
  loading: boolean;
  query: string | null;
  onSortChange: any;
  activeSort: string;
  showMoreServices: number;
};

export default SearchResults;
