import React, { useState, useEffect, useContext } from "react";
import "./Information.scss";
import Icon from "../../../../Icon/Icon";
import classNames from "classnames";
import { MainContext } from "../../../../contexts/context";
import PropTypes from 'prop-types';

const Information = ({ data }) => {
  const { theme } = useContext(MainContext);
  return (
    <div className={classNames({
      "information mb-3": true,
      "information--ltr mb-3": theme === "english",
      "information--rtl mb-3": theme !== "english",
    })}>
      <hr />
      <div className="information__header-container">
      <Icon name="information" className="information__icon" height="24px" width="24px" />
        <div className="information__header" dangerouslySetInnerHTML={{ __html: data?.header }}></div>
      </div>
      <ul>
        {data?.questions?.map((question, index) => (
          <li key={index}>
            {question?.link && (  <a href={`/${localStorage.getItem("i18nextLng")}${question?.link}`}>
              <Icon name="link-arrow" className="information__arrow-icon"  />
              {question?.text}
            </a>) }
          </li>
        ))}
      </ul>
    </div>
  );
};

Information.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string 
    })) 
  }) };

export default Information;
