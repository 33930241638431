import React from 'react'
import mobileBackground from './img/body-mobile.png'
import mobileBackgroundAr from './img/body-mobile-image-ar.png'

import './StartExploringBanner.scss'
import Link from "../Link/Link";
import {MainContext} from "../contexts/context";
import { useTranslation } from "react-i18next";

const StartExploringBanner = ({signInLink}) => {
    const { theme } = React.useContext(MainContext);
    const { t } = useTranslation("translation", {
        keyPrefix: "landing.startExplore",
      });
    return(
        <div className="exploring-banner" data-testid="start-exploring-banner-test">
            <div className="exploring-banner__card">
                <div className="exploring-banner__card__title-wrapper">
                    <p>{t("title")}</p>
                    <h2>{t("description")}</h2>
                </div>
                <div className="exploring-banner__card__action-wrapper">
                    <p>
                       {t("secondDescription")}
                    </p>
                    <div className="exploring-banner__card__button-wrapper">
                        <Link to={signInLink} className="exploring-banner__card__button-wrapper__blue-button">
                           {t("tryForBusiness")}
                        </Link>
                        <Link to={signInLink} className="exploring-banner__card__button-wrapper__green-button">
                            {t("tryForEmployees")}
                        </Link>
                    </div>
                </div>
                <div className="exploring-banner__card__mobile-background">
                    <img src={theme === "english" ? mobileBackground : mobileBackgroundAr} alt="mobile-background"/>
                </div>
            </div>
        </div>
    )
}

export default StartExploringBanner;