import _ from 'lodash';

const transformHeroTitle = (breadcrumbsItem) => {
  if (!breadcrumbsItem) return;

  return _.chain(breadcrumbsItem)
    .split(/[\s-]+/)
    .map((word) => _.capitalize(word))
    .join('-')
    .value();
};

export default transformHeroTitle;
