import React, { useState, useEffect, useRef } from "react";
import Icon from "../../Icon/Icon";
import classnames from "classnames";
import SearchMobileFilters from "./SearchMobileFilters.tsx";
import { useTranslation } from "react-i18next";
import {
  CheckboxGroup,
  Checkbox,
} from "@takamol/qiwa-design-system/components";

import "./SearchFilters.scss";
import { isEmpty } from "lodash";

const SearchFilters = ({
  response,
  materials,
  setActiveMaterial,
  activeMaterial,
  getTopics,
  onMobileFiltersApply,
}: Props) => {
  const [showTopics, setShowTopics] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<Array<any>>([]);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const topicsRef = useRef<any>(null);
  const topicsTriggerRef = useRef<any>(null);

  const onShowTopics = (e: React.BaseSyntheticEvent) => {
    if (e.target.closest(".search-filters__topics-list")) {
      return;
    }
    setShowTopics(!showTopics);
  };

  const onTopicRemove = (id: string) => {
    let newTopics = selectedTopics;
    newTopics = selectedTopics.filter(function (topics) {
      return topics !== id;
    });
    setSelectedTopics(newTopics);
    getTopics(newTopics);
  };

  const onTopicClick = (value: string[]) => {
    setSelectedTopics(value);
    getTopics(value);
    setForceUpdate(forceUpdate + 1);
  };

  useEffect(() => {
    setSelectedTopics([]);
  }, [activeMaterial]);

  const onShowPopup = (show: boolean) => {
    if (show) {
      document.body.classList.add("body-freeze");
      setShowPopup(true);
    } else {
      document.body.classList.remove("body-freeze");
      setShowPopup(false);
    }
  };

  const storedLanguage = localStorage.getItem("i18nextLng");

  const mobileFiltersChange = (data: { material: string; topics: [] }) => {
    setSelectedTopics(data.topics);
    onMobileFiltersApply(data);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        topicsRef.current &&
        !topicsRef.current.contains(event.target) &&
        event.target !== topicsTriggerRef?.current
      ) {
        setShowTopics(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [topicsRef]);

  return (
    <div className="search-filters">
      <div className="search-filters__desktop-container">
        <h6 className="search-filters__title">{t("filters")}</h6>
        <ul className="search-filters__materials-list">
          <h6 className="search-filters__block-title">{t("materials")}</h6>
          <fieldset className="search-filters__materials-radio-wrapper">
            <li className="search-filters__material-list-item">
              <input
                id={"All Materials"}
                type="radio"
                name="material"
                checked={activeMaterial === "!"}
                onChange={() => setActiveMaterial("!")}
              />
              <label htmlFor={"All Materials"}>{t("allMaterials")}</label>
            </li>
            {materials &&
              materials.map((material: any, index: number) => {
                const materialKey = material.id ? material.id : material.en;
                return (
                  <React.Fragment key={index}>
                    <li className="search-filters__material-list-item">
                      <input
                        id={materialKey}
                        type="radio"
                        name="material"
                        checked={materialKey === activeMaterial}
                        onChange={() => setActiveMaterial(materialKey)}
                      />
                      <label htmlFor={materialKey}>
                        {material[storedLanguage ? storedLanguage : "en"]}
                      </label>
                    </li>
                  </React.Fragment>
                );
              })}
          </fieldset>
        </ul>
        <div className="search-filters__topics-block">
          <h6 className="search-filters__block-title">{t("topics")}</h6>
          <button
            className="search-filters__topics-dropdown"
            ref={topicsTriggerRef}
            onClick={onShowTopics}
          >
            {selectedTopics.length === 0
              ? t("pickATopic")
              : `${t("topics")} (${selectedTopics.length})`}
            <Icon className="topic-dropdown-icon" name="chevron-down" />
            <div
              ref={topicsRef}
              className={classnames(
                "search-filters__topics-list",
                showTopics && "search-filters__topics-list--open"
              )}
            >
              {!isEmpty(response) &&
                !isEmpty(response?.facets[1]) &&
                !isEmpty(response?.facets[1][0]?.topics) && (
                  <CheckboxGroup
                    name="topics-checkboxes"
                    value={selectedTopics}
                    onChange={onTopicClick}
                  >
                    {response?.facets &&
                      response?.facets[1][0]?.topics?.map(
                        (topic: any, index: number) => {
                          return (
                            <Checkbox
                              key={"checkbox-" + index}
                              id={topic?.values?.value}
                              label={
                                <span
                                  className={classnames(
                                    "search-filters__topic-label",
                                    topic.values.count === 0 &&
                                      "search-filters__topic-label--disabled"
                                  )}
                                >
                                  <span className="search-filters__topic-value">
                                    {topic?.values?.value}
                                  </span>
                                  <span className="search-filters__topic-count">
                                    {topic?.values?.count}
                                  </span>
                                </span>
                              }
                              value={topic?.values?.value}
                              isDisabled={topic?.values?.count === 0}
                            />
                          );
                        }
                      )}
                  </CheckboxGroup>
                )}
            </div>
          </button>
          <div className="search-filters__selected-topics">
            {selectedTopics &&
              selectedTopics.map((topic, index) => {
                return (
                  <div className="search-filters__topic-chip" key={index}>
                    {topic}
                    <button
                      className="search-filters__remove-chip-btn"
                      onClick={(e) => {
                        e.currentTarget.blur();
                        onTopicRemove(topic);
                      }}
                    >
                      <Icon
                        className="topic-chip-remove-icon"
                        name="close"
                        height="16"
                        width="16"
                      />
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="search-filters__mobile-container">
        <button
          className="search-filters__open-filters-btn"
          onClick={() => onShowPopup(true)}
        >
          <Icon className="" width="17" viewBox="3 0 17 24" name="filter" />{" "}
          {t("filters")}{" "}
          {(activeMaterial !== "!" || selectedTopics.length > 0) && (
            <span className="search-filters__filters-count">
              {activeMaterial === "!"
                ? selectedTopics.length
                : selectedTopics.length + 1}
            </span>
          )}
        </button>
        {showPopup && !isEmpty(response) && (
          <SearchMobileFilters
            onClose={() => onShowPopup(false)}
            response={response}
            materials={materials}
            setActiveMaterial={setActiveMaterial}
            activeMaterial={activeMaterial}
            getTopics={getTopics}
            onTopicClick={onTopicClick}
            selectedTopics={selectedTopics}
            onMobileFiltersApply={mobileFiltersChange}
          />
        )}
      </div>
    </div>
  );
};

type Props = {
  response: any;
  materials: Array<object>;
  setActiveMaterial: any;
  getTopics: any;
  activeMaterial: string;
  onMobileFiltersApply: any;
};

export default SearchFilters;
