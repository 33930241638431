/* istanbul ignore file */
export const mockedData = {
  mainMenu: [
    {
      "key": "Services for Business Owners",
      "description": "Handle all official matters online.",
      "secondLevelMenu": [
        {
          "title": "Hire employees",
          "description": "Hiring Saudis, non-Saudis and home workers.",
          "url": "/Hire_employees"
        },
        {
          "title": "Manage employees",
          "description": "Managing employment contracts, Employee Transfers and Work Permits. ",
          "url": "/Manage_employees"
        },
        {
          "title": "Manage company",
          "description": "Managing all companies under Unified ID, creating Labor Policy, Nitaqat.",
          "url": "/Manage_company"
        },
        {
          "title": "New company",
          "description": "Creating a new company file. Managing locations and activity.",
          "url": "/New_company"
        },
        {
          "title": "Manage your subscription",
          "description": "Managing subscription for a company or Unified ID.",
          "url": "/Manage_your_subscription"
        },
        {
          "title": "Tools & Calculators",
          "description": "Calculating Nitaqat and end of service reward. Certificates validation.",
          "url": "/Tools_Calculators"
        }
      ]
    },
    {
      "key": "Services for Employees",
      "description": "Handle all official matters online.",
      "secondLevelMenu": [
        {
          "title": "Get hired",
          "description": "Creating, managing and sharing your profile. Accepting or rejecting contracts. ",
          "url": "/Get_hired"
        },
        {
          "title": "Manage your current job",
          "description": "Reviewing contracts updates, Labor Policy, employer's profile.",
          "url": "/Manage_your_current job"
        },
        {
          "title": "Manage your home workers",
          "description": "Approving and conducting transfers. ",
          "url": "/Manage_your_home_workers"
        },
        {
          "title": "Salary certificate",
          "description": "Approving and conducting transfers. ",
          "url": "/Salary_certificate"
        },
        {
          "title": "Your Qiwa account",
          "description": "Managing account settings.",
          "url": "/Your_Qiwa_account"
        }
      ]
    },
    {
      "key": "About Qiwa platforms",
      "url": '/',
      "secondLevelMenu": []
    }
  ],
  hero: {
    mainTitle: '<p>Welcome to <span>Qiwa platform</span></p>',
    subTitle: '<p>Manage your business, develop your career and handle all official matters easily and conveniently <span>online 24/7.</span></p>'
  },
  whyUsingQiwa: {
    title: 'Why using <span>QIWA platform?</span>',
    cards: [
      {
        icon: 'desktop',
        description: '<span>Conveniently handle</span> key official matters online',
      },
      {
        icon: 'save-time',
        description: '<span>Save time</span> by carrying out the entire process through Qiwa platform',
      },
      {
        icon: 'digital-doc',
        description: 'Get access to all <span>documentation in digital form</span>',
      },
      {
        icon: 'desktop-mobile',
        description: 'Benefit from simplified processes for both <span>desktop and mobile</span>',
      },
    ]
  },
  navCards: {
    title: 'Qiwa Knowledge Center',
    description: 'Learn more and get the most out of your business or career with dedicated online services.',
    cards: [
      {
        icon: 'suitcase',
        title: 'For Business Owners',
        description: 'Control and manage your business effectively.',
        url: '/?tab=business',
        variant: 'business'
      },
      {
        icon: 'user',
        title: 'For Employees ',
        description: 'Boost your career and increase chances of employment.',
        url: '/?tab=individuals',
        variant: 'individuals'
      },
      {
        icon: 'contract',
        title: 'For Service Providers',
        description: 'Improve information flow and cooperation with business owners.',
        url: '/?tab=providers',
        variant: 'providers'
      },
    ]
  },
  toolsAndCalculators: {
    title: 'Tools & Calculators',
    description: "Use Qiwa's interactive tools and calculators for financial accounting and validating certificates.",
    groupCta: false,
    theme: 'neutral',
    cards: [
      {
        title: 'Nitaqat Calculator',
        description: 'Check the calculator for Saudi labor market regulation program.',
        theme: 'neutral',
        url: '/',
        tag: false,
        type: 'regular'
      },
      {
        title: 'End of service reward',
        description: 'Check the sum of the end of service reward.',
        theme: 'neutral',
        url: '/',
        tag: false,
        type: 'regular'
      },
      {
        title: 'Certificates validation',
        description: 'Validate certificates, e.g., the Saudization certificate of the company.',
        theme: 'neutral',
        url: '/',
        tag: false,
        type: 'regular'
      },
    ],
  },
  saudiLaborSystem: {
    title: 'Saudi Labor System',
    description: "Everything related to the labor system in the Kingdom of Saudi Arabia, including labor system materials, auxiliary tools, and illustrative examples for business owners.",
    groupCta: {
      label: 'Go to all chapters',
      url: '/'
    },
    theme: 'neutral',
    cards: [
      {
        title: 'Tariffs and General Provisions',
        description: 'Essential definitions and provisions descriptions.',
        theme: 'neutral',
        url: '/',
        tag: 'chapter 1',
        type: 'chapter'
      },
      {
        title: 'Work relationships',
        description: 'Employment contracts, Duties and Disciplinary Rules.',
        theme: 'neutral',
        url: '/',
        tag: 'chapter 2',
        type: 'chapter'
      },
      {
        title: 'Hiring non-Saudis',
        description: 'The terms of recruitment, transfer of services, change of profession, etc.',
        theme: 'neutral',
        url: '/',
        tag: 'chapter 3',
        type: 'chapter'
      },
    ],
  },
  laborSectorNumbers: {
    title: 'Labor sector in numbers',
    image: 'https://fastly.picsum.photos/id/7/500/600.jpg?hmac=tqgAygACLw9d6Ywa2mKdIYoQKCsu2OX2HGs1YxPwHro',
    tabs: [
      {
        title: 'Women empowerment',
        key: 'women',
        numbers: [
          {
            value: '32%',
            label: 'Women from the Saudi work force in the private sector'
          },
          {
            value: '68%',
            label: 'Man from the Saudi work force in the private sector'
          },
        ]
      },
      {
        title: 'Nationalization',
        key: 'nationalization',
        numbers: [
          {
            value: '91%',
            label: 'Financial Institutions'
          },
          {
            value: '84%',
            label: 'Petroleum and Natural Gas'
          },
          {
            value: '73%',
            label: 'Communications'
          },
          {
            value: '68%',
            label: 'Electricity, Gas and Water'
          },
          {
            value: '52%',
            label: 'Electricity, Gas and Water'
          },
          {
            value: '26%',
            label: 'Electricity, Gas and Water'
          },
        ]
      },
      {
        title: 'Stimulation Investment',
        key: 'investment',
        numbers: [
          {
            value: '94%',
            label: 'Saudi'
          },
          {
            value: '6%',
            label: 'Foreign'
          },
        ]
      },
    ],
  },
  laborMarketsReports: {
    title: 'Labor Market Reports in the Kingdom',
    description: 'We are working to contribute to the national transformation by providing reports and statistics on the labor market to be a cornerstone of the decision-making process.',
    cards: [
      {
        title: 'Visas and tourism in Saudi Arabia ',
        description: 'Measure the level of quality of localization in the establishments of the Kingdom of Saudi Arabia. The private sector establishments are classified based on an average of points based on a set of criteria',
        image: 'https://fastly.picsum.photos/id/718/900/600.jpg?hmac=iAQnUF3WfJ1_HN_vyU117fjnijLDbqIoKugF5el84qE',
        cta: {
          url: '/',
          label: 'Learn More',
        },
        showTwitter: false
      },
      {
        title: 'Visas and tourism in Saudi Arabia ',
        description: 'Measure the level of quality of localization in the establishments of the Kingdom of Saudi Arabia. The private sector establishments are classified based on an average of points based on a set of criteria',
        image: 'https://fastly.picsum.photos/id/124/900/600.jpg?hmac=b1Qjz7oF-zKYG5_36FkPxTpb28moB3uaQkG4QS9HASk',
        cta: {
          url: '/',
          label: 'Learn More',
        },
        showTwitter: true
      },
    ]
  },
  footerData: {
    sections: [
      {
        title: 'Business Owners',
        url: '/?tab=business',
        sectionLinks: [
          {
            label: 'Hire employees',
            url: '/',
          },
          {
            label: 'Manage current employees',
            url: '/',
          },
          {
            label: 'Manage establishment',
            url: '/',
          },
          {
            label: 'Create new establishment',
            url: '/',
          },
          {
            label: 'Manage Qiwa account',
            url: '/',
          },
          {
            label: 'Tools & Calculators',
            url: '/',
          },
          {
            label: 'Saudi Labor System',
            url: '/',
          },
        ]
      },
      {
        title: 'Employees',
        url: '/?tab=individuals',
        sectionLinks: [
          {
            label: 'Get hired',
            url: '/',
          },
          {
            label: 'Manage your current job',
            url: '/',
          },
          {
            label: 'Manage your home workers',
            url: '/',
          },
          {
            label: 'Tools & Calculators',
            url: '/',
          },
          {
            label: 'Saudi Labor System',
            url: '/',
          },
        ]
      },
      {
        title: 'Service Providers',
        url: '/?tab=providers',
        sectionLinks: [
          {
            label: 'Manage Labor Policy requests',
            url: '/',
          },
          {
            label: 'Manage your Law Office Profile',
            url: '/',
          },
        ]
      },
      {
        title: 'Qiwa Platform',
        url: '',
        sectionLinks: [
          {
            label: 'Business Profile',
            url: '/',
          },
          {
            label: 'Individual Profile',
            url: '/',
          },
          {
            label: 'About QIWA platform',
            url: '/',
          },
        ]
      },
    ],
    ctaBar: {
      text: 'Have a problem or see any opportunities to upgrade our site?',
      cta: {
        label: 'Contact us',
        url: '/'
      }
    },
    socialLinks: [
      {
        label: false,
        icon: 'linkedin',
        url: 'https://www.linkedin.com/company/qiwa-sa',
      },
      {
        label: false,
        icon: 'facebook',
        url: 'https://www.facebook.com/qiwa.platform/',
      },
      {
        label: false,
        icon: 'twitter',
        url: 'https://twitter.com/qiwa_sa',
      },
    ],
    secondaryLinks: [
      {
        label: 'Term of use',
        icon: false,
        url: '/',
      },
      {
        label: 'Privacy policy',
        icon: false,
        url: '/',
      },
      {
        label: 'Contact Us',
        icon: false,
        url: '/',
      },
    ],
    copyright: 'Test copyright'
  },
  hireEmployees: {
    title: 'Hire employees',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'business',
    cards: [
      {
        title: 'Hiring non-Saudi employees',
        description: 'Everything you need to know to start onboarding non-Saudis into your company.',
        theme: 'business',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Hiring Saudi employees',
        description: 'Everything you need to know to start onboarding Saudis into your company.',
        theme: 'business',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Work Visas',
        description: 'Manage and renew Work Visas of all types.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Work Permits',
        description: 'Issue Work Permits for non-Saudi employees.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Employee Transfer',
        description: 'Hire a non-Saudi from another company. Hire a home worker or dependant.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Contract Management',
        description: 'Create contracts templates, manage or terminate Employment Contracts.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
    ],
  },
  manageCurrentEmployees: {
    title: 'Manage current employees',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'business',
    cards: [
      {
        title: 'Managing employees on Qiwa',
        description: 'Everything you need to know about managing your employees and corresponding services.',
        theme: 'business',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Contract Management',
        description: "Create contracts templates, manage or terminate your employees' contracts.",
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Employee Transfer',
        description: 'Manage Employee Transfer requests.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Work Permits',
        description: 'Issue and renew Work Permits for non-Saudi employees.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Change Occupation',
        description: 'Change or correct occupation of your employees.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Training Certificates',
        description: 'Add and manage trainings for your employees.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
    ],
  },
  manageEstablishment: {
    title: 'Manage establishment',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'business',
    cards: [
      {
        title: 'Managing establishment on Qiwa',
        description: 'Everything you need to know about managing your establishment online through Qiwa.',
        theme: 'business',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Work Policy',
        description: "Create a standard template or custom policy and manage it with Law Office.",
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Nitaqat',
        description: "Check and improve your company's Nitaqat level.Manage Employee Transfer requests.",
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Establishment File',
        description: 'Create Establishment File. Manage locations and company activity. Close a company.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Manage Establishments Group',
        description: 'Manage all establishments under the same Unified ID.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Violations',
        description: 'Check violations and fees. Object to a violation.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Certificates',
        description: 'Check and add certificates for your establishment.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'Manage Finances',
        description: 'Check and manage invoices and bills.',
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
    ],
  },
  createNewEstablishment: {
    title: 'Create new establishment',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'business',
    cards: [
      {
        title: 'Creating new establishment',
        description: 'Everything about opening a new company - process, requirements and costs.',
        theme: 'business',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Establishment File',
        description: "Register a new establishment file on Qiwa.",
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
    ],
  },
  manageQiwaBusinessAccount: {
    title: 'Manage Qiwa business account',
    titleLink: '/',
    description: '',
    theme: 'business',
    groupCta: false,
    cards: [
      {
        title: 'Managing your Qiwa business account',
        description: 'Everything on managing a business account, including subscription and user management.',
        theme: 'business',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Subscription Management',
        description: "Subscribe to Qiwa. Manage or renew your account subscription.",
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
      {
        title: 'User Management',
        description: "Manage your business account users and privileges.",
        theme: 'business',
        url: '/',
        tag: 'Service',
        type: 'service'
      },
    ],
  },
  getHired: {
    title: 'Get hired',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'individuals',
    cards: [
      {
        title: 'Managing your career with Qiwa',
        description: 'Everything about creating an individual professional profile on Qiwa.',
        theme: 'individuals',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Get hired',
        description: 'Create and manage your professional profile. Review, accept or reject new contracts.',
        theme: 'individuals',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
    ],
  },
  manageYourCurrentJob: {
    title: 'Manage your current job',
    titleLink: '/',
    description: '',
    theme: 'individuals',
    groupCta: false,
    cards: [
      {
        title: 'Managing your current job',
        description: 'Everything about your current employment and related services.',
        theme: 'individuals',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Job contracts',
        description: 'Pending contracts, signed contracts and termination requests.',
        theme: 'individuals',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
      {
        title: "Employers' Policies",
        description: "Read your terms of work in Employers' Policy.",
        theme: 'individuals',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
      {
        title: "Training Management",
        description: "Manage trainings and add to your profile.",
        theme: 'individuals',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: "End of service reward",
        description: "Calculate your end of service reward.",
        theme: 'individuals',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
      {
        title: "Salary certificate",
        description: "Pending, active and closed salary certificates.",
        theme: 'individuals',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
    ],
  },
  manageHomeWorkers: {
    title: 'Manage home workers',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'individuals',
    cards: [
      {
        title: 'Managing home workers transfers',
        description: 'Everything you need to know about the home workers transfers.',
        theme: 'individuals',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Home workers',
        description: 'Review and approve or reject transfers.',
        theme: 'individuals',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
    ],
  },
  lawOffices: {
    title: 'Law offices',
    titleLink: '/',
    description: '',
    groupCta: false,
    theme: 'providers',
    cards: [
      {
        title: 'Labor Policy',
        description: 'Manage requests and cooperate on creating new policies.',
        theme: 'providers',
        url: '/',
        tag: 'Article',
        type: 'article',
      },
      {
        title: 'Manage your profile',
        description: 'Manage your Law Office profile and settings.',
        theme: 'providers',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
      {
        title: 'How to register as law office',
        description: 'Issue visa, work permit or hire non-Saudi from other company',
        theme: 'providers',
        url: '/',
        tag: 'Service',
        type: 'service',
      },
    ],
  },
  landingPageWhatsNew: {
    title: 'See what’s new',
    cards: [
      {
        title: 'Visas and tourism in Saudi Arabia',
        description: 'Measure the level of quality of localization in the establishments of the Kingdom of Saudi Arabia.',
        image: 'https://fastly.picsum.photos/id/970/900/600.jpg?hmac=z9uioQn4EQTrIuMeYU-4lQdrijRXJ_VpHsLeseOlem0',
        cta: {
          url: '/',
          label: 'Read More',
        },
        showTwitter: false
      },
      {
        title: 'Visas and tourism',
        description: 'Measure the level of quality of localization',
        image: 'https://fastly.picsum.photos/id/124/900/600.jpg?hmac=b1Qjz7oF-zKYG5_36FkPxTpb28moB3uaQkG4QS9HASk',
        cta: {
          url: '/',
          label: 'Read More',
        },
        showTwitter: false
      },
      {
        title: 'Visas and tourism in Saudi Arabia',
        description: 'Measure the level of quality of localization',
        image: 'https://fastly.picsum.photos/id/637/900/600.jpg?hmac=yc8N-MPJLnzEaJIQSAgCjav5CCp4n-vGhJQ4oRZcB2Y',
        cta: {
          url: '/',
          label: 'Read More',
        },
        showTwitter: false
      },
    ],
    allArticlesLink: {
      url: '#',
      label: 'Go to all articles'
    },
    showOnPage: false
  }
}