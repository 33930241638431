import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Link from "../../Link/Link";
import { getUsers } from "../../../utils/qiwaUtils";

import "./CallingServicePage.scss";
import Feedback from "../../Feedback/Feedback";

const CallingServicePage = () => {
  const [isAgreedToTerms, setIsAgreedToTerms] = useState<boolean>(false);
  const { t } = useTranslation("translation", { keyPrefix: "cvcPage" });

  const availableUsers = getUsers();
  return (
    <div className="calling-service-page">
      <div className="calling-service-page__hero">
        <Container>
          <Row>
            <Col lg="8">
              <Breadcrumbs theme="light">
                <span>{t("qiwaCustomerVideoCallingService")}</span>
              </Breadcrumbs>
              <h2>{t("qiwaCustomerVideoCallingService")}</h2>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="calling-service-page__content">
        <Container>
          <Row>
            <Col lg="8">
              <h3>{t("customerCareService")}</h3>
              <h5>{t("forOurDifferentlyAbledPeople")}</h5>
              <p>{t("mainText")}</p>
              <fieldset>
                <input
                  id="agree-terms-video-calls"
                  type="checkbox"
                  checked={isAgreedToTerms}
                  onChange={() => {
                    setIsAgreedToTerms((prev) => !prev);
                  }}
                />
                <label htmlFor="agree-terms-video-calls">
                  {t("byClickingTheCheckbox")}{" "}
                  <Link
                    to="/terms-and-conditions"
                    label={t("termsAndConditions")}
                    underlined={true}
                  />{" "}
                  {t("and")}{" "}
                  <Link
                    to="/service-level-agreement"
                    label={t("serviceLevelAgreement")}
                    underlined={true}
                  />
                </label>
              </fieldset>
              <Link
                to={`https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fcall%2F0%2F0%3Fusers%3D${availableUsers}%26withVideo%3Dtrue&type=call&deeplinkId=34acf93b-f164-4a6c-a6d7-104cc8c4cbf6&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true`}
              >
                <button
                  className="calling-service-page__start-call-btn"
                  disabled={!isAgreedToTerms}
                >
                  {t("startVideoCall")}
                </button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Feedback />
    </div>
  );
};

export default CallingServicePage;
