import React from "react";
import { Link as ReactLink } from "react-router-dom";
import classNames from "classnames";
import Icon from "../Icon/Icon";

import "./Link.scss";

// Can be changed in case we will have different basePath.
const basePath = "";
const linkVariants = [
  "business",
  "individuals",
  "white",
  "danger",
  "success",
  "info",
  "warning",
  "black",
];

function getLocationOrigin() {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? ":" + port : ""}`;
}

function hasBasePath(path: string) {
  return path === basePath || path.startsWith(basePath + "/");
}

function isLocalURL(url: string) {
  if (url.trim().startsWith("/")) return true;
  return false;
}

function isAbsoluteLocalURL(url: string) {
  const locationOrigin = getLocationOrigin();
  const resolved = new URL(url, locationOrigin);
  return resolved.origin === locationOrigin && hasBasePath(resolved.pathname);
}

function getRelativeFromLocalAbsolute(url: string) {
  const locationOrigin = getLocationOrigin();
  const resolved = new URL(url, locationOrigin);
  return resolved.pathname.replace(basePath, "");
}

// Function will remove base path prefix from the link source URL
// before path it to React Router link component.

function cleanURL(url: string) {
  if (basePath && url.startsWith(basePath)) {
    return url.replace(basePath, "");
  }

  const locationOrigin = getLocationOrigin();
  const resolved = new URL(url, locationOrigin);
  const prefix = localStorage.getItem("i18nextLng");
  const resultUrl =
    resolved.pathname.replace(
      resolved.pathname,
      (prefix === "en" ? "/en" : "/ar") +
        (resolved.pathname === "/" ? "" : resolved.pathname)
    ) + resolved.search;

  return resultUrl;
}

const Link = ({
  to,
  label,
  children,
  className,
  onClick,
  textLink,
  variant,
  underlined,
  revert,
  icon,
  iconPosition,
  iconSize,
  disabled,
  ...props
}: Props): React.ReactNode => {
  const existingUrl = to && !!to.length && !Array.isArray(to) ? to : "";
  const isLocal = isLocalURL(existingUrl);
  const isAbsoluteLocal = isAbsoluteLocalURL(existingUrl);
  const clickHandler = disabled ? (e: any) => e.preventDefault() : onClick;
  // If this local URL from next site starting from '/'
  if (isLocal) {
    return (
      <ReactLink
        to={cleanURL(existingUrl)}
        className={classNames(
          "kc-link",
          textLink ? "kc-link--text-link" : "",
          linkVariants.includes(variant ? variant : "default")
            ? "kc-link--" + variant
            : "kc-link--business",
          underlined ? "kc-link--underlined" : "",
          revert ? "kc-link--revert" : "",
          disabled ? "kc-link--disabled" : "",
          className
        )}
        onClick={clickHandler}
        aria-disabled={disabled}
        {...props}
        data-testid="link-test"
      >
        {iconPosition === "before" && icon && (
          <Icon
            className={`kc-link__link-icon__before  kc-link__link-icon__${iconSize}`}
            viewBox="0 0 24 24"
            name="external-link"
            height={iconSize === "small" ? "16px" : "24px"} width={iconSize === "small" ? "16px" : "24px"}
          />
        )}
        {label && <span className="kc-link__label">{label}</span>}
        {children}
        {!iconPosition && icon && (
          <Icon
            className={`kc-link__link-icon__after  kc-link__link-icon__${iconSize}`}
            viewBox="0 0 24 24"
            name="external-link"
            height={iconSize === "small" ? "16px" : "24px"} width={iconSize === "small" ? "16px" : "24px"}
          />
        )}
      </ReactLink>
    );
  }
  // If URL passed as absolute, but is a path to React site pages
  if (isAbsoluteLocal) {
    return (
      <ReactLink
        to={getRelativeFromLocalAbsolute(existingUrl)}
        {...props}
        className={classNames(
          "kc-link",
          textLink ? "kc-link--text-link" : "",
          linkVariants.includes(variant ? variant : "default")
            ? "kc-link--" + variant
            : "kc-link--business",
          underlined ? "kc-link--underlined" : "",
          disabled ? "kc-link--disabled" : "",
          className
        )}
        onClick={clickHandler}
        data-testid="link-test"
        aria-disabled={disabled}
      >
        {iconPosition === "before" && icon && (
          <Icon
            className={`kc-link__link-icon__before  kc-link__link-icon__${iconSize}`}
            viewBox="0 0 24 24"
            name="external-link"
            height={iconSize === "small" ? "16px" : "24px"} width={iconSize === "small" ? "16px" : "24px"}
          />
        )}
        {label && <span className="kc-link__label">{label}</span>}
        {children}
        {!iconPosition && icon && (
          <Icon
            className={`kc-link__link-icon__after  kc-link__link-icon__${iconSize}`}
            viewBox="0 0 24 24"
            name="external-link"
            height={iconSize === "small" ? "16px" : "24px"} width={iconSize === "small" ? "16px" : "24px"}
          />
        )}
      </ReactLink>
    );
  }
  // Otherwise return just a link to open in new tab.
  return (
    <a
      {...props}
      href={existingUrl}
      className={classNames(
        "kc-link",
        textLink ? "kc-link--text-link" : "",
        linkVariants.includes(variant ? variant : "default")
          ? "kc-link--" + variant
          : "kc-link--business",
        underlined ? "kc-link--underlined" : "",
        disabled ? "kc-link--disabled" : "",
        className
      )}
      onClick={clickHandler}
      target="_blank"
      aria-disabled={disabled}
      rel="noreferrer"
      data-testid="link-test"
    >
      {iconPosition === "before" && icon && (
        <Icon
          className={`kc-link__link-icon__before`}
          viewBox="0 0 24 24"
          height={iconSize === "small" ? "16px" : "24px"}
          width={iconSize === "small" ? "16px" : "24px"}
          name="external-link"
        />
      )}
      {label && <span className="kc-link__label">{label}</span>}
      {children}
      {!iconPosition && icon && (
        <Icon
          className={`kc-link__link-icon__after  kc-link__link-icon__${iconSize}`}
          height={iconSize === "small" ? "16px" : "24px"}
          width={iconSize === "small" ? "16px" : "24px"}
          viewBox="0 0 24 24"
          name="external-link"
        />
      )}
    </a>
  );
};

type Props = {
  target?: string;
  className?: string;
  to: string;
  label?: string;
  children: any;
  textLink?: boolean;
  variant?: string;
  revert?: boolean;
  underlined?: boolean;
  onClick: any;
  icon?: boolean;
  iconPosition?: string;
  iconSize?: string;
  disabled?: boolean;
};

Link.defaultProps = {
  className: "",
  to: "",
  label: "",
  children: "",
  textLink: true,
  variant: "default",
  revert: false,
  underlined: false,
  onClick: () => {
    return;
  },
};

export default Link;
