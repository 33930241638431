import React, { useContext, useState, useEffect, useRef } from "react";
import "./TableOfContent.scss";
import Icon from "../../../../Icon/Icon";
import { MainContext } from "../../../../contexts/context";
import { Accordion } from "@takamol/qiwa-design-system/components";
import classNames from "classnames";
import Text from "../Text/Text";
import { useLocation } from "react-router-dom";
import _, { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const TableOfContents = ({ contents, title }) => {
  const { theme, isTablet, isMobile } = useContext(MainContext);
  let location = useLocation();
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const accordionRef = useRef(null);
  const [openIndex, setOpenIndex] = useState(0);
  
  useEffect(() => {
    setOpenIndex(0);
  }, [contents]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (
        currentScrollY > lastScrollY &&
        openIndex != null &&
        lastScrollY > 640
      ) {
        setOpenIndex(null);
      }
      if(currentScrollY < lastScrollY){
        setOpenIndex(null)
      }

      lastScrollY = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [openIndex]);

  const [headerRefs, setHeaderRefs] = useState([]);

  function generateSlug(text) {
    text = text?.trimStart();

    let endsWithSpace = text?.endsWith(" ");
    let slug = "#" + _?.kebabCase(text);

    if (text?.endsWith("?")) {
      slug += "?";
    }

    return slug;
  }
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const headers = Array.from(
      document.querySelectorAll(
        ".journey-article__body h2:not([class]), .journey-article__body h3:not([class]), .labor-education-article__body h2:not([class]), .labor-education-article__body h3:not([class])"
      )
    );
    setHeaders(headers);
  }, [theme, contents]);

  headers.forEach((header, index) => {
    if (headerRefs[index]) {
      headerRefs[index].current = header;
    }
  });

  const result = headers.map((header) => ({
    text: header.innerText,
    hash: generateSlug(header.innerText),
  }));
  const contentData = isEmpty(contents) ? result : contents;
  useEffect(() => {
    if (!headerRefs || headers.length !== headerRefs.length) {
      const newRefs = headers.map(() => React.createRef());
      setHeaderRefs(newRefs);
    }
  });

  const handleLinkClick = (index, event) => {
    event.preventDefault();
    const contentItem = contentData[index];
    if (contentItem && contentItem.link) {
      const hashIndex = contentItem.link.indexOf("#");
      if (hashIndex > -1) {
        const hash = contentItem.link.substring(hashIndex);
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          const headerPosition =
            targetElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition =
            headerPosition - (isTablet || isMobile ? 800 : 200);
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
          setOpenIndex(index);
          return;
        }
      }
    }

    if (headerRefs[index] && headerRefs[index].current) {
      const headerPosition =
        headerRefs[index].current.getBoundingClientRect().top +
        window.pageYOffset;
      const offsetPosition =
        headerPosition - (isTablet || isMobile ? 800 : 200);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setOpenIndex(index);
  };

  const handleAccordionChange = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleClick = (link) => (event) => {
    event.preventDefault();
    const hashPart = new URL(link, window.location.origin).hash;
    const decodedHash = decodeURIComponent(hashPart);
    window.location.hash = hashPart;
    const id = decodedHash.replace("#", "");
    const element = document.getElementById(id);
    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.pageYOffset - (isTablet || isMobile ? 300 : 200);
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <>
      {!isEmpty(headers) && (
        <div
          ref={accordionRef}
          className={classNames({
            "table-of-contents-articles": true,
            "table-of-contents-articles--rtl": theme !== "english",
          })}
        >
          {title === "Table of contents" && !isEmpty(headers) && (
            <>
              {isTablet || isMobile ? (
                <Accordion
                  hasBorderBottom={false}
                  iconPlacement="end"
                  shouldAutoScrollOnOpen={false}
                >
                  <Accordion.Item
                    isOpened={openIndex === 0}
                    style={{ backgroundColor: "#fff", color: "#333" }}
                    onClick={() => handleAccordionChange(0)}
                  >
                    <Accordion.Header>{t("tableOfContent")}</Accordion.Header>
                    <Accordion.Content>
                      {contentData?.map((content, index) => (
                        <React.Fragment key={index}>
                          {(!isEmpty(contents)
                            ? !isEmpty(content?.link)
                            : true) && (
                            <div className="table-of-contents-articles__container">
                              <div className="table-of-contents-articles__group">
                                <div className="table-of-contents-articles__icon">
                                  <Icon
                                    name={
                                      theme === "english"
                                        ? "arrow-right"
                                        : "arrow-left"
                                    }
                                  />
                                </div>
                                {!isEmpty(contents) ? (
                                  <button
                                    className={
                                      "table-of-contents-articles__link"
                                    }
                                    onClick={handleClick(content?.link)}
                                  >
                                    {content?.text}
                                  </button>
                                ) : (
                                  <button
                                    className={
                                      "table-of-contents-articles__link"
                                    }
                                    href={content?.hash}
                                    onClick={(e) => handleLinkClick(index, e)}
                                  >
                                    {content?.text}
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </Accordion.Content>
                  </Accordion.Item>
                </Accordion>
              ) : (
                <>
                  <div className="table-of-contents-articles__header">
                    {t("tableOfContent")}
                  </div>
                  {contentData?.map((content, index) => (
                    <React.Fragment key={index}>
                      {(!isEmpty(contents)
                        ? !isEmpty(content?.link)
                        : true) && (
                        <div className="table-of-contents-articles__container">
                          <div className="table-of-contents-articles__group">
                            <div className="table-of-contents-articles__icon">
                              <Icon
                                name={
                                  theme === "english"
                                    ? "arrow-right"
                                    : "arrow-left"
                                }
                              />
                            </div>
                            {!isEmpty(contents) ? (
                              <button
                                className={"table-of-contents-articles__link"}
                                onClick={handleClick(content?.link)}
                              >
                                {content?.text}
                              </button>
                            ) : (
                              <button
                                className={"table-of-contents-articles__link"}
                                href={content?.hash}
                                onClick={(e) => handleLinkClick(index, e)}
                              >
                                {content?.text}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          )}

          {title === "Objectives" && (
            <>
              <div className="table-of-contents-articles__header">
                {t("objectives")}
              </div>
              <div className="objectives-container">
                <Accordion iconPlacement="end" shouldAutoScrollOnOpen={false}>
                  {contents.map((content, index) => (
                    <Accordion.Item
                      style={{ backgroundColor: "#fff", color: "#333" }}
                      key={index}
                      isOpened={openIndex === index}
                      onClick={() => handleAccordionChange(index)}
                    >
                      <Accordion.Header>
                        <Text text={content?.table_of_contents_header} />
                      </Accordion.Header>
                      <Accordion.Content>
                        <Text text={content?.table_of_contents_field} />
                      </Accordion.Content>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

TableOfContents.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      hash: PropTypes.string,
    })
  ), // Contents can be empty, and elements should have text and link or hash
  title: PropTypes.string, // Title is used as a prop, but you could make it optional or required based on its use in your component
};

export default TableOfContents;
