import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";

import "./OurMissionBanner.scss";

const OurMissionBanner = () => {
  const { t } = useTranslation("translation", { keyPrefix: "landing.ourMission" });
  return (
    <div className="our-mission-banner" data-testid="our-mission-banner-test">
      <Container>
        <p className="our-mission-banner__badge">{t("title")}</p>
        <h3 className="our-mission-banner__title">
         {t("improve")}
        </h3>
        <p className="our-mission-banner__description">
          {t("enable")}
        </p>
        <Row
          xs="1"
          md="2"
          lg="2"
          className="our-mission-banner__benefits-wrapper"
        >
          <Col className="our-mission-banner__benefit">
            <h4>
              <span className="our-mission-banner__checkmark-wrapper">
                <Icon name="checkmark" height="20" width="20"></Icon>
              </span>
              {t("transparency")}
            </h4>
            <p>
              {t("weGuarantee")}
            </p>
          </Col>
          <Col className="our-mission-banner__benefit">
            <h4>
              <span className="our-mission-banner__checkmark-wrapper">
                <Icon name="checkmark" height="20" width="20"></Icon>
              </span>
              {t("development")}
            </h4>
            <p>
              {t("weAim")}
            </p>
          </Col>
          <Col className="our-mission-banner__benefit">
            <h4>
              <span className="our-mission-banner__checkmark-wrapper">
                <Icon name="checkmark" height="20" width="20"></Icon>
              </span>
              {t("support")}
            </h4>
            <p>
              {t("weDevelop")}
            </p>
          </Col>
          <Col className="our-mission-banner__benefit">
            <h4>
              <span className="our-mission-banner__checkmark-wrapper">
                <Icon name="checkmark" height="20" width="20"></Icon>
              </span>
              {t("improvement")}
            </h4>
            <p>
              {t("weEmpower")}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurMissionBanner;
