import React, { useContext } from "react";
import { Container, Nav } from "react-bootstrap";
import Icon from "../../Icon/Icon";
import Link from "../../Link/Link";
import MainMenuLogin from "../MainMenuLogin";
import { MainContext } from "../../contexts/context";
import PropTypes from 'prop-types';

const MainMobilePane = ({firstLevelLinks, closeMenu}) => {
  const { theme } = useContext(MainContext);
  const parsedTheme = {
    'english': 'EN',
    'arabic': 'AR'
  }

  return (
    <div data-testid='main-mobile-pane-test' className="mobile-menu-pane__main-pane">
      <Container>
        <ul className="mobile-menu-pane__first-level-list">
          {firstLevelLinks.map((link, index) => {
            return (
              <li key={index} className="mobile-menu-pane__first-level-list-item">
                <Link textLink={false} to={link.url} className="mobile-menu-pane__first-level-button" onClick={() => closeMenu()}>
                  {link.key}
                  <Icon name='arrow-right'/>
                </Link>
              </li>
            )
          })}
        </ul>
      </Container>
      <div className="mobile-menu-pane__actions-block">
        <MainMenuLogin type="mobile" onClose={closeMenu}/>
        <div className="mobile-menu-pane__language">
          <Nav.Link as='button' className="mobile-menu-pane__language-button" eventKey={"Select your language"}>
            <Icon name='globe' />
            <span>{parsedTheme[theme]}</span>
          </Nav.Link>
        </div>
      </div>
    </div>
  )
};

MainMobilePane.propTypes = {
  firstLevelLinks: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,  
    key: PropTypes.string  
  })),  
  closeMenu: PropTypes.func 
};

MainMobilePane.defaultProps = {
  firstLevelLinks: [],
  closeMenu: () => {return},
}

export default MainMobilePane;