import React from "react";
import { Accordion } from "react-bootstrap";

export const AccordionOuterWrapper = ({children, defaultActiveKey}) => {
    if (window.innerWidth < 768) {
        return (
            <Accordion
                defaultActiveKey={"1"}
                flush
                className="action-tiles-group__accordion"
                data-testid="action-tiles-group-accordion-test"
            >
                {children}
            </Accordion>
        );
    } else {
        return <>{children}</>;
    }
};
