import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Tab, Nav } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { MainContext } from "../../contexts/context";
import Icon from "../../Icon/Icon";
import SLATable from "./SLATable.tsx";
import { useTranslation } from "react-i18next";

import "./SLAPage.scss";
import classnames from "classnames";
import mockedData from "./mockedData.js";

const SLAPage = () => {
  const [activeTab, setActiveTab] = useState("business");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const contextValue = useContext(MainContext);
  const { isTablet, isMobile, theme } = contextValue;
  const { t } = useTranslation("translation", { keyPrefix: "sla" });
  const [activeFilter, setActiveFilter] = useState('general');

  const tabs = [
    {
      key: "business",
      title: t("forBusinessOwners"),
    },
    {
      key: "individuals",
      title: t("forEmployees"),
    },
    {
      key: "providers",
      title:
        isTablet || isMobile ? t("serviceProviders") : t("forServiceProviders"),
    },
  ];

  const manageActiveTab = (tab: any) => {
    setActiveTab(tab);
    const newActiveTabIndex = tabs.findIndex((t) => t.key === tab);
    setActiveTabIndex(newActiveTabIndex);

    if (tab === "business") {
        setSearchParams({});
    } else {
        setSearchParams({ tab });
        setActiveFilter('general');
    }
    setTimeout(() => {
        const activeNav = document.querySelector('.nav-item--active');
        if (activeNav) {
            activeNav.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }, 0);
};

  const isRtl = theme === "arabic";
  const [showPrevIcon, setShowPrevIcon] = useState(false);
  const [showNextIcon, setShowNextIcon] = useState(true);
  
  const handleScroll = () => {
    const tabsList = document.querySelector('.sla-page-tabs__tabs-list');
    if (!tabsList) return;
  
    let isAtStart, isAtEnd;

    if (isRtl) {
      const scrollOffset = Math.max(tabsList.scrollWidth - tabsList.clientWidth, 0);
      isAtStart = tabsList.scrollLeft === scrollOffset || tabsList.scrollLeft >= 0;
      isAtEnd = tabsList.scrollLeft <= -(scrollOffset/2);
      
    } else {
      isAtStart = tabsList.scrollLeft === 0;
      isAtEnd = tabsList.scrollWidth - tabsList.scrollLeft + 0.5 === tabsList.clientWidth;
    }

    setShowPrevIcon(!isAtStart);
    setShowNextIcon(!isAtEnd);
  };
  useEffect(() => {
    const tabsList = document.querySelector('.sla-page-tabs__tabs-list');
    if (tabsList) {
      tabsList.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  
    return () => tabsList?.removeEventListener('scroll', handleScroll);
  }, [theme]); 
    


const handleFilterChange = (filterType: string) => {
  setActiveFilter(filterType);
};
const parseTabPanes = (tab:any) => {
  switch (tab) {
    case "business":
      return <SLATable data={mockedData.business} generalTabData={mockedData.general} activeFilter={activeFilter} onFilterChange={handleFilterChange} activeTab={activeTab} />;
    case "individuals":
      return <SLATable data={mockedData.individuals} generalTabData={mockedData.general} activeFilter={activeFilter} onFilterChange={handleFilterChange} activeTab={activeTab} />;
    case "providers":
      return <SLATable data={mockedData.providers} generalTabData={mockedData.general} activeFilter={activeFilter} onFilterChange={handleFilterChange}  activeTab={activeTab}/>;
    default:
      return <SLATable />;
  }
};


  return (
    <div className="sla-page">
      <div className="sla-page__hero">
        <Container>
          <Breadcrumbs theme="light">
            <span>{t("headerTitle")}</span>
          </Breadcrumbs>
          <h2 className="sla-page__title">{t("headerTitle")}</h2>
        </Container>
      </div>
      <div className="sla-page__content">
        <Container>
          <Tab.Container
            id="sla-page-tabs"
            activeKey={activeTab}
            onSelect={(tab) => manageActiveTab(tab)}
          >
            <div
              className={`${isTablet ? "" : "container"} sla-page-tabs-list`}
            >
              <Nav className="sla-page-tabs__tabs-list">
                {tabs.map((tab, index) => {
                  return (
                    <Nav.Item
                      className={classnames(
                        activeTab === tab.key && "nav-item--active"
                      )}
                      key={index}
                    >
                      <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
              {activeTab !== "business" &&  showPrevIcon && (
                <button
                  className="sla-page-tabs__prev-button"
                  onClick={() => manageActiveTab(tabs[activeTabIndex - 1].key)}
                >
                  <Icon className="" width="40" name={theme === "english" ? "chevron-left" : "chevron-right"} />
                </button>
              )}
              {activeTab !== "providers" && showNextIcon && (
                <button
                  className="sla-page-tabs__next-button"
                  onClick={() =>
                    tabs[activeTabIndex + 1] &&
                    manageActiveTab(tabs[activeTabIndex + 1].key)
                  }
                >
                  <Icon className="" width="40" name={theme === "english" ? "chevron-right" : "chevron-left"} />
                </button>
              )}
            </div>
            <Tab.Content>
              {tabs.map((tab, index) => {
                return (
                  <Tab.Pane
                    key={index}
                    eventKey={tab.key}
                    title={tab.title}
                    className="sla-page-tab-pane"
                  >
                    {parseTabPanes(tab.key)}
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </div>
  );
};

export default SLAPage;
