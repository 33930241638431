import React from "react";
import Link from "../../Link/Link";
import ResultRow from "../ResultRow/ResultRow.tsx";
import { Loader } from "../../Loader/Loader.tsx";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { toNumber } from "lodash";

const ResultsDropdown = ({ query, loading, data, showMoreClick }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const leftResults = toNumber(data.pager?.total_items) - 5;

  if (query === "" && !sessionStorage.getItem("searchQuery")) {
    return;
  }
  
  return (
    <div className="results-dropdown">
      {(loading || !data.pager) && (
        <div className="certificate-validation-tool__loader">
          <Loader />
        </div>
      )}
      {data && data.search_results && (
        <div className="results-dropdown__header">
          {query.length === 0 ? (
            <span>{t("previousResults")}</span>
          ) : (
            <p>
              {t("weFound")} <span>{data && data.pager?.total_items}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("resultsFor", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />{" "}
              <span>“{query}”.</span> {t("tryTypingMore")}
            </p>
          )}
        </div>
      )}
      <div
        className={classnames(
          "results-dropdown__results",
          query.length === 0 && "results-dropdown__results--previous"
        )}
      >
        {data.pager?.total_items_categories?.services !== 0 && (
          <div
            className={classnames(
              "results-dropdown__results-block",
              data.search_results?.services?.length > 0 &&
                "results-dropdown__results-block--with-results"
            )}
          >
            <h6 className="results-dropdown__results-block-title">
              {t("services")}
              {` (${data.pager?.total_items_categories?.services})`}
            </h6>
            {data.search_results?.services?.map(
              (result: any, index: number) => {
                return <ResultRow type="service" key={index} {...result} />;
              }
            )}
          </div>
        )}
        {data.pager?.total_items_categories?.tools_and_calculators !== 0 && (
          <div
            className={classnames(
              "results-dropdown__results-block",
              data.search_results?.tools_and_calculators?.length > 0 &&
                "results-dropdown__results-block--with-results"
            )}
          >
            <h6 className="results-dropdown__results-block-title">
              {t("toolsAndCalculators")}
              {` (${data.pager?.total_items_categories?.tools_and_calculators})`}
            </h6>
            {data.search_results?.tools_and_calculators?.map(
              (result: any, index: number) => {
                return <ResultRow type="calculators" key={index} {...result} />;
              }
            )}
          </div>
        )}
        {data.pager?.total_items_categories?.knowledge_center !== 0 && (
          <div
            className={classnames(
              "results-dropdown__results-block",
              data.search_results?.knowledge_center?.length > 0 &&
                "results-dropdown__results-block--with-results"
            )}
          >
            <h6 className="results-dropdown__results-block-title">
              {t("kcMaterials")}
              {` (${data.pager?.total_items_categories?.knowledge_center})`}
            </h6>
            {data?.search_results?.knowledge_center?.map(
              (result: any, index: number) => {
                return (
                  <ResultRow
                    type="knowledge"
                    key={index}
                    {...result}
                    dateShowOpen={false}
                  />
                );
              }
            )}
          </div>
        )}
        {data &&
          (data.search_results?.knowledge_center ||
            data.search_results?.tools_and_calculators ||
            data.search_results?.services) &&
          data.pager &&
          toNumber(data.pager.total_items) > 5 && (
            <div className="results-dropdown__show-more">
              <button
                className="results-dropdown__show-more-button"
                onClick={showMoreClick}
              >
                {t("showMore")} {`(+${leftResults})`}
              </button>
            </div>
          )}
        {data &&
          !data.search_results?.knowledge_center &&
          !data.search_results?.tools_and_calculators &&
          !data.search_results?.services && (
            <div className="results-dropdown__no-results search-results__no-results">
              <h6>{data?.empty_page?.searchPageMessage?.title}</h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.empty_page?.searchPageMessage?.body,
                }}
              />
            </div>
          )}
      </div>
      <div className="results-dropdown__footer">
        {t("contactUs")}{" "}
        <Link to="/contact" label={t("contactUsLink")} underlined={true} />
      </div>
    </div>
  );
};

type Props = {
  query: string;
  loading: boolean;
  data: any;
  showMoreClick: any;
};

ResultsDropdown.defaultProps = {
  query: "",
  loading: false,
  data: {},
  showMoreClick: (e: any) => e,
};

export default ResultsDropdown;
