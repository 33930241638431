import React, { useContext } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import { mockedData } from "./mockedData";
import { useTranslation } from "react-i18next";
import { MainContext } from "../contexts/context";

const WorkPermitResult = ({data, onReturn}) => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation('translation', { keyPrefix: "wpCalc.result" });
  
  return (
    <div
      className="certificate-validation-tool__calc-result wp-calculator__calc-result"
      data-testid="wp-calc-result-test"
    >
      <button
        onClick={onReturn}
        className="certificate-validation-tool__return-btn certificate-validation-tool__return-btn--top"
        data-testid="wp-calc-result-return-test"
      >
        <Icon name="chevron-left" />
        {t('returnBtn')}
      </button>
      <ul className="certificate-validation-tool__result-list">
        <li className="certificate-validation-tool__result-item">
          <p className="certificate-validation-tool__validation-result">
            {t('title')}
          </p>
        </li>
        {mockedData.calcOutputFields
          .filter((item) => (item.id === 'businessOwner' && data.businessOwner === 0) ? false : true)
          .map((field, index) => {
            return (
              <li key={index} className="wp-calculator__output-row">
                <p className="wp-calculator__output-row-label">
                  {t(`fields.${field.id}`)}
                  {field.id === 'ExtraSaudisCount' && <span >{data.ExtraSaudisCount} * 800 * 12</span>}
                  {field.id === 'TwinSaudisCount' && <span >{data.TwinSaudisCount} * 700 * 12</span>}
                </p>
                <div className="wp-calculator__output-row-value">
                  {field.id === 'ExtraSaudisCount' && <p className="wp-calculator__output-row-formula">{data.ExtraSaudisCount} * 800 * 12</p>}
                  {field.id === 'TwinSaudisCount' && <p className="wp-calculator__output-row-formula">{data.TwinSaudisCount} * 700 * 12</p>}
                  {(field.id === 'ExtraSaudisCount' || field.id === 'TwinSaudisCount' || field.id === 'WorkPermitFee') && <p>
                    {theme === 'english' && <span>{t('currency')} </span>}
                    {
                      field.id === 'ExtraSaudisCount' ? data.ExtraSaudisCount * 800 * 12
                      : field.id === 'TwinSaudisCount' ? data.TwinSaudisCount * 700 * 12
                      : data[field.id]
                    }
                    {theme === 'arabic' && <span> {t('currency')}</span>}
                  </p>}
                  {(field.id !== 'ExtraSaudisCount' && field.id !== 'TwinSaudisCount' && field.id !== 'WorkPermitFee') && <p>{data[field.id]}</p>}
                </div>
              </li>
            )
          })
        }
        <div className="wp-calculator__total-result-row">
          <p>{t('totalAmount.label')}</p>
          <p className="wp-calculator__total-result">{data.TotalAmount} {t('totalAmount.value')}</p>
        </div>  
      </ul>
      <button
        onClick={onReturn}
        className="certificate-validation-tool__return-btn certificate-validation-tool__return-btn--bottom"
      >
        {t('returnBtn')}
      </button>
    </div>
  );
}

WorkPermitResult.propTypes = {
  data: PropTypes.shape({
    businessOwner: PropTypes.number, 
    ExtraSaudisCount: PropTypes.number, 
    TwinSaudisCount: PropTypes.number, 
    WorkPermitFee: PropTypes.number,
    TotalAmount: PropTypes.number, 
  }), 
  onReturn: PropTypes.func,
};

WorkPermitResult.defaultProps = {
  data: [],
  onReturn: () => {return}
}

export default WorkPermitResult;