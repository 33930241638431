import React, { useContext, useEffect, useState } from "react";
import {  Container } from "react-bootstrap";
import LaborEducationHero from "./LaborEducationHero";
import "./LaborEducation.scss";
import { MainContext } from "../../contexts/context";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import LaborEducationMaterials from "./LaborEducationMaterials";
import NotFoundError from "../ErrorPages/NotFound/NotFound";
import { PageLoader } from "../../Loader/PageLoader";
import axios from "axios"; 

function LaborEducation() {
  const { t } = useTranslation("translation", {
    keyPrefix: "laborEducation",
  });
  const { theme } = useContext(MainContext);

  const [educationData, setEducationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULL_URL}/api/v1/labor-education?format=json${
          theme === "arabic" ? "&language=ar" : ""
        }`
      )
      .then((response) => {
        setEducationData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [theme]);

  if (loading) {
    return <PageLoader/>;
  }

  if (error) {
    return <NotFoundError />;
  }

  return (
    <Container
      fluid
      className={classNames({
        "labor-education p-0": true,
        "labor-education--rtl p-0": theme === "arabic",
      })}
    >
      <LaborEducationHero header={t("header")} content={t("content")} />

      {educationData && <LaborEducationMaterials data={educationData} />}
    </Container>
  );
}

export default LaborEducation;
