import classnames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";

const SLAFilters = ({ onFilterChange, activeTab }: any) => {
  
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation("translation", { keyPrefix: "sla" });

  const onShowPopup = (show: boolean) => {
    if (show) {
      document.body.classList.add("body-freeze");
      setShowPopup(true);
    } else {
      document.body.classList.remove("body-freeze");
      setShowPopup(false);
    }
  };

  type FilterType = {
    label: string;
    active: boolean;
  };

  const filtersArray = [
    {
      label: "general",
      active: true,
    },
    {
      label: "allServices",
      active: false,
    },
  ];

  const [filters, setFilters] = useState(filtersArray);
  const [tempFilters, setTempFilters] = useState([...filtersArray]);  

  const onFilterClick = (filter: FilterType, isMobile: boolean) => {    
    const newFilters = filters.map((f) => ({
      ...f,
      active: f.label === filter.label,
    }));

    setFilters(newFilters);
    if (isMobile) {
      setTempFilters(newFilters);
    }
    onFilterChange(filter);
  };
  useEffect(() => {
    onFilterClick(filtersArray[0], true);
  }
  , [activeTab]);

  const onApply = () => {
    setFilters([...tempFilters]);
    onShowPopup(false);
  };

  const paneRef = useRef<HTMLInputElement>(null);

  const addHeightVariable = () => {
    if (paneRef && paneRef.current) {
      paneRef.current.style.setProperty(
        "--window-inner-height",
        `${window.innerHeight - 61}px`
      );
    }
  };

  useEffect(() => {
    addHeightVariable();

    window.addEventListener("resize", addHeightVariable);
    return () => {
      window.removeEventListener("resize", addHeightVariable);
    };
  }, [paneRef.current, showPopup]);

  return (
    <div className="sla-filters">
      <div className="sla-filters__desktop-wrapper">
        {filters.map((filter, index) => (
          <button
            className={classnames(
              "sla-filters__filter",
              "sla-filters__filter--desktop",
              filter.active && "sla-filters__filter--active"
            )}
            onClick={() => onFilterClick(filter, false)}
            key={index}
          >
            {t(filter.label)}
          </button>
        ))}
      </div>
      <div className="sla-filters__mobile-wrapper">
        <button
          onClick={() => onShowPopup(true)}
          className="sla-filters__open-filters"
        >
          <Icon className="" name="filter" /> {t("filters")}
        </button>
        {showPopup && (
          <div className="sla-filters__mobile-popup" ref={paneRef}>
            <button
              className="sla-filters__back-button"
              onClick={() => onShowPopup(false)}
            >
              <Icon className="" name="chevron-left" /> {t("backBtn")}
            </button>
            <div className="sla-filters__clear-filters-row">
              <h6>{t("filters")}</h6>
              {/* <button
                className="sla-filters__clear-filters"
                onClick={() => onFilterClick(filters[0], true)}
              >
                {t("clear")}
              </button> */}
            </div>
            {filters.map((filter, index) => (
              <fieldset key={index}>
                <input
                  id={filter.label}
                  type="radio"
                  name="sla-filter"
                  checked={filter.active}
                  onChange={(e) => {
                    onFilterClick(filter, true);
                  }}
                />
                <label htmlFor={filter.label}>{t(filter.label)}</label>
              </fieldset>
            ))}
            <div className="sla-filters__apply-filters-btn-wrapper">
              <button
                className="sla-filters__apply-filters-btn"
                onClick={onApply}
              >
                {t("applyFilters")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SLAFilters;
