import React, { useState, useEffect, Fragment} from "react";
import classnames from "classnames";
import Icon from "../Icon/Icon";
import Link from "../Link/Link";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@takamol/qiwa-design-system/components";
import PropTypes from 'prop-types';

import "./Breadcrumbs.scss";

const shortenText = (text, level, totalLevels) => {
  let maxLength;
  if (totalLevels === 1) {
    return text;
  } 
  else if (totalLevels === 2) {
    maxLength = level === 0 ? 28 : 23;
  } 
  else if (totalLevels === 3) {
    maxLength = 14;
  } 
  else if (totalLevels >= 4) {
    maxLength = level === 0 ? 0 : 12;
  }
  return text?.length > maxLength ? `${text?.substring(0, maxLength)}…` : text;
};


const Breadcrumbs = ({ children, theme, Breadcrumbs }) => {
  const navigate = useNavigate();
  const elements = React.Children.toArray(children);
  const [isMobile, setIsMobile] = useState(false);
  const storedLanguage = localStorage.getItem("i18nextLng");

  const { t } = useTranslation("translation", {
    keyPrefix: "breadcrumbs",
  });
  const breadcrumbLink = Breadcrumbs[0]?.link;
  const breadcrumbFinder = (breadcrumb) => {
    if (breadcrumb === "For Business Owners") {
      return `/?tab=business`;
    } else if (breadcrumbLink === "For Employees") {
      return `/?tab=individuals`;
    } else if (breadcrumbLink === "For Service Providers") {
      return `/?tab=providers`;
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, [window.innerWidth]);

  return (
    <>
      {isMobile && (
        <ol className={classnames("breadcrumbs-kc", "breadcrumbs-kc--" + theme)} data-testid="breadcrumbs-test">
          <li className="breadcrumbs-kc__link breadcrumbs-kc__link--back-link">
          <button onClick={navigateBack}  className="breadcrumbs-kc__back-button" >
              <Icon name={storedLanguage === 'en' ? "chevron-left" : "chevron-right"} />
              {t("goBack")}
            </button>
          </li>
        </ol>
      )}
      {!isMobile && (
        <ol className="breadcrumbs-kc" data-testid="breadcrumbs-test">
          {elements.length > 0 ? (
            <>
              <li className={`breadcrumbs-kc__link ${'breadcrumbs-kc__link--' + theme}`}>
                <Link to="/"> {t("qiwa")}</Link>
              </li>
              <li className={"breadcrumbs-kc__link breadcrumbs-kc__active--" + theme}>
                <Icon name={storedLanguage === 'en' ? "chevron-right" : "chevron-left"} height="16" width="16" />
              </li>
            </>
          ) : (
            <li className={`breadcrumbs-kc__back-link`}>
              <Link onClick={navigateBack}>
                <Icon name={storedLanguage === 'en' ? "chevron-left" : "chevron-right"} />
                {t("goBack")}
              </Link>
            </li>
          )}
           {elements.map((element, index) => {
            const isLastElement = index === elements.length - 1;
            const totalLevels = elements.length;
            const text = element.props.children || element.props.label
            const shortenedText = shortenText(text, index, totalLevels);
            return (
              <Fragment key={index}>
                <li
                  className={classnames(
                    "breadcrumbs-kc__link",
                    {"breadcrumbs-kc__active": isLastElement},
                    "breadcrumbs-kc__link--" + theme,
                  )}
                >
                  <Tooltip description={text} trigger="hover" data-testid="tooltip">
                    {isLastElement ? (
                      <span>{shortenedText}</span>
                    ) : (
                      <Link to={element.props.to || element.props.href}>
                        {shortenedText}
                      </Link>
                    )}
                  </Tooltip>
                </li>
                {!isLastElement && (
                  <Icon name={storedLanguage === 'en' ? "chevron-right" : "chevron-left"} height="16" width="16" />
                )}
              </Fragment>
            );
          })}
        </ol>
      )}
    </>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node, 
  theme: PropTypes.oneOf(['dark', 'light']),
  Breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string, 
  })) 
};

Breadcrumbs.defaultProps = {
  theme: "dark" | "light",
  Breadcrumbs: [],
};

export default Breadcrumbs;
