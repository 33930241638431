import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import "./Tooltip.scss";

const Tooltip = ({ children, tooltipText }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !(
          tooltipRef.current?.contains(target) ||
          triggerRef.current?.contains(target)
        )
      ) {
        setVisible(false);
      }
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, []);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode === 27) {
        setVisible(false);
      }
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, []);
  return (
    <div className="tooltip-container">
      <button
        className="tooltip-trigger"
        data-testid="tooltip-trigger"
        aria-describedby="tooltip-content"
        onMouseOver={() => setVisible(true)}
        onFocus={() => setVisible(true)}
        ref={triggerRef}
      >
        {children}
      </button>
      {visible && (
        <div
          id="tooltip-content"
          className="tooltip-text"
          ref={tooltipRef}
          data-testid="tooltip" // Add this line
        >
          <div className="header">{tooltipText.header}</div>
          <div className="text">{tooltipText.text}</div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node, // children can be any renderable React nodes
  tooltipText: PropTypes.shape({
    header: PropTypes.string, // header is a required string
    text: PropTypes.string // text is a required string
  }) // tooltipText must be provided with both header and text
};

export default Tooltip;
