export const checkLanguageInUrl = (url) => {
  const storedLanguage = ['en', 'ar'].includes(localStorage.getItem("i18nextLng")) 
    ? localStorage.getItem("i18nextLng") 
    : 'ar';

  const hasEnPrefix = url.startsWith("/en/") || url === "/en";
  const hasArPrefix = url.startsWith("/ar/") || url === "/ar";

  if (hasEnPrefix) {
    return "en";
  } else if (hasArPrefix) {
    return "ar";
  } else {
    return storedLanguage;
  }
};
