import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Button } from "@takamol/qiwa-design-system/components";
import classnames from "classnames";
import "./HiringNonSaudiEmployees.scss";
import isEmpty from "lodash/isEmpty";
import Icon from "../../../Icon/Icon";
import slice from "lodash/slice";
import { MainContext } from "../../../contexts/context";
import Link from "../../../Link/Link";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

function HiringComponent({ data, index }) {
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const { theme } = React.useContext(MainContext);
  if (isEmpty(data)) {
    return null;
  }
  const renderLinks = (links, sliceSize) => {
    return slice(links, 0, sliceSize).map((link, linkIndex) => (
      <React.Fragment key={linkIndex}>
        <p className="mb-2">
          <Link to={`${link?.url}`} label={link?.title} />
        </p>
        {linkIndex < links.length - 1 && (
          <hr className="hiring-non-saudi-employees__line" />
        )}
      </React.Fragment>
    ));
  };

  const renderSection = (section, index) => (
    <Col lg={4} key={index} className="hiring-non-saudi-employees__card">
      <Card.Body className="hiring-non-saudi-employees__body-with-array">
        <button
            className="hiring-non-saudi-employees__service-header-container"
            onClick={() =>
              (window.location.href = `/${localStorage.getItem("i18nextLng")}${
                section?.articleLink?.url
              }`)
            }
            role="button"
            tabIndex={0}
          >
          <Link
            to={`${section?.articleLink?.url}`}
            label={section?.articleLink?.title}
          />
          <Icon
            name="arrow-right"
            className="hiring-non-saudi-employees__service-header-container__icon"
          />
        </button>
        <div>
          {isEmpty(section?.linksToArticles) && <p className="hiring-non-saudi-employees__empty-service-answer">{t("workingOnOfferYou")}</p>}
          {renderLinks(
            section?.linksToArticles,
            section?.linksToArticles?.length
          )}
          {section?.showMoreLink && !isEmpty(section?.showMoreLink?.title) && (
            <div className="hiring-non-saudi-employees__show-more">
              <Link
                to={section.showMoreLink.url}
                label={section.showMoreLink.title}
                className="hiring-non-saudi-employees__show-more"
              />
            </div>
          )}
        </div>
      </Card.Body>
    </Col>
  );

  return (
    <Container fluid className="hiring-non-saudi-employees">
      <Card className={"hiring-non-saudi-employees__body-card"}>
        <Card.Body>
          <Row
            className={classnames(
              "hiring-non-saudi-employees__header-container",
              data?.options?.variant &&
                `hiring-non-saudi-employees__header-container--${data?.options?.variant}`
            )}
          >
            <div
              className={classnames({
                "hiring-non-saudi-employees__header": true,
                "hiring-non-saudi-employees__header--rtl": theme !== "english",
              })}
            >
              {data?.articleLink?.title}
            </div>
            {data?.articleLink?.url !== "#" && (
              <Button
                iconComponent={() => <Icon name="arrow-right" />}
                iconPlacement="end"
                variant="business_ghost"
                className="hiring-non-saudi-employees__button"
                onClick={() =>
                  (window.location.href = `/${localStorage.getItem(
                    "i18nextLng"
                  )}${data?.articleLink?.url}`)
                }
              >
                {t("readJourneyArticle")}
              </Button>
            )}
          </Row>
          <Row className="hiring-non-saudi-employees__services">
            {data.serviceCards.map((section, index) =>
              renderSection(section, index)
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}


HiringComponent.propTypes = {
  data: PropTypes.shape({
    articleLink: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }),
    options: PropTypes.shape({
      variant: PropTypes.string
    }),
    serviceCards: PropTypes.arrayOf(PropTypes.shape({
      articleLink: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string
      }),
      linksToArticles: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string
      })),
      showMoreLink: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string
      })
    }))
  }),
  index: PropTypes.number // Assuming index is used somewhere in your component logic
};

export default HiringComponent;
