import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./LaborEducationHero.scss";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Link from "../../Link/Link";
import { useTranslation } from "react-i18next";

interface AppProps {
  header: string;
  content: string;
}

const LaborEducationHero: React.FC<AppProps> = ({ header, content }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "laborEducation",
  });

  return (
    <div className="labor-education-hero">
      <Container className="labor-education-hero-container">
        <Breadcrumbs theme="light">
          <span>{header}</span>
        </Breadcrumbs>
        <Row>
          <p className="labor-education-hero__heading">{header}</p>
          <Col lg={7}>
            <p className="labor-education-hero__content">{content}</p>
            <Link to="/labor-education/about-labor-education" className="labor-education-hero__learn-more">
              {t("learnMore")}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LaborEducationHero;
