import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';

import {
  RadioTile
} from "@takamol/qiwa-design-system/components";

const WrapperRadioGroup = ({
  name,
  control,
  options,
  onRadioChange,
  selectedValue,
  requiredMessage,
  errors
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref = null, ...props } }) =>
        options.map(
          (option, index) => (
            <RadioTile
              {...props}
              key={`radio-${index}`}
              id={"wp-calc-business-owner-option-" + option}
              label={option}
              value={option}
              maxInputWidth={'100%'}
              width={'100%'}
              isChecked={selectedValue === option}
              onChange={onRadioChange}
              isError={errors && errors.businessOwner}
              errorMessage={requiredMessage}
              data-testid={"wp-calc-business-owner-option-" + option}
            />
          )
        )
      }
      rules={{ required: requiredMessage }}
    />
  );
};

WrapperRadioGroup.propTypes = {
  name: PropTypes.string, 
  control: PropTypes.object, 
  options: PropTypes.arrayOf(PropTypes.string), 
  onRadioChange: PropTypes.func, 
  selectedValue: PropTypes.string,
  requiredMessage: PropTypes.string, 
  errors: PropTypes.object
};

export default WrapperRadioGroup;
