import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Link from "../Link/Link";
import NavCard from "../NavCard/NavCard";

import './NavCardsGroup.scss';

const NavCards = ({cardsData}) => {
  return (
    <div className="nav-cards-group">
      <Container>
        <Row sm='1'>
          <Col lg='7'>
            <h3 className="nav-cards-group__title">{cardsData.title}</h3>
          </Col>
          <Col lg='7'>
            <p className="nav-cards-group__description">{cardsData.description}</p>
          </Col>
        </Row>
        <Nav className="row row-cols-xs-1 row-cols-xxs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 nav-cards-group__cards-row">
          {cardsData.cards.map((card, index) => {
            return (
              <Nav.Item as="div" className="col" key={index}>
                <Link to={card.url} textLink={false}>
                  <NavCard variant={card.variant} icon={card.icon} title={card.title} description={card.description} />
                </Link>
              </Nav.Item>
            )
          })}
        </Nav>
      </Container>
    </div>
  )
};

NavCards.propTypes = {
  cardsData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string, 
        variant: PropTypes.string, 
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string, 
      })
    ),
  }),
};

NavCards.defaultProps = {
  cardsData: {
    title: '',
    description: '',
    cards: []
  }
}

export default NavCards;