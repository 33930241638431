import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";
import classNames from "classnames";

import "./InfoBox.scss";

const InfoBox = ({ children, className }) => {
  return (
    <div className={classNames("info-box", className)}>
      <Icon
        name="info"
        height="34"
        width="34"
        className="info-box__icon"
      />
      {children}
    </div>
  );
};

InfoBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default InfoBox;
