import React from "react";
import "./TimeDisplay.scss";
import isEmpty from "lodash/isEmpty";
import { MainContext } from "../../../../../contexts/context";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const TimeDisplay = ({ createdTime, updatedTime }) => {
  const { theme } = React.useContext(MainContext);
  const { t } = useTranslation("translation", { keyPrefix: "article" });

  const createdDate = new Date(createdTime * 1000);
  const updatedDate = new Date(updatedTime * 1000);

  const isArabic = theme === "arabic";

  const formatDate = (date) => {
    const locale = isArabic ? "ar-EG" : "en-US";
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString(locale, options);
  };

  return (
    <div className={classnames({
      "time-display": true,
      "time-display--rtl": isArabic
    })}>
      <div className="time-display__line">
        <div className="time-display__info">
          {t("lastUpdated")}:
        </div>
        {formatDate(updatedDate)}
      </div>
    </div>
  );
};

export default TimeDisplay;
