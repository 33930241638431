import React, { useEffect, useContext, useState } from "react";
import { Container } from "react-bootstrap";
import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import Banner from "../../../../Banner/Banner";
import Link from "../../../../Link/Link";
import useDynamicTranslation from "../../../../../hooks/useDynamicTranslate";
import { MainContext } from "../../../../contexts/context";
import Feedback from "../../../../Feedback/Feedback";
import NitaqatCalculator from "../../../../NitaqatCalculator/NitaqatCalculator";
import { useTranslation } from "react-i18next";
import useNavigationHistory from "../../../../../hooks/useNavigationHistory";
import { extractSpecialUrlsFromHistory } from "../../../../../utils/extractSpecialUrlsFromHistory";

import "./NitaqatCalcPage.scss";

const NitaqatCalcPage = () => {
  const [showNote, setShowNote] = useState(false);
  const { setHeaderData, headerData, isMobile, isTablet } = useContext(MainContext);
  const breadCrumbTranslations = useDynamicTranslation("breadcrumbs");
  const { t } = useTranslation("translation", { keyPrefix: "nitaqatCalc" });
  const navigationHistory = useNavigationHistory();
  const specialUrls = extractSpecialUrlsFromHistory(navigationHistory); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setHeaderData({ ...headerData, variant: "light" });

    return () => {
      setHeaderData({ ...headerData, variant: "dark" });
    };
  }, [setHeaderData]);

  return (
    <div className="nitaqat-calc-page">
      <Container data-testid="nitaqat-calc-page-test">
        <Breadcrumbs theme="dark">
        {(specialUrls?.toolsAndCalculators && (
            <Link
              to={specialUrls?.toolsAndCalculators}
              label={breadCrumbTranslations("tools")}
            />
          ))}
          {navigationHistory?.length > 0 && (
            <span>{navigationHistory[navigationHistory.length - 1]?.title }</span>
          )}
        </Breadcrumbs>
        <NitaqatCalculator displayNote={setShowNote}/>
      </Container>
      {showNote && <div className="nitaqat-calc-page__note">
        <Container>
          <div
            dangerouslySetInnerHTML={{
              __html: t((isMobile || isTablet ? "noteMobile" : "note"), {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Container>
      </div>}
      <Feedback />
      <Banner variant={"body"} />
    </div>
  );
};

export default NitaqatCalcPage;
