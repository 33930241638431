import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  RadioButton,
  RadioGroup,
  CheckboxGroup,
  Checkbox,
  Button,
  Modal,
  Box,
  Text,
} from "@takamol/qiwa-design-system/components";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/Icon";
import BeneficiarySelection from "./BeneficiarySelection";
import "./Filter.scss";

interface BeneficiaryOption {
  raw_value: string;
  values: {
    value: string;
    count: number;
  };
}

interface UserType {
  option: string;
  value: string;
  user_type_id: string;
}

interface FilterProps {
  userTypes: UserType[];
  selectedUserType: string;
  onFilterChange: (selectedType: string) => void;
  beneficiaries: BeneficiaryOption[];
  selectedBeneficiaries: string[]; // Array of selected beneficiaries
  onBeneficiaryChange: (selectedBeneficiaries: string[]) => void; // Update multiple selections
}

const Filter: React.FC<FilterProps> = ({
  userTypes,
  selectedUserType,
  onFilterChange,
  beneficiaries,
  selectedBeneficiaries,
  onBeneficiaryChange,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "laborEducation.filter",
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [tempSelectedUserType, setTempSelectedUserType] =
    useState(selectedUserType);
  const [tempSelectedBeneficiaries, setTempSelectedBeneficiaries] = useState<
    string[]
  >(selectedBeneficiaries);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenModal = () => {
    setTempSelectedUserType(selectedUserType);
    setTempSelectedBeneficiaries(selectedBeneficiaries);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleApplyFilters = () => {
    onFilterChange(tempSelectedUserType);
    onBeneficiaryChange(tempSelectedBeneficiaries); // Apply multiple selections
    setShowModal(false);
  };

  return (
    <>
      {isMobile ? (
        <>
          <Button
            className="filter__button"
            variant="business_ghost"
            onClick={handleOpenModal}
            data-testid="filter-button"
          >
            <>
              <Icon width="17" viewBox="3 0 17 24" name="filter" />
              <p className="filter__button__text">{t("filters")}</p>
            </>
          </Button>

          {showModal && (
            <Modal
              isOpened={showModal}
              handleClose={handleCloseModal}
              desktopWidth="standard"
              hasCloseButton={true}
            >
              <Modal.Body>
                <Box gap={24} withDividers>
                  <Text
                    variant={["heading-xxs", "heading-xs"]}
                    weight="semibold"
                  >
                    {t("filters")}
                  </Text>

                  <div className="filter__section">
                    <p className="filter__label">{t("userType")}</p>
                    <RadioGroup
                      name="userType"
                      value={tempSelectedUserType}
                      onChange={setTempSelectedUserType}
                      size={20}
                    >
                      {userTypes.map((type) => (
                        <RadioButton
                          id={type.value}
                          key={type.value}
                          value={type.user_type_id}
                          label={type.option}
                        />
                      ))}
                    </RadioGroup>
                  </div>

                  <BeneficiarySelection
                    beneficiaries={beneficiaries}
                    selectedBeneficiaries={tempSelectedBeneficiaries}
                    onChange={setTempSelectedBeneficiaries}
                    isDisabled={tempSelectedUserType === "business_owners"}
                  />

                  <div className="modal-footer">
                    <Button
                      onClick={handleApplyFilters}
                      className="apply-button"
                    >
                      {t("apply")}
                    </Button>
                  </div>
                </Box>
              </Modal.Body>
            </Modal>
          )}
        </>
      ) : (
        <div className="filter">
          <h5 className="filter__title">{t("filters")}</h5>

          <div className="filter__section">
            <p className="filter__label">{t("userType")}</p>
            <RadioGroup
              name="userType"
              value={selectedUserType}
              onChange={onFilterChange}
              size={20}
            >
              {userTypes.map((type) => (
                <RadioButton
                  id={type.value}
                  key={type.value}
                  value={type.user_type_id}
                  label={type.option}
                />
              ))}
            </RadioGroup>
          </div>

          <BeneficiarySelection
            beneficiaries={beneficiaries}
            selectedBeneficiaries={selectedBeneficiaries}
            onChange={onBeneficiaryChange}
            isDisabled={selectedUserType === "business_owners"}
          />
        </div>
      )}
    </>
  );
};

export default Filter;
