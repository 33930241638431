export const mockedData = {
  title: "Work Permit Calculator",
  description: "description",
  nonSaudisInputs: [
    {
      id: 'privateExpatriate',
      title: 'A private expatriate',
      description: "Citizen's husband - citizen's wife - son/daughter of a citizen",
    },
    {
      id: 'exemptFromDeportation',
      title: 'Nationalities exempt from deportation',
      description: 'Palestinians with Egyptian or Turkestan documents, Balochi residents',
    },
    {
      id: 'displacedTribes',
      title: 'Displaced tribes',
      description: 'Holders of the five-year card (Saudi passport holders who are not Saudis)',
    },
    {
      id: 'gulfCooperationCouncil',
      title: 'Citizens of the Gulf Cooperation Council',
      description: 'Citizens of Kuwait, the UAE, Qatar, Bahrain, and Oman.',
    },
    {
      id: 'recruitmentCompanies',
      title: 'Workers in recruitment companies',
      description: 'Domestic/home labor rental',
    },
    {
      id: 'farmers',
      title: 'Workers in farmer, fishermen and shepherds establishments',
      description: 'with a maximum of 6 expatriate workers on activity 1-5',
    },
  ],
  calcOutputFields: [
    {
      id: 'SaudiCount',
      label: 'Saudis',
    },
    {
      id: 'NonSaudiCount',
      label: 'Non-Saudis',
    },
    {
      id: 'businessOwner',
      label: 'Business owner',
    },
    {
      id: 'totalEmployee',
      label: 'Total number of employees',
    },
    {
      id: 'ExtraSaudisCount',
      label: 'Financial compensation for over Saudi count',
    },
    {
      id: 'TwinSaudisCount',
      label: 'Financial compensation for equal Saudi count',
    },
    {
      id: 'ExemptByType',
      label: 'Exempt by type',
    },
    {
      id: 'ExemptByDiscount',
      label: 'Exempt by type',
    },
    {
      id: 'WorkPermitFee',
      label: '+ Work Permit Fee',
    },

  ]
};
