// usePersonaTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePersonaTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname + location.search;
    const previousUrl = sessionStorage.getItem('currentUrl') || '';

    // Update current URL in sessionStorage
    sessionStorage.setItem('currentUrl', currentUrl);

    // Extract persona from the previous URL
    let previousPersona = null;
    if (previousUrl) {
      const origin = window.location.origin;
      const previousUrlObj = new URL(previousUrl, origin);
      const previousParams = new URLSearchParams(previousUrlObj.search);
      previousPersona = previousParams.get('persona');

      if (previousPersona) {
        if (previousPersona === 'businessowners') {
          sessionStorage.setItem('previousPersona', 'business_owners');
        } else {
          sessionStorage.setItem('previousPersona', previousPersona);
        }
      } else {
        // If previousPersona is null, set it to 'all'
        sessionStorage.setItem('previousPersona', 'all');
      }
    } else {
      // If previousUrl is empty, set previousPersona to 'all'
      sessionStorage.setItem('previousPersona', 'all');
    }

    // Extract persona from the current URL
    const currentParams = new URLSearchParams(location.search);
    let currentPersona = currentParams.get('persona');

    if (currentPersona) {
      // Store the current persona
      sessionStorage.setItem('currentPersona', currentPersona);
    } else {
      // If currentPersona is null, set it to 'all'
      sessionStorage.setItem('currentPersona', 'all');
    }

  }, [location]);
};

export default usePersonaTracker;
