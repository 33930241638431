import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./VideoWidget.scss";
import Icon from "../../../../Icon/Icon";
import { Link } from "react-router-dom";
import { MainContext } from "../../../../contexts/context";
import PropTypes from 'prop-types';

const VideoWidget = ({
  videoTitle,
  videoContent,
  externalVideoLink,
  imgUrl,
}) => {
  const { theme } = useContext(MainContext);
  
  const isRTL = theme !== 'english'; 

  const openVideo = () => {
    window.open(externalVideoLink);
  };
  
  return (
    <Card className={`video-widget ${isRTL ? 'rtl' : ''}`}>
      <Card.Img variant="top" src={imgUrl} className="video-widget__image" />
      <Card.Body className={`video-widget__body`}>
        <div className="video-widget__content">
          <button onClick={openVideo} className="video-widget__button">
            <Icon
              name="play"
              viewBox="0 0 32 32"
              className="video-widget__icon"
            />
          </button>
          <div className="video-widget__text">
            <Card.Title className="video-widget__title">
              {videoTitle}
            </Card.Title>
            <Card.Text className="video-widget__description">
              {videoContent}
            </Card.Text>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

VideoWidget.propTypes = {
  videoTitle: PropTypes.string, 
  videoContent: PropTypes.string, 
  externalVideoLink: PropTypes.string, 
  imgUrl: PropTypes.string 
};

export default VideoWidget;
