export const mockedData = {
  title: "title",
  descriptionFirstPart: "descriptionFirstPart",
  descriptionSecondPart: `descriptionSecondPart`,
  endOfServiceResultRows: [
    "typeOfContract",
    "endOfContractReason",
    "salary",
    "contractStartDate",
    "contractEndDate",
    "calculatedReward",
  ],
  ContractEndReason: [
    {
      "id": "reason-1",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "1",
      "EnDescription": "Contract period expired, or agreement between both parties to end contract",
      "ArDescription": "\u0627\u0646\u062a\u0647\u0627\u0621 \u0645\u062f\u0629 \u0627\u0644\u0639\u0642\u062f\u060c \u0623\u0648 \u0628\u0627\u062a\u0641\u0627\u0642 \u0627\u0644\u0637\u0631\u0641\u064a\u0646 \u0639\u0644\u0649 \u0625\u0646\u0647\u0627\u0621 \u0627\u0644\u0639\u0642\u062f"
    },
    {
      "id": "reason-2",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "2",
      "EnDescription": "Termination of contract by employer",
      "ArDescription": "\u0641\u0633\u062e \u0627\u0644\u0639\u0642\u062f \u0645\u0646 \u0642\u0628\u0644 \u0635\u0627\u062d\u0628 \u0627\u0644\u0639\u0645\u0644"
    },
    {
      "id": "reason-3",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "3",
      "EnDescription": "Termination of the contract by the employer for one of the cases stated in Article (80)",
      "ArDescription": "(\u0641\u0633\u062e \u0627\u0644\u0639\u0642\u062f \u0645\u0646 \u0642\u0628\u0644 \u0635\u0627\u062d\u0628 \u0627\u0644\u0639\u0645\u0644 \u0644\u0623\u062d\u062f \u0627\u0644\u062d\u0627\u0644\u0627\u062a \u0627\u0644\u0648\u0627\u0631\u062f\u0629 \u0641\u064a \u0627\u0644\u0645\u0627\u062f\u0629 (80"
    },
    {
      "id": "reason-4",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "4",
      "EnDescription": "Employee leaves work due to force majeure",
      "ArDescription": "\u062a\u0631\u0643 \u0627\u0644\u0645\u0648\u0638\u0641 \u0627\u0644\u0639\u0645\u0644 \u0646\u062a\u064a\u062c\u0629 \u0644\u0642\u0648\u0629 \u0642\u0627\u0647\u0631\u0629"
    },
    {
      "id": "reason-5",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "5",
      "EnDescription": "Termination of employment contract within six months of marriage or three months of birth",
      "ArDescription": "\u0625\u0646\u0647\u0627\u0621 \u0627\u0644\u0645\u0648\u0638\u0641\u0629 \u0644\u0639\u0642\u062f \u0627\u0644\u0639\u0645\u0644 \u062e\u0644\u0627\u0644 \u0633\u062a\u0629 \u0623\u0634\u0647\u0631 \u0645\u0646 \u0639\u0642\u062f \u0627\u0644\u0632\u0648\u0627\u062c \u0623\u0648 \u062e\u0644\u0627\u0644 \u062b\u0644\u0627\u062b\u0629 \u0623\u0634\u0647\u0631 \u0645\u0646 \u0627\u0644\u0648\u0636\u0639"
    },
    {
      "id": "reason-6",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "6",
      "EnDescription": "Employee leaves work due to one of the cases stated in Article (81)",
      "ArDescription": "(\u062a\u0631\u0643 \u0627\u0644\u0645\u0648\u0638\u0641 \u0627\u0644\u0639\u0645\u0644 \u0644\u0623\u062d\u062f \u0627\u0644\u062d\u0627\u0644\u0627\u062a \u0627\u0644\u0648\u0627\u0631\u062f\u0629 \u0641\u064a \u0627\u0644\u0645\u0627\u062f\u0629 (81"
    },
    {
      "id": "reason-7",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "1",
      "EnDescription": "Agreement between both parties to end contract",
      "ArDescription": "\u0627\u062a\u0641\u0627\u0642 \u0627\u0644\u0637\u0631\u0641\u064a\u0646 \u0639\u0644\u0649 \u0625\u0646\u0647\u0627\u0621 \u0627\u0644\u0639\u0642\u062f"
    },
    {
      "id": "reason-8",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "2",
      "EnDescription": "Termination of contract by the employer",
      "ArDescription": "\u0641\u0633\u062e \u0627\u0644\u0639\u0642\u062f \u0645\u0646 \u0642\u0628\u0644 \u0635\u0627\u062d\u0628 \u0627\u0644\u0639\u0645\u0644"
    },
    {
      "id": "reason-9",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "3",
      "EnDescription": "Termination of the contract by the employer for one of the cases stated in Article (80)",
      "ArDescription": "(\u0641\u0633\u062e \u0627\u0644\u0639\u0642\u062f \u0645\u0646 \u0642\u0628\u0644 \u0635\u0627\u062d\u0628 \u0627\u0644\u0639\u0645\u0644 \u0644\u0623\u062d\u062f \u0627\u0644\u062d\u0627\u0644\u0627\u062a \u0627\u0644\u0648\u0627\u0631\u062f\u0629 \u0641\u064a \u0627\u0644\u0645\u0627\u062f\u0629 (80"
    },
    {
      "id": "reason-10",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "4",
      "EnDescription": "Employee leaves work due to force majeure",
      "ArDescription": "\u062a\u0631\u0643 \u0627\u0644\u0645\u0648\u0638\u0641 \u0627\u0644\u0639\u0645\u0644 \u0646\u062a\u064a\u062c\u0629 \u0644\u0642\u0648\u0629 \u0642\u0627\u0647\u0631\u0629"
    },
    {
      "id": "reason-11",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "5",
      "EnDescription": "Termination of employment contract within six months of marriage or three months of birth",
      "ArDescription": "\u0625\u0646\u0647\u0627\u0621 \u0627\u0644\u0645\u0648\u0638\u0641\u0629 \u0644\u0639\u0642\u062f \u0627\u0644\u0639\u0645\u0644 \u062e\u0644\u0627\u0644 \u0633\u062a\u0629 \u0623\u0634\u0647\u0631 \u0645\u0646 \u0639\u0642\u062f \u0627\u0644\u0632\u0648\u0627\u062c \u0623\u0648 \u062e\u0644\u0627\u0644 \u062b\u0644\u0627\u062b\u0629 \u0623\u0634\u0647\u0631 \u0645\u0646 \u0627\u0644\u0648\u0636\u0639"
    },
    {
      "id": "reason-12",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "6",
      "EnDescription": "Employee leaves work due to one of the cases stated in Article (81)",
      "ArDescription": "(\u062a\u0631\u0643 \u0627\u0644\u0645\u0648\u0638\u0641 \u0627\u0644\u0639\u0645\u0644 \u0644\u0623\u062d\u062f \u0627\u0644\u062d\u0627\u0644\u0627\u062a \u0627\u0644\u0648\u0627\u0631\u062f\u0629 \u0641\u064a \u0627\u0644\u0645\u0627\u062f\u0629 (81"
    },
    {
      "id": "reason-13",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "8",
      "EnDescription": "Employee Resignation",
      "ArDescription": "\u0627\u0633\u062a\u0642\u0627\u0644\u0629 \u0627\u0644\u0645\u0648\u0638\u0641"
    },
    {
      "id": "reason-14",
      "ContractTypeCode": "1",
      "ContractEndReasonCode": "7",
      "EnDescription": "Termination of contract by employee or employee leaves work due to reasons not stated in Article (81)",
      "ArDescription": "(\u0641\u0633\u062e \u0627\u0644\u0639\u0642\u062f \u0645\u0646 \u0642\u0628\u0644 \u0627\u0644\u0645\u0648\u0638\u0641 \u0623\u0648 \u062a\u0631\u0643 \u0627\u0644\u0645\u0648\u0638\u0641 \u0627\u0644\u0639\u0645\u0644 \u0644\u063a\u064a\u0631 \u0627\u0644\u062d\u0627\u0644\u0627\u062a \u0627\u0644\u0648\u0627\u0631\u062f\u0629 \u0641\u064a \u0627\u0644\u0645\u0627\u062f\u0629 (81"
    },
    {
      "id": "reason-15",
      "ContractTypeCode": "2",
      "ContractEndReasonCode": "7",
      "EnDescription": "Employee leaves work without submitting his/her resignation, other than the cases stated in Article (81)",
      "ArDescription": "(\u062a\u0631\u0643 \u0627\u0644\u0645\u0648\u0638\u0641 \u0627\u0644\u0639\u0645\u0644 \u062f\u0648\u0646 \u062a\u0642\u062f\u064a\u0645 \u0627\u0633\u062a\u0642\u0627\u0644\u0629 \u0644\u063a\u064a\u0631 \u0627\u0644\u062d\u0627\u0644\u0627\u062a \u0627\u0644\u0648\u0627\u0631\u062f\u0629 \u0641\u064a \u0627\u0644\u0645\u0627\u062f\u0629 (81"
    }
  ],
  endOfServiceCalcTranslations: {
    typeOfContract: {
      english: "Type of contract",
      arabic: "نوع العقد",
    },
    chooseReason: {
      english: "Choose a reason",
      arabic: "اختر سبب",
    },
    limitedPeriod: {
      english: "Limited period",
      arabic: "محدد المدة",
    },
    unlimitedPeriod: {
      english: "Unlimited period",
      arabic: "غير محدد المدة",
    },
    endOfContactReason: {
      english: "End of contract reason",
      arabic: "سبب انتهاء العقد",
    },
    salary: {
      english: "Salary",
      arabic: "الراتب",
    },
    contractStartDate: {
      english: "Contract start date",
      arabic: "تاريخ بداية العقد",
    },
    contractEndDate: {
      english: "Contract end date",
      arabic: "تاريخ انتهاء العقد",
    },
  },
  langRequired: {
    english: "*Required",
    arabic: "مطلوب*",
  },
};
