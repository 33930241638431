import React, {useContext} from "react";
import { Container, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Icon from "../../Icon/Icon";
import Link from "../../Link/Link";
import { MainContext } from "../../contexts/context";
import PropTypes from 'prop-types';

const SecondaryMobilePane = ({onReturn, closeMenu, showPane}) => {
  const { theme, setTheme } = useContext(MainContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation("translation", {
    keyPrefix: "breadcrumbs",
  });
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  let panes = [];
  const languagesPane = {
    key: 'Select your language',
    secondLevelMenu: [
      {
        "title": "(AR) العربية",
        "flag": 'arabic-flag',
        "url": "#",
        "id": 'arabic',
      },
      {
        "title": "English (UK)",
        "flag": 'britain-flag',
        "url": "#",
        "id": 'english',
      },
    ]
  }
  panes.push(languagesPane);
  
  const handleLanguageSwitch = (e, lang) => {
    e.preventDefault();
    setTheme(lang);
    document.body.dir = lang === 'english' ? '' : 'rtl';
    document.body.lang = lang === 'english' ? 'en' : 'ar';
    lang === 'english' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');

    const urlParts = pathname.split('/');
    urlParts[1] = lang === 'english' ? 'en' : 'ar';
    const resultUrl = urlParts.join('/') + search;
    navigate(resultUrl, { replace: true });
  }

  return (
    <div className={`secondary-mobile-pane ${showPane ? 'secondary-mobile-pane--open' : ''}`} data-testid='secondary-mobile-pane-test'>
      <Container>
        <button className="secondary-mobile-pane__back-btn" onClick={() => onReturn(false)}>
          <Icon name={theme === "english" ? "chevron-left" : "chevron-right"} />
          {t("goBack")}
        </button>
        <Tab.Content>
            {panes.map((pane, index) => {
              return (
                <Tab.Pane key={index} eventKey={pane.key}>
                  {pane.key === 'Select your language' && <h4 className="secondary-mobile-pane__title">{t("selectYourLang")}</h4>}
                  <ul>
                    {pane.secondLevelMenu.map((link, index) => {
                      const isLanguage = pane.key === 'Select your language';
                      const isSelectedLanguage = isLanguage && theme === link.id;
                      return (
                        <li
                          key={index}
                          className={
                            isLanguage ?
                            `secondary-mobile-pane__language-link ${isSelectedLanguage ? 'secondary-mobile-pane__language-link--selected' : ''}` :
                            "secondary-mobile-pane__link"
                          }>
                          <Link to={link.url} onClick={(e) => {if (isLanguage) { handleLanguageSwitch(e, link.id) } closeMenu()}}>
                            <div className="secondary-mobile-pane__link-title-wrapper">
                              {isLanguage && <Icon name={link.flag}/>}
                              {link.title}
                            </div>
                            {isSelectedLanguage &&
                              <span className="secondary-mobile-pane__active-language-icon-container">
                                <Icon name="checkmark" height='18' width='18' className='checkmark-icon' />
                              </span>
                            }
                            {!isLanguage && <p>{link.description}</p>}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </Tab.Pane>
              )
            })}
          </Tab.Content>
      </Container>
    </div>
  )
};

SecondaryMobilePane.propTypes = {
  onReturn: PropTypes.func,
  closeMenu: PropTypes.func, 
  showPane: PropTypes.bool 
};

SecondaryMobilePane.defaultsProps = {
  showPane: false,
  onReturn: () => {return},
  closeMenu: () => {return},
}

export default SecondaryMobilePane;