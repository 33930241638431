import AhmedAvatar from './img/Ahmed.png';
import MajidAvatar from './img/Majid.png';
import AbdullahAvatar from './img/Abdullah.png';
import AbuthnainAvatar from './img/Abuthnain.png';

export const mockData = [
  {
    quote: `"Qiwa platform is a platform for empowerment, localization and business continuity. Eventually it will be the engine of the job market in the future"`,
    author: {
      name: "Eng. Ahmed Al-Rajhi",
      profession: "Minister of Human Resources and Social Development",
      image: AhmedAvatar,
    },
  },
  {
    quote: `"Partnership with the private sector is important because the Kingdom’s Vision 2030 confirmed and defined that the private sector is the engine and executor of the vision"`,
    author: {
      name: "Eng. Ahmed Al-Rajhi",
      profession: "Minister of Human Resources and Social Development",
      image: AhmedAvatar,
    },
  },
  {
    quote: `"The Kingdom is an attractive destination for investment ... a stimulating environment and efficient procedures."`,
    author: {
      name: "Dr. Majid Alqasabi",
      profession: "Minister of Commerce",
      image: MajidAvatar,
    },
  },
  {
    quote: `"Today we celebrate participatory work and combined efforts. Qiwa was launched in record time and we are proud to be part of this success story"`,
    author: {
      name: "Eng. Abdullah Alswaha",
      profession: "Minister of Communications and Information Technology",
      image: AbdullahAvatar,
    },
  },
  {
    quote: `"We launched a strong platform to enhance transparency, raise the quality of the ministry’s services, support digital transformation and partner with government agencies to facilitate beneficiaries and customers"`,
    author: {
      name: "Dr. Abdullah Abuthnain",
      profession: "Deputy Minister of Human Resources and Social Development",
      image: AbuthnainAvatar,
    },
  },
  {
    quote: "Qiwa platform was launched on April 23, 2019",
  },
];

export const mockDataAR = [
  {
    quote: `منصة قوى هي منصة للتمكين والتوطين ولاستمرارية الأعمال، وبإذن الله ستكون هي المحرك لسوق العمل في المستقبل`,
    author: {
      name: "المهندس أحمد الراجحي",
      profession: "معالي وزير الموارد البشربة والتنمية الاجتماعية",
      image: AhmedAvatar,
    },
  },
  {
    quote: `الشراكة مع القطاع الخاص مهمة لأن رؤية المملكة 2030 أكدت وحددت أن القطاع الخاص هو المحرك، وهو المنفذ للرؤية`,
    author: {
      name: "المهندس أحمد الراجحي",
      profession: "معالي وزير الموارد البشربة والتنمية الاجتماعية",
      image: AhmedAvatar,
    },
  },
  {
    quote: `المملكة وجهة جذابة للاستثمار .. بيئة محفزة وإجراءات ميسرة`,
    author: {
      name: "الدكتور ماجد القصبي",
      profession: "معالي وزير التجارة",
      image: MajidAvatar,
    },
  },
  {
    quote: `اليوم نحتفي بعمل تشاركي وبتظافر جميع الجهود, أطلق في زمن قياسي ونحن فخورين أن نكون جزءا من قصة النجاح هذه`,
    author: {
      name: "المهندس عبدالله السواحة",
      profession: "معالي وزيرالاتصالات وتقنية المعلومات",
      image: AbdullahAvatar,
    },
  },
  {
    quote: `أطلقنا منصة قوى لتعزيز الشفافية ورفع مستوى جودة خدمات الوزارة, ودعم التحول الرقمي والشراكة مع الجهات الحكومية تسهيلاً للمستفيدين والعملاء`,
    author: {
      name: "الدكتورعبدالله أبوثنين",
      profession: "نائب وزير الموارد البشرية والتنمية الاجتماعية",
      image: AbuthnainAvatar,
    },
  },
  {
    quote: "تم إطلاق منصة قوى بتاريخ 23 ابريل 2019",
  },
];
