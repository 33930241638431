import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SaudiLaborSytemHero from "./SaudiLaborSystemHero";
import LaborSystemMaterials from "./LaborSystemMaterials";
import Accordion from "../Articles/Shared/Accordion/Accordion";
import "./SaudiLaborSystem.scss";
import Text from "../Articles/Shared/Text/Text";
import { MainContext } from "../../contexts/context";
import ActionTilesGroup from "../../ActionTilesGroup/ActionTilesGroup";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

function SaudiLaborSytem() {
  const { t } = useTranslation("translation", {
    keyPrefix: "saudiLaborSystem",
  });
  const { toolsAndCalculators, theme, saudiLaborSystem, isMobile } =
    useContext(MainContext);
  const [openIndex, setOpenIndex] = useState(null);
  const groupCta = {
    label: t("viewAllToolsAndCalculators"),
    url: "/tools-and-calculators",
  };
  useEffect(() => {
    if (saudiLaborSystem && window.location.hash) {
      const hashValue = window.location.hash.substring(1);
      const decodedHash = decodeURIComponent(hashValue);
      const targetIndex = saudiLaborSystem.faq.findIndex(question => {
        const formattedTitle = question.title.value.replace(/\s+/g, "-").toLowerCase();
        return theme === "english" ? formattedTitle === hashValue : formattedTitle === decodedHash;
      });
  
      if (targetIndex !== -1) {
        setOpenIndex(targetIndex);
        const element = document.getElementById(theme === "english" ? hashValue : decodedHash);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - 162;
          window.scrollTo({ top: elementPosition, behavior: "instant" });
        }
      }
    }
  }, [saudiLaborSystem, theme]);
  
  


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Container
      fluid
      className={classNames({
        "saudi-labor-system p-0": true,
        "saudi-labor-system--rtl p-0": theme === "arabic",
      })}
    >
      <SaudiLaborSytemHero header={t("header")} content={t("content")} />
      <Row className="m-0 p-0">
        <Col className="p-0">
          <LaborSystemMaterials
            isMobile={isMobile}
            saudiLaborSystem={saudiLaborSystem}
            title={t("title")}
          />
        </Col>
      </Row>
      <Row className="saudi-labor-system__questions m-0">
        <Col lg={8} className="saudi-labor-system__questions-body">
          <div className="saudi-labor-system__questions-header">
            <Text text={t("popularQuestions")} />
          </div>
          {saudiLaborSystem?.faq?.map((question, index) => {
            return (
              <Accordion
                key={index}
                question={question.title.value}
                index={question.title.value} 
                isOpen={openIndex === index}
                setOpenIndex={setOpenIndex}
                toggleAccordion={() => toggleAccordion(index)}
              >
                <Text text={question?.text?.value} />
              </Accordion>
            );
          })}
        </Col>
      </Row>
      <Row className="saudi-labor-system__tools-and-calculators ">
        {toolsAndCalculators && (
          <ActionTilesGroup
            title={toolsAndCalculators.title}
            description={toolsAndCalculators.description}
            cards={toolsAndCalculators.cards}
            useAccordion={false}
          />
        )}
      </Row>
    </Container>
  );
}

export default SaudiLaborSytem;
