import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import ResultsDropdown from "./ResultsDropdown.tsx";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/context.js";
import { useTranslation } from "react-i18next";
import { Search } from "@takamol/qiwa-design-system/components";

import fetchResults from "../SearchApi.js";

import "./HeroSearchInput.scss";

const HeroSearchInput = ({
  placeholder,
  buttonText,
  isSaudiLabor,
  id,
}: Props): React.ReactNode => {
  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resultsData, setResultsData] = useState<any>({});
  const [isInputFocused, setIsInputFocused] = useState(false);
  const navigate = useNavigate();
  const {
    showMobileSearch,
    setShowMobileSearch,
    isTablet,
    isMobile,
    setSearchUpdateValue,
    searchUpdateValue,
  } = useContext(MainContext);
  const storedLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation("translation", { keyPrefix: "search" });


  const searchRef = useRef<any>(null);

  const fetchData = async (query: string | null, page: number) => {
    setIsLoading(true);
    const searchStr =
      query === "" ? sessionStorage.getItem("searchQuery") : query;
    const materialsTranslation =
      storedLanguage === "ar" ? "نظام العمل" : "Labor Law";

    try {
      const data: any = await fetchResults(searchStr, {
        items_per_page: "All",
        materials: isSaudiLabor ? materialsTranslation : "!()",
        page: page ? page : 0,
        sort_by: "relevance_descending",
        topics: [],
        search_dropdown: true,
      });

      setIsLoading(false);

      if (data) {
        setResultsData(data);
      }
      if (data && !data.search_results) {
        sessionStorage.removeItem("searchQuery");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSearchInput = (value: any) => {
    setQuery(value);
    debouncedSearchInput(value, 0);
    if (value !== "") {
      sessionStorage.setItem("searchQuery", value);
    } else {
      sessionStorage.setItem("searchQuery", "");
    }
  };

  const debouncedSearchInput = useCallback(debounce(fetchData, 800), []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
        setQuery("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const onShowMoreClick = () => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    const searchStr =
      query === "" ? sessionStorage.getItem("searchQuery") : query;

    setShowMobileSearch(false);

    navigate("/" + storedLanguage + "/search?q=" + searchStr);
  };

  const onInputFocus = (e: React.FocusEvent) => {
    if ((isMobile || isTablet) && !showMobileSearch) {
      if (id === "main-hero-search-input-id") {
        (e.target as HTMLElement).blur();
      }
      setShowMobileSearch(true);
      document.body.classList.add("body-freeze");
      return;
    }

    setIsInputFocused(true);
    setShowResults(true);
    const searchStr =
      query === "" ? sessionStorage.getItem("searchQuery") : query;
    fetchData(searchStr, resultsData?.pager?.current_page);
  };

  const translatedPlaceholder =
    placeholder && !!placeholder.length ? placeholder : t("placeholder");
  const translatedButton =
    buttonText && !buttonText.length ? buttonText : t("search");

  return (
    <div className={`hero-search-input`} ref={searchRef}>
      <div className={"hero-search-input__wrapper"}>
        <Search
          data-testid="hero-search-input-test"
          id={id}
          onChange={(e) => {
            onSearchInput(e.target.value);
          }}
          onFocus={(e) => onInputFocus(e)}
          onBlur={() => {
            setIsInputFocused(false);
          }}
          onClear={() => onSearchInput("")}
          onSubmit={() => {
            document.body.classList.remove("body-freeze");
            const storedLanguage = localStorage.getItem("i18nextLng");
            isSaudiLabor
              ? navigate(
                  "/" + storedLanguage + "/search?q=" + query + "&materials=sls"
                )
              : navigate("/" + storedLanguage + "/search?q=" + query);
            setSearchUpdateValue(searchUpdateValue + 1);
            setShowMobileSearch(false);
            if (query) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "KC_Search",
                query_content: query,
              });
            }
          }}
          placeholder={isInputFocused ? "" : translatedPlaceholder}
          showButton={isTablet || isMobile ? false : true}
          size="large"
          value={query}
          variant="business"
          searchButtonLabel={translatedButton}
        />
      </div>
      {showResults && (
        <ResultsDropdown
          query={query}
          loading={isLoading}
          data={resultsData}
          showMoreClick={onShowMoreClick}
        />
      )}
    </div>
  );
};

type Props = {
  placeholder?: string;
  buttonText?: string;
  id: string;
  isSaudiLabor?: boolean;
};

export default HeroSearchInput;

HeroSearchInput.defaultProps = {
  placeholder: "",
  buttonText: "",
  isSaudiLabor: false,
  id: "hero-search-input-id",
};
