import React from "react";
import "./Banner.scss";
import { Container, Row, Col } from "react-bootstrap";
import HeroImage from "../../../../../../images/videoAttribute.png";
import VideoWidget from "../../../Shared/VideoWidget/VideoWidget";
import PropTypes from 'prop-types';

const Interludesvideo = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Container fluid className="interludesvideo">
      <Row className=" mt-4 mb-5">
        <Col className="interludesvideo__header" >{data?.title}</Col>
      </Row>
      <Row className="row">
        <Col lg={5}>
          {data?.videoContent?.map((videoContent, index) => (
            <div className="interludesvideo__videoContent" key={index}>{videoContent}</div>
          ))}
        </Col>
        <Col lg={7}>
          <VideoWidget
            videoTitle={data?.button_title}
            videoContent={data?.content}
            externalVideoLink={data?.button_url}
            imgUrl={HeroImage}
          />
        </Col>
      </Row>
    </Container>
  );
};

Interludesvideo.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,         
    videoContent: PropTypes.arrayOf(PropTypes.string), 
    button_title: PropTypes.string,  
    content: PropTypes.string,        
    button_url: PropTypes.string      
  }) 
};

export default Interludesvideo;
