import { useState, useEffect, useContext } from "react";
import "./Accordion.scss";
import Container from "react-bootstrap/Container";
import Icon from "../../../../Icon/Icon";
import { useLocation } from "react-router-dom";
import { Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../../contexts/context";
import IconTooltip from "../IconTooltip/IconTooltip";
import { Divider } from "@takamol/qiwa-design-system/components";
import PropTypes from 'prop-types';

const Accordion = ({
  question,
  children,
  index,
  isOpen,
  setOpenIndex,
  toggleAccordion,
  isCopy,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const { theme } = useContext(MainContext);
  const location = useLocation();
  const [showToast, setShowToast] = useState(false);
  const indexWithHash = index?.replace(/\s+/g, "-").toLowerCase();

  const handleCopyLink = () => {
    const url = `${window.location.origin}${window.location.pathname}#${indexWithHash}`;
    navigator.clipboard.writeText(url);
    setShowToast(true);
  };

  return (
    <Container>
      <button
        className={`accordion ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
        data-testid="accordion"
        id={`${indexWithHash}`}
        role="button"
        tabIndex={0}
      >
        <div className="accordion__header">
          <div className="accordion__title">{question}</div>
          <div className="accordion__actions">
            <div className="accordion__icon" data-testid="icon">
              <Icon name={`chevron${isOpen ? "Up" : "Down"}`} />
            </div>
          </div>
        </div>
      </button>
      <Divider />
      {isOpen && (
        <div className="accordion__content" data-testid="content">
          {isCopy && (
            <div className="accordion__content__copy">
              <div className="accordion__content__text">{t("answer")}</div>

              <button
                className="accordion__copy-button"
                onClick={handleCopyLink}
              >
                <IconTooltip iconClassName="copy-icon" />
              </button>
            </div>
          )}
          {children}
        </div>
      )}
      <Toast
        className="toast-copy-message"
        bg={"success"}
        show={showToast}
        autohide={true}
        onClose={() => setShowToast(false)}
      >
        <Toast.Body>
          <div className="checkmark-success">
            <Icon name="checkmark"></Icon>
          </div>
          <p>{t("copyText")}</p>
          <button
            className="toast-close-button"
            onClick={() => setShowToast(false)}
          >
            <Icon name="close" />
          </button>
        </Toast.Body>
      </Toast>
    </Container>
  );
};

Accordion.defaultProps = {
  isCopy: "true",
};

Accordion.propTypes = {
  question: PropTypes.string,
  children: PropTypes.node,
  index: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpenIndex: PropTypes.func,
  toggleAccordion: PropTypes.func,
  isCopy: PropTypes.bool,
};

export default Accordion;
