import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeroSearchInput from "../../Search/HeroSearchInput/HeroSearchInput.tsx";
import "./LaborSystemMaterials.scss";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Grid, Tile, Text, CircleBadge } from "@takamol/qiwa-design-system/components"

function LaborSystemMaterials({saudiLaborSystem, title, isMobile }) {
  const storedLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation("translation", { keyPrefix: "saudiLaborSystem" });
  return (
    <Container fluid className="labor-system-materials">
      <Row className="labor-system-materials__body">
        <Col className="p-0">
          <p className="labor-system-materials__header">
           {title}
          </p>
          <Row>
            <Col xl={6}>
              <HeroSearchInput placeholder={isMobile && t("search")} isSaudiLabor={true} id="labor-system-search-input-id" />
            </Col>
          </Row>
          <Row className="labor-system-materials__tiles-row">
            <Col>
              <Grid
                columns={[
                  1,
                  3
                ]}
                columnGap={[24, 24]}
                rowGap={[24, 32]}
              >
                {saudiLaborSystem?.cards.map((card, index) => {
                  return (
                    <Tile
                      key={index}
                      href={`/${storedLanguage}${card.url}`}
                      variant="neutral"
                      component="a"
                      className="labor-system-materials__action-tile"
                    >
                      <CircleBadge
                        role="status"
                        variant="individuals"
                      >
                        <Text
                          color="grayscale_900"
                          variant="special-captionSmall"
                          weight="bold"
                        >
                          {card.tag}
                        </Text>
                      </CircleBadge>
                      <Text 
                        variant="heading-xxs"
                        weight="semibold"
                        mt={16}
                      >
                        {card.title}
                      </Text>
                    </Tile>
                  )
                })}
              </Grid>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

LaborSystemMaterials.propTypes = {
  saudiLaborSystem: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      tag: PropTypes.string,
      title: PropTypes.string
    }))
  }),
  title: PropTypes.string, 
  isMobile: PropTypes.bool 
};

export default LaborSystemMaterials;
