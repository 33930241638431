import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import { composeDate } from "../../utils/composeDate";
import { mockedData } from "./mockedData";
import { MainContext } from "../contexts/context";
import { Button } from "@takamol/qiwa-design-system/components";
import PropTypes from 'prop-types';

const EndOfServiceRewardResult = ({ data, onReturn }) => {
  const dateFields = ["contractStartDate", "contractEndDate"];
  const { theme, isMobile, isTablet } = useContext(MainContext);
  const {
    endOfServiceCalcTranslations: { limitedPeriod, unlimitedPeriod },
    endOfServiceResultRows,
  } = mockedData;
  const { t } = useTranslation("translation", {
    keyPrefix: "endOfServiceRewardResult",
  });
  const storedLanguage = localStorage.getItem("i18nextLng");

  return (
    <div
      className="end-of-service-reward__calc-result"
      data-testid="end-of-service-reward-result-test"
    >
      {(!isMobile && !isTablet) && (
          <button
            onClick={onReturn}
            className="end-of-service-reward__return-btn cend-of-service-reward__return-btn--top"
            data-testid="end-of-service-reward-result-return-test"
          >
            <Icon
              name={theme === "english" ? "chevron-left" : "chevron-right"}
            />
            {t("backToCalculator")}
          </button>
        )}
      <span className="end-of-service-reward__calc-result__result-title">
        {t("endOfServiceReward")}
      </span>
      <ul className="end-of-service-reward__result-list">
        {endOfServiceResultRows.map((row, index) => {
          return endOfServiceResultRows.length - 1 !== index ? (
            <li className="end-of-service-reward__result-item" key={index}>
              <p className="end-of-service-reward__result-item-label">
                {t(row)}
              </p>
              {data && (
                <p className={"end-of-service-reward__result-item-value"}>
                  {dateFields.includes(row)
                    ? composeDate(data[row])
                    : row === "salary"
                    ? `SAR ${data[row]}`
                    : row === "typeOfContract" &&
                      data[row] === limitedPeriod.english
                    ? limitedPeriod[theme]
                    : row === "typeOfContract" &&
                      data[row] === unlimitedPeriod.english
                    ? unlimitedPeriod[theme]
                    : row === "endOfContractReason"
                    ? data?.endOfContractReason?.[
                        `${
                          storedLanguage === "en"
                            ? "EnDescription"
                            : "ArDescription"
                        }`
                      ]
                    : data[row]}
                </p>
              )}
            </li>
          ) : (
            <li className="end-of-service-reward__result-item" key={index}>
              <p className="end-of-service-reward__result-item-label">
                {t(row)}
              </p>
              {data && (
                <p className="end-of-service-reward__result-item-value end-of-service-reward__result-item-value--reward">
                  {data.RewardAmount} {t("sar")}
                </p>
              )}
            </li>
          );
        })}
      </ul>
      {(isMobile || isTablet) && (
        <Button onClick={onReturn} className="end-of-service-reward__return-btn__mobile" width="100%" variant="business_ghost"> {t("backToCalculator")}</Button>
        )}
    </div>
  );
};

EndOfServiceRewardResult.propTypes = {
  data: PropTypes.shape({
    contractStartDate: PropTypes.string,
    contractEndDate: PropTypes.string,
    salary: PropTypes.number,
    typeOfContract: PropTypes.oneOf(['limited', 'unlimited']),
    endOfContractReason: PropTypes.shape({
      EnDescription: PropTypes.string,
      ArDescription: PropTypes.string
    }),
    RewardAmount: PropTypes.number
  }), 
  onReturn: PropTypes.func 
};

EndOfServiceRewardResult.defaultProps = {
  data: {},
  onReturn: () => {
    return;
  },
};

export default EndOfServiceRewardResult;
