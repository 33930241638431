import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./HeroSection.scss";
import Link from "../../../../../Link/Link";
import Breadcrumbs from "../../../../../Breadcrumbs/Breadcrumbs";
import transformHeroTitle from "../../../transformNameToURL";
import { findLast, first } from "lodash";
import { MainContext } from "../../../../../contexts/context";
import classNames from "classnames";
import Videos from "../Videos/Videos";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const Herosection = ({ data, breadcrumbs}) => {
  const { theme } = React.useContext(MainContext);
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  if (!data) {
    return null;
  }
  return (
    <div
      className={classNames({
        "journey-article-hero": true,
        "journey-article-hero--rtl": theme !== "english",
      })}
      data-testid="hero-section"
    >
      <Container>
        <Row className="journey-article-hero__row">
          <Col className="journey-article-hero__left-col" lg={!data?.video ? 8 : 5}>
            <Breadcrumbs theme="light" Breadcrumbs={breadcrumbs}>
            {breadcrumbs?.slice(1, -1).map((item, index) => (
            <Link
              key={index}
              to={item?.url}
              label={item?.title}
            />
          ))}
          {breadcrumbs?.length > 0 && (
            <span>{breadcrumbs[breadcrumbs.length - 1]?.title }</span>
          )}
            </Breadcrumbs>
            <div className="journey-article-hero__headline h1 mt-4 ">
              {data?.headline}
            </div>
            <div className="journey-article-hero__text ">{data?.txt}</div>
          </Col>
          <Col className="journey-article-hero__right-col" lg={6}>
            <Videos videoData={data?.video} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Herosection.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.string,  
    txt: PropTypes.string,      
    video: PropTypes.object      
  }), 
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,       
    title: PropTypes.string     
  })) 
};

export default Herosection;
