import React, { useState } from "react";
import Icon from "../Icon/Icon";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import "./ContrastModeDropdown.scss";

const ContrastModeDropdown = () => {
  const [showList, setShowList] = useState<boolean>(false);
  const [selectedMode, setSelectedMode] = useState<string>("light");
  const { t } = useTranslation("translation", {
    keyPrefix: "accesibility",
  });

  const parseSelectedMode = (mode: string) => {
    switch (mode) {
      case "light":
        return (
          <>
            <Icon className="" name="light-mode" viewBox="0 0 20 21" />{" "}
            {t("light")}
          </>
        );
      case "dark":
        return (
          <>
            <Icon className="" name="dark-mode" /> {t("dark")}
          </>
        );
      case "contrast":
        return (
          <>
            <Icon className="" name="contrast-mode" /> {t("highContrast")}
          </>
        );
    }
  };

  const onModeClick = (mode: string) => {
    setSelectedMode(mode);
    setShowList(false);

    type Filters = {
      [key: string]: string;
    };

    const filters: Filters = {
      dark: "grayscale(100%)",
      contrast: "grayscale(100%) invert(90%)",
      light: "",
    };

    document.documentElement.style.filter = filters[mode];
  };

  return (
    <div className="contrast-mode-dropdown">
      <button
        onClick={() => setShowList(true)}
        className="contrast-mode-dropdown__trigger-list-btn"
      >
        {parseSelectedMode(selectedMode)}
        <Icon className="" name="chevron-down" />
      </button>
      <ul
        className={classnames(
          "contrast-mode-dropdown__modes-list",
          showList && "contrast-mode-dropdown__modes-list--open"
        )}
      >
        <li className="contrast-mode-dropdown__modes-list-item">
          <button
            className={classnames(
              "contrast-mode-dropdown__mode-btn",
              selectedMode === "light" &&
                "contrast-mode-dropdown__mode-btn--selected"
            )}
            onClick={() => onModeClick("light")}
          >
            <Icon
              className=""
              name={selectedMode === "light" ? "checkmark" : "light-mode"}
            />
            {t("light")}
          </button>
        </li>
        <li className="contrast-mode-dropdown__modes-list-item">
          <button
            className={classnames(
              "contrast-mode-dropdown__mode-btn",
              selectedMode === "dark" &&
                "contrast-mode-dropdown__mode-btn--selected"
            )}
            onClick={() => onModeClick("dark")}
          >
            <Icon
              className=""
              name={selectedMode === "dark" ? "checkmark" : "dark-mode"}
            />
            {t("dark")}
          </button>
        </li>
        <li className="contrast-mode-dropdown__modes-list-item">
          <button
            className={classnames(
              "contrast-mode-dropdown__mode-btn",
              selectedMode === "contrast" &&
                "contrast-mode-dropdown__mode-btn--selected"
            )}
            onClick={() => onModeClick("contrast")}
          >
            <Icon
              className=""
              name={selectedMode === "contrast" ? "checkmark" : "contrast-mode"}
            />
            {t("highContrast")}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ContrastModeDropdown;
