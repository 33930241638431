import React, { useRef, useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../Loader/Loader";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Icon from "../Icon/Icon";
import { Select, Field, Button } from "@takamol/qiwa-design-system/components";
import { MainContext } from "../contexts/context";

import {
  salaryCertReq,
  serviceCertReq,
  nationalizationCertReq,
  debtCertReq,
  complianceCertReq
} from './api.js'

import "./CertificateValidation.scss";

export const submitCalcData = (
  certType,
  estNumber,
  certNumber,
  setData,
  showErrorMsg,
  setLoading,
  setErrorMsg,
) => {

  switch (certType) {
    case "Salary Certificate":
      return salaryCertReq(setLoading, setData, certType, `${process.env.REACT_APP_FULL_URL}/salary_certificate/api-request?LaborerIdNo=` + estNumber + "&CertificateId=" + certNumber, axios, showErrorMsg, setErrorMsg);
    case "Nationalization certificate":
      return nationalizationCertReq(setLoading, setData, certType, `${process.env.REACT_APP_FULL_URL}/saudization_certificate_calculator/api-request?establishment_id=` + estNumber + "&cert_number=" + certNumber, axios, showErrorMsg, setErrorMsg);
    case "Compliance certificate":
      return complianceCertReq(setLoading, setData, certType, `${process.env.REACT_APP_FULL_URL}/api/v1/compliance-certificate?establishmentSequenceNumber=` + estNumber + "&certificateNumber=" + certNumber, axios, showErrorMsg);
    case "Service Certificate":
      return serviceCertReq(setLoading, setData, certType, `${process.env.REACT_APP_FULL_URL}/api/v1/compliance-certificate-validation?certificateNumber=` + certNumber + "&laborerId=" + estNumber, axios, showErrorMsg);
    case "Debt Certificate":
      return debtCertReq(setLoading, setData, certType, `${process.env.REACT_APP_FULL_URL}/api/v1/debt-certificate-validation?`, axios, showErrorMsg, certNumber, estNumber);
    default:
      return;
  }
};

const CertificateCalc = ({ setData }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "certCalc",
  });
  const [certType, setCertType] = useState("Nationalization certificate");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [defaultError, setDefaultError] = useState(t("defaultError"));
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const { theme } = useContext(MainContext);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
    control
  } = useForm();
  
  useEffect(() => {
    setDefaultError(t("defaultError"));
  }, [theme]);

  useEffect(() => {
    if (recaptchaRef) {
      recaptchaRef.current.captcha
        ? (recaptchaRef.current.captcha.style = "margin-bottom: 24px;")
        : (recaptchaRef.current.style = "margin-bottom: 24px;");
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    setShowErrorMsg(false);
    if (
      recaptchaRef.current.getValue() &&
      recaptchaRef.current.getValue().length
    ) {
      submitCalcData(
        certType,
        certType === "Salary Certificate" || certType === "Service Certificate"
          ? data.nationalId
          : data.saudizationCertificate,
        data.certificateNumber,
        setData,
        setShowErrorMsg,
        setLoading,
        setDefaultError,
      );
    } else {
      setError("captchaError", {
        type: "custom",
        message: t("errors.captchaError"),
      });
      setLoading(false);
    }
  };

  const onErrors = (errors) => {
    if (
      errors.captchaError &&
      recaptchaRef.current.getValue() &&
      recaptchaRef.current.getValue().length
    ) {
      clearErrors("captchaError");
      onSubmit(getValues());
    }
  };

  const firstInputVariants = {
    "Nationalization certificate": {
      label: t("establishmentNumber"),
      name: "saudizationCertificate",
      requiredError: t("errors.saudizationCertificate"),
      pattern: /^\d{1,3}-\d{1,11}$/,
      ariaLabel: t("establishmentNumber"),
      isError: errors?.saudizationCertificate,
      errorMessage: errors?.saudizationCertificate?.message
    },
    "Compliance certificate": {
      label: t("establishmentNumber"),
      name: "saudizationCertificate",
      requiredError: t("errors.saudizationCertificate"),
      pattern: /^\d{1,3}-\d{1,11}$/,
      ariaLabel: t("establishmentNumber"),
      isError: errors?.saudizationCertificate,
      errorMessage: errors?.saudizationCertificate?.message
    },
    "Debt Certificate": {
      label: t("establishmentNumber"),
      name: "saudizationCertificate",
      requiredError: t("errors.saudizationCertificate"),
      pattern: /^\d{1,3}-\d{1,11}$/,
      ariaLabel: t("establishmentNumber"),
      isError: errors?.saudizationCertificate,
      errorMessage: errors?.saudizationCertificate?.message
    },
    "Salary Certificate": {
      label: t("nationalId"),
      name: "nationalId",
      requiredError: t("errors.nationalId"),
      pattern: /^[12]\d{9}$/,
      ariaLabel: t("nationalId"),
      isError: errors?.nationalId,
      errorMessage: errors?.nationalId?.message
    },
    "Service Certificate": {
      label: t("nationalId"),
      name: "nationalId",
      requiredError: t("errors.nationalId"),
      pattern: /^\d{10}$/,
      ariaLabel: t("nationalId"),
      isError: errors?.nationalId,
      errorMessage: errors?.nationalId?.message
    },
  }

  return (
    <div
      className="certificate-validation-tool__calc-content"
      data-testid="certificate-calc-test"
    >
      {loading && (
        <div className="certificate-validation-tool__loader">
          <Loader />
        </div>
      )}
      <Form
        onSubmit={handleSubmit(onSubmit, onErrors)}
        data-testid="certificate-calc-form"
      >
        <Form.Group className="mb-4" controlId="certificationType">
          <Form.Label>{t("certType")}</Form.Label>
          <Controller
              name="endOfContractReason"
              control={control}
              render={({field: { onChange, onBlur }, fieldState: { error }}) => (
                <Select
                  id="serc-calc-content-select"
                  data-testid="certificate-calc-select"
                  variant="business"
                  errorMessage={error?.message}
                  isError={error}
                  options={[
                    {
                      option: t("certOptions.saudizationCert"),
                      value: "Nationalization certificate",
                    },
                    {
                      option: t("certOptions.salaryCert"),
                      value: "Salary Certificate",
                    },
                    {
                      option: t("certOptions.complianceCert"),
                      value: "Compliance certificate",
                    },
                    {
                      option: t("certOptions.serviceCert"),
                      value: "Service Certificate",
                    },
                    {
                      option: t("certOptions.debtCert"),
                      value: "Debt Certificate",
                    },
                  ]}
                  maxInputWidth={'100%'}
                  onChange={(newValue) => {
                    onChange(newValue);
                    setCertType(newValue)
                  }}
                  onBlur={onBlur}
                  value={certType}
                />
              )}
            />
        </Form.Group>
        <Form.Group className="mb-4" controlId="establishmentNumber">
          <Form.Label>
            {firstInputVariants[`${certType}`].label}
          </Form.Label>
          <Field
            {...register(
              firstInputVariants[`${certType}`].name,
              {
                required: firstInputVariants[`${certType}`].requiredError,
                pattern: {
                  value: firstInputVariants[`${certType}`].pattern,
                  message: t("errors.incorrectData"),
                },
              }
            )}
            ariaLabel={firstInputVariants[`${certType}`].ariaLabel}
            id="establishment-number-input"
            maxInputWidth={'100%'}
            fieldWidth={'100%'}
            isError={firstInputVariants[`${certType}`].isError}
            errorMessage={firstInputVariants[`${certType}`].errorMessage}
            data-testid="certificate-calc-id-input"
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="certificateNumber">
          <Form.Label>{t("certNumber")}</Form.Label>
          <Field
            {...register("certificateNumber", {
              required: t("errors.certificateNumber"),
              pattern: {
                  value: /^\d{6}-\d{8}$/,
                  message: t("errors.incorrectData"),
                },
            })}
            ariaLabel='Certificate Number'
            id="cert-number-input"
            maxInputWidth={'100%'}
            fieldWidth={'100%'}
            isError={errors.certificateNumber}
            errorMessage={errors?.certificateNumber?.message}
            data-testid="certificate-calc-cert-input"
          />
        </Form.Group>
        <Form.Group>
          {errors.captchaError && (
            <p className="certificate-validation-tool__error-msg">
              <Icon name={'info'} width="20" height="20" /> {errors.captchaError.message}
            </p>
          )}
          <ReCAPTCHA
            sitekey="6Ld_4nYdAAAAAMpoTic6J_BuaYLtT8GSb0zaIS9L" // from .env file on BE side
            ref={recaptchaRef}
          />
        </Form.Group>
        {showErrorMsg && (
          <p className="certificate-validation-tool__error-msg">
            <Icon name={'info'} width="20" height="20" /> {defaultError}
          </p>
        )}
        <Button
          variant="business_primary_filled"
          type="submit"
          data-testid="certificate-calc-submit"
        >
          {t("submitBtn")}
        </Button>
      </Form>
    </div>
  );
};

CertificateCalc.defaultProps = {
  setData: () => {
    return;
  },
};

export default CertificateCalc;
