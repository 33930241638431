import React, { useState, useEffect, useRef } from "react";
import Link from "../Link/Link";
import Icon from "../Icon/Icon";
import ContrastModeDropdown from "../ContrastModeDropdown/ContrastModeDropdown";
import { useTranslation } from "react-i18next";

import "./AccessibilityTools.scss";

const AccessibilityTools = () => {
  const [zoomLevel, setZoomLevel] = useState<number>(100);
  const toolsRef = useRef(null);
  const { t } = useTranslation("translation", {
    keyPrefix: "accesibility",
  });

  const onFontChange = (type: string) => {
    type === "increase"
      ? setZoomLevel((level) => level + 25)
      : setZoomLevel((level) => level - 25);
    setTimeout(() => {
      changeScroll();
    }, 0);
  };

  const changeScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight + 10000,
      behavior: "instant",
    });
  };

  useEffect(() => {
    if (zoomLevel === 75) {
      document.body.classList.add("body-zoomed-out");
    } else if (zoomLevel === 100) {
      document.body.classList.remove("body-zoomed-out");
      document.body.classList.remove("body-zoomed-in");
    } else if (zoomLevel === 125) {
      document.body.classList.add("body-zoomed-in");
    }
  }, [zoomLevel]);

  return (
    <div className="accessibility-tools" ref={toolsRef}>
      <div className="accessibility-tools__active-tools-block">
        <button
          className="accessibility-tools__font-size-btn"
          onClick={() => onFontChange("increase")}
          disabled={zoomLevel === 125}
        >
          A+
        </button>
        <button
          className="accessibility-tools__font-size-btn"
          onClick={() => onFontChange("decrease")}
          disabled={zoomLevel === 75}
        >
          A-
        </button>
        <ContrastModeDropdown />
      </div>
      <Link
        to="/customer-video-calling-service"
        className="accessibility-tools__sign-language-link"
        target="_blank"
        data-testid="sign-language-link"
      >
        <Icon
          className="accessibility-tools__external-link-icon"
          name="external-link"
        />
        {t("signSupport")}
      </Link>
    </div>
  );
};

export default AccessibilityTools;
