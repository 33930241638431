import React, { useState, useContext } from "react";
import classnames from "classnames";
import Icon from "../../Icon/Icon";
import SearchPopup from "./SearchPopup.tsx";
import { MainContext } from "../../contexts/context.js";
import { useTranslation } from "react-i18next";

import "./NavigationSearch.scss";

const NavigationSearch = ({ variant, resolution }: Props) => {
  const [popupKey, setPopupKey] = useState(() => crypto.randomUUID());
  const { showMobileSearch, setShowMobileSearch } = useContext(MainContext);
  const { t } = useTranslation("translation", { keyPrefix: "search" });

  const onShowPopup = (show: boolean) => {
    if (show) {
      document.body.classList.add("body-freeze");
      setPopupKey(crypto.randomUUID());
      setShowMobileSearch(true);
      sessionStorage.removeItem('savedUrls');
    } else {
      document.body.classList.remove("body-freeze");
      setShowMobileSearch(false);
    }
  };

  return (
    <div className="navigation-search dropdown nav-item">
      {!showMobileSearch && (
        <button
          className={classnames(
            "navigation-search__open-popup-btn",
            resolution === "desktop" &&
              "main-menu-login__desktop-link nav-link kc-link dropdown-toggle kc-link--text-link nav-link--with-underline",
            resolution === "mobile" && "main-menu-navbar__mobile-search",
            variant === "light" &&
              "main-menu-login__desktop-link__light nav-link__light"
          )}
          onClick={() => onShowPopup(true)}
        >
          <div className="main-menu-navbar__dropdown-label">
            <Icon
              className={classnames(
                "search-icon",
                variant === "light" && "search-icon--light"
              )}
              name="search"
            />
            <span className="c__dropdown-label--span false">{t("search")}</span>
          </div>
        </button>
      )}

      <SearchPopup
        key={popupKey}
        onClose={() => onShowPopup(false)}
        openState={showMobileSearch}
      />
    </div>
  );
};

type Props = {
  variant: string;
  resolution: string;
};

NavigationSearch.defaultProps = {
  variant: "dark",
  resolution: "desktop",
};

export default NavigationSearch;
