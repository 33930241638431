export const mockedData = {
  breadCrumbText: "Certificate Validation",
  title: "Certificate Validation",
  description: `description`,
  salaryCertificateRows: [
    {
      field: 'CertificateType',
      labelEng: 'Certification type',
      labelAr: 'Certification type',
    },
    {
      field: 'CertificateId',
      labelEng: 'Certificate number',
      labelAr: 'رقم الشهادة',
    },
    {
      field: 'SCCreationDate',
      labelEng: 'Certificate issue date',
      labelAr: 'تاريخ إصدار الشهادة'
    },
    {
      field: 'SCExpiryDate',
      labelEng: 'Certificate expiry date',
      labelAr: 'تاريخ انتهاء الشهادة'
    },
    {
      field: 'EstablishmentName',
      labelEng: 'Establishment name',
      labelAr: 'جهة العمل'
    },
    {
      field: 'LaborerName',
      labelEng: 'Employee name',
      labelAr: 'اسم الموظف'
    },
    {
      field: 'Job',
      labelEng: 'Occupation',
      labelAr: 'المهنة'
    },
    {
      field: 'JobTitle',
      labelEng: 'Job title',
      labelAr: 'المسمى الوظيفي'
    },
    {
      field: 'ServiceStartDate',
      labelEng: 'Job start date (joining date)',
      labelAr: 'تاريخ الالتحاق بالعمل'
    },
    {
      field: 'Nationality',
      labelEng: 'Nationality',
      labelAr: 'الجنسية'
    },
    {
      field: 'TotalSalary',
      labelEng: 'Total Salary',
      labelAr: 'الراتب الإجمالي'
    },
  ],
  saudizationCertificateRows: [
    {
      field: 'CertificateType',
      labelEng: 'Certification type',
      labelAr: 'Certification type',
    },
    {
      field: 'CertificateNumber',
      labelEng: 'Certificate number',
      labelAr: 'رقم الشهادة',
    },
    {
      field: 'EstablishmentId',
      labelEng: 'Establishment number',
      labelAr: 'رقم المنشأة'
    },
    {
      field: 'EstablishmentName',
      labelEng: 'Establishment name',
      labelAr: 'جهة العمل'
    },
    {
      field: 'SaudiCertIssueDate',
      labelEng: 'Certificate issue date',
      labelAr: 'تاريخ إصدار الشهادة'
    },
    {
      field: 'SaudiCertExpiryDate',
      labelEng: 'Certificate expiry date',
      labelAr: 'تاريخ انتهاء الشهادة'
    },
  ],
  complianceCertRows: [
    {
      field: 'CertificateType',
      labelEng: 'Certification type',
      labelAr: 'Certification type',
    },
    {
      field: 'CertificateNumber',
      labelEng: 'Certificate number',
      labelAr: 'رقم الشهادة',
    },
    {
      field: 'EstablishmentId',
      labelEng: 'Establishment number',
      labelAr: 'رقم المنشأة'
    },
    {
      field: 'EstablishmentName',
      labelEng: 'Establishment name',
      labelAr: 'جهة العمل'
    },
    {
      field: 'SCCreationDate',
      labelEng: 'Certificate issue date',
      labelAr: 'تاريخ إصدار الشهادة'
    },
    {
      field: 'SCExpiryDate',
      labelEng: 'Certificate expiry date',
      labelAr: 'تاريخ انتهاء الشهادة'
    },
  ],
  serviceCertificateRows: [
    {
      field: 'CertificateType',
      labelEng: 'Certification type',
      labelAr: 'Certification type',
    },
    {
      field: 'CertificateId',
      labelEng: 'Certificate number',
      labelAr: 'رقم الشهادة',
    },
    {
      field: 'LaborerName',
      labelEng: 'Employee name',
      labelAr: 'اسم الموظف'
    },
    {
      field: 'EmployerName',
      labelEng: 'Employer name',
      labelAr: 'جهة العمل'
    },
    {
      field: 'Nationality',
      labelEng: 'Nationality',
      labelAr: 'الجنسية'
    },
    {
      field: 'JobTitle',
      labelEng: 'Job title',
      labelAr: 'المسمى الوظيفي'
    },
    {
      field: 'JobType',
      labelEng: 'Job type',
      labelAr: 'المهنة'
    },
    {
      field: 'ServiceStartDate',
      labelEng: 'Job start date',
      labelAr: 'تاريخ الالتحاق بالعمل'
    },
    {
      field: 'ServiceEndDate',
      labelEng: 'Job end date',
      labelAr: 'تاريخ انتهاء العمل'
    },
  ],
  debtCertificateRows: [
    {
      field: 'CertificateType',
      labelEng: 'Certification type',
      labelAr: 'Certification type',
    },
    {
      field: 'CertificateNumber',
      labelEng: 'Certificate number',
      labelAr: 'رقم الشهادة',
    },
    {
      field: 'EstablishmentId',
      labelEng: 'Establishment number',
      labelAr: 'رقم المنشأة'
    },
    {
      field: 'EstablishmentName',
      labelEng: 'Establishment name',
      labelAr: 'جهة العمل'
    },
    {
      field: 'SCCreationDate',
      labelEng: 'Certificate issue date',
      labelAr: 'تاريخ إصدار الشهادة'
    },
    {
      field: 'SCExpiryDate',
      labelEng: 'Certificate expiry date',
      labelAr: 'تاريخ انتهاء الشهادة'
    },
  ],
};
