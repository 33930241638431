import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import ActionTilesGroup from "../../../ActionTilesGroup/ActionTilesGroup";
import { MainContext } from "../../../contexts/context";
import { AccordionOuterWrapper } from "../../../../utils/AccordionOuterWrapper";

const ProvidersTab = () => {
  const contextValue = useContext(MainContext);
  const {
    lawOffices,
    manageQiwaServiceProvider,
    toolsAndCalculators,
    saudiLaborSystem,
  } = contextValue;

  return (
    <>
      <AccordionOuterWrapper>
        <div className="providers-tab" data-testid="providers-tab-test">
          {lawOffices && (
            <ActionTilesGroup
              title={lawOffices.title}
              titleLink={lawOffices.titleLink}
              cards={lawOffices.cards}
              description={lawOffices.description}
              groupCta={lawOffices.groupCta}
              theme={lawOffices.theme}
              useAccordion={true}
              eventKey={"1"}
            />
          )}
          {manageQiwaServiceProvider && (
            <ActionTilesGroup
              title={manageQiwaServiceProvider.title}
              titleLink={manageQiwaServiceProvider.titleLink}
              cards={manageQiwaServiceProvider.cards}
              description={manageQiwaServiceProvider.description}
              groupCta={manageQiwaServiceProvider.groupCta}
              theme={"providers"}
              useAccordion={true}
              forcedTheme={true}
            />
          )}
                    <div className="tools-block">
            {toolsAndCalculators && (
              <ActionTilesGroup
                title={toolsAndCalculators.title}
                description={toolsAndCalculators.description}
                cards={toolsAndCalculators.cards}
                useAccordion={false}
              />
            )}
            {saudiLaborSystem && (
              <ActionTilesGroup
                title={saudiLaborSystem.title}
                titleLink={false}
                description={saudiLaborSystem.description}
                cards={saudiLaborSystem.cards?.slice(0, 3)}
                groupCta={saudiLaborSystem.groupCta}
                useAccordion={false}
              />
            )}
          </div>
        </div>
      </AccordionOuterWrapper>
    </>
  );
};

export default ProvidersTab;
