import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EndOfServiceRewardResult from "./EndOfServiceRewardResult";
import EndOfServiceRewardCalc from "./EndOfServiceRewardCalc";
import { mockedData } from "./mockedData";

import "./EndOfServiceReward.scss";

const EndOfServiceReward = () => {
  const [calcVisible, setCalcVisible] = useState(true);
  const [resultData, setResultData] = useState(null);
  const { t } = useTranslation("translation", {
    keyPrefix: "endOfServicePage",
  });

  useEffect(() => {
    if (resultData) {
      setCalcVisible(false);
    }
  }, [resultData]);

  const onReturn = () => {
    setCalcVisible(true);
    setResultData(null);
  };

  return (
    <div
      className="end-of-service-reward"
      data-testid="end-of-service-reward-test"
    >
      <div className="end-of-service-reward__content">
        <div className="end-of-service-reward__text-content">
          <h2 className="end-of-service-reward__title">
            {t(mockedData.title)}
          </h2>
          <p className="end-of-service-reward__description">
            {t(mockedData.descriptionFirstPart)}
          </p>
          <p className="end-of-service-reward__description">
            {t(mockedData.descriptionSecondPart)}
          </p>
        </div>
        {calcVisible && <EndOfServiceRewardCalc setData={setResultData} />}
        {!calcVisible && (
          <EndOfServiceRewardResult data={resultData} onReturn={onReturn} />
        )}
      </div>
    </div>
  );
};

export default EndOfServiceReward;
