// BeneficiarySelection.tsx
import React, { useState, useRef, useEffect } from 'react';
import { CheckboxGroup, Checkbox } from "@takamol/qiwa-design-system/components";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Icon from "../../Icon/Icon";

interface BeneficiaryOption {
  raw_value: string;
  values: {
    value: string;
    count: number;
  };
}

interface BeneficiarySelectionProps {
  beneficiaries: BeneficiaryOption[];
  selectedBeneficiaries: string[];
  onChange: (selectedBeneficiaries: string[]) => void;
  isDisabled: boolean;
}

const BeneficiarySelection: React.FC<BeneficiarySelectionProps> = ({
  beneficiaries,
  selectedBeneficiaries,
  onChange,
  isDisabled,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "laborEducation.filter",
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className="filter__section">
      <p className="filter__label">{t("filterByBeneficiaries")}</p>
      <div className="filter__dropdown" ref={dropdownRef}>
        <button
          className={`filter__dropdown__header ${isDisabled ? "disabled" : ""}`}
          onClick={() => {
            if (!isDisabled) {
              setDropdownOpen(!dropdownOpen);
            }
          }}
        >
          <span>
            {selectedBeneficiaries.length > 0 ? selectedBeneficiaries.join(", ") : t("select")}
          </span>
          <Icon
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            name={dropdownOpen ? "chevronUp" : "chevronDown"}
          />
        </button>
        {dropdownOpen && (
          <div className="filter__dropdown__content">
            <CheckboxGroup
              name="beneficiaries"
              value={selectedBeneficiaries}
              onChange={onChange}
              isDisabled={isDisabled}
            >
              {beneficiaries.map((beneficiary) => (
                <Checkbox
                  id={beneficiary.raw_value}
                  key={beneficiary.raw_value}
                  value={beneficiary.raw_value}
                  label={
                    <span
                      className={classnames(
                        "filter__dropdown__content__beneficiary-label",
                        beneficiary.values.count === 0 &&
                          "filter__dropdown__content__beneficiary-label--disabled"
                      )}
                    >
                      <span className="filter__dropdown__content__beneficiary-value">
                        {beneficiary.values.value}
                      </span>
                      <span className="filter__dropdown__content__beneficiary-count">
                        {beneficiary.values.count}
                      </span>
                    </span>
                  }
                />
              ))}
            </CheckboxGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default BeneficiarySelection;
