import axios from "axios";

export const fetchErrorData = async (errorCode) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_FULL_URL}/jsonapi/config_pages/${errorCode}`
    );
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return await error;
  }
};
