import { useTranslation } from "react-i18next";

function useDynamicTranslation(defaultPrefix = "") {
  const { t } = useTranslation();

  function dynamicTranslate(key, prefix) {
    const prefixedKey = prefix
      ? `${prefix}.${key}`
      : defaultPrefix
      ? `${defaultPrefix}.${key}`
      : key;
    return t(prefixedKey);
  }

  return dynamicTranslate;
}

export default useDynamicTranslation;
