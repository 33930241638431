import React from "react";
import ActionTilesGroup from "../../../ActionTilesGroup/ActionTilesGroup";

const FilteredTiles = ({ tiles, categoryFilters }) => {
  return tiles.map((tileContent, index) => {
    let cards = tileContent.items;

    if (
      tileContent.title === "Labor Education" ||
      tileContent.title === "الثقافة العمالية"
    ) {
      cards = cards.filter((item) =>
        categoryFilters.includes(item.category)
      );
    }

    return (
      <ActionTilesGroup
        key={index}
        title={tileContent.title}
        titleLink={true}
        description={tileContent.subtitle}
        cards={cards.slice(0, 3)}
        groupCta={tileContent.allArticlesLink}
        useAccordion={false}
      />
    );
  });
};

export default FilteredTiles;
