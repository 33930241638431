import React, { useState, useRef, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../../Loader/Loader";
import { MainContext } from "../../contexts/context";
import { mockedData } from "../mockedData";
import Icon from "../../Icon/Icon";
import { 
  Select,
  NumericField,
  Field,
  Accordion,
  Button,
  Message,
  Box,
  Text,
  ToastStacking,
  useToastMethodsContext
} from "@takamol/qiwa-design-system/components";

import "./NitaqatCalcForm.scss";

const NitaqatCalcForm = ({
  checkCompare,
  setCheckCompare,
  setDisplayResult,
  setNitaqatCalcResult,
}) => {
  const currentEmployeeMessageRef = useRef(null);
  const expectedEmployeeMessageRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(0);
  const { t } = useTranslation("translation", {
    keyPrefix: "nitaqatCalc.nitaqatCalcForm",
  });
  const { theme } = useContext(MainContext);
  const {
    register,
    handleSubmit,
    resetField,
    clearErrors,
    formState,
    getValues,
    control,
    setError,
  } = useForm();
  const [totalCurrentEmployees, setTotalCurrentEmployees] = useState(0);
  const [totalExpectedEmployees, setTotalExpectedEmployees] = useState(0);
  const [economicSectorValue, setEconomicSectorValue] = useState("");
  const [economicActivityId, setEconomicActivityId] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState({});
  const [subEconomicActivityId, setSubEconomicActivityId] = useState(0);
  const [checkTotalCurrentEmployees, setCheckTotalCurrentEmployees] =
    useState(false);
  const [checkTotalExpectedEmployees, setCheckTotalExpectedEmployees] =
    useState(false);
  const [economicActivityList, setEconomicActivityList] = useState([]);
  const [economicActivityDropdownList, setEconomicActivityDropdownList] =
    useState([]);
  const [defaultError, setDefaultError] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [loadingActivities, setLoadingActivities] = useState(false);

  useEffect(() => {
    if (recaptchaRef) {
      recaptchaRef.current.captcha.style = "margin-bottom: 24px;";
    }
  }, []);

  useEffect(() => {
    fetchEconomicActivityList();
  }, []);
  const handleAccordionChange = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const fetchEconomicActivityList = async () => {
    setLoadingActivities(true);
    try {
      await axios
        .get(`${process.env.REACT_APP_FULL_URL}/api/v1/nitaqat?_format=json`)
        .then((response) => {
          if (response.data) {
            const excludeIds = [
              "954",
              "955",
              "782002",
              "782001",
              "510",
              "410029",
              "1500",
              "931215",
              "956",
              "970001",
              "110",
              "16400",
              "782003",
              "701011",
            ];
            const values = Object.values(response.data).filter((item) => {
              return !excludeIds.includes(item.sub_activity_id);
            });
            setEconomicActivityList(values);
            setEconomicActivityDropdownList(
              values.map((economicActivity) => {
                return {
                  option: economicActivity.sub_economic_activity,
                  value: economicActivity.sub_activity_id,
                };
              })
            );
            setLoadingActivities(false);
          } else {
            console.log(response);
            setLoadingActivities(false);
          }
        });
    } catch {
      setLoadingActivities(false);
    }
  };

  const onInputChange = (inputValue) => {
    setSearchInput(inputValue);
    if (inputValue === "") {
      setSelectedActivity({}); // Assuming this is how you reset the dropdown
    }
  };

  const submitNitacatCalc = async (
    currentNumberOfSaudis,
    currentNumberOfNonSaudis,
    expectedNumberOfSaudis,
    expectedNumberOfNonSaudis,
    economicActivityId,
    subEconomicActivityId
  ) => {
    try {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_FULL_URL}/api/v1/nitaqat?_format=json`,
          checkCompare
            ? {
                SaudisCount: currentNumberOfSaudis,
                NonSaudisCount: currentNumberOfNonSaudis,
                NitaqActivityId: economicActivityId,
                compare: checkCompare,
                Isic4Code: subEconomicActivityId,
                ExpectedSaudisCount: expectedNumberOfSaudis,
                ExpectedNonSaudisCount: expectedNumberOfNonSaudis,
              }
            : {
                SaudisCount: currentNumberOfSaudis,
                NonSaudisCount: currentNumberOfNonSaudis,
                NitaqActivityId: economicActivityId,
                Isic4Code: subEconomicActivityId,
                compare: checkCompare,
              },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            if (response.data) {
              setNitaqatCalcResult(response.data);
              setLoading(false);
              setTotalCurrentEmployees(0);
              setTotalExpectedEmployees(0);
              setDisplayResult(true);
            }
          }
        });
    } catch (err) {
      setDefaultError(t("defaultError"));
      setLoading(false);
    }
  };

  useEffect(() => {
    resetField("expectedNumberOfSaudis");
    resetField("expectedNumberOfNonSaudis");
  }, [checkCompare]);

  useEffect(() => {
    if ((checkTotalCurrentEmployees && totalCurrentEmployees <= 5) || (checkTotalCurrentEmployees && totalCurrentEmployees > 50000)) {
      currentEmployeeMessageRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
      return;
    }
    if (checkCompare && ((checkTotalExpectedEmployees && totalExpectedEmployees <= 5) || (checkTotalExpectedEmployees && totalExpectedEmployees > 50000))) {
      expectedEmployeeMessageRef.current.scrollIntoView({ behavior: 'smooth', block: "center" })
    }
  }, [checkTotalCurrentEmployees, checkTotalExpectedEmployees])

  const onSubmit = (data) => {
    const {
      currentNumberOfSaudis,
      currentNumberOfNonSaudis,
      expectedNumberOfSaudis,
      expectedNumberOfNonSaudis,
    } = data;

    const totalCurrentEmployees =
      +currentNumberOfSaudis + +currentNumberOfNonSaudis;

    const totalExpectedEmployees =
      expectedNumberOfSaudis + expectedNumberOfNonSaudis || 0;

    setTotalCurrentEmployees(totalCurrentEmployees);
    setTotalExpectedEmployees(totalExpectedEmployees);

    if (totalCurrentEmployees <= 5 || totalCurrentEmployees > 50000)
      setCheckTotalCurrentEmployees(true);
    else setCheckTotalCurrentEmployees(false);

    if (totalExpectedEmployees <= 5 || totalExpectedEmployees > 50000)
      setCheckTotalExpectedEmployees(true);
    else setCheckTotalExpectedEmployees(false);

    if (
      totalCurrentEmployees > 5 &&
      totalCurrentEmployees <= 50000 &&
      (!checkCompare ||
        (checkCompare &&
          totalExpectedEmployees > 5 &&
          totalExpectedEmployees <= 50000))
    ) {

      if (
        recaptchaRef.current.getValue() &&
        recaptchaRef.current.getValue().length
      ) {
        submitNitacatCalc(
          +currentNumberOfSaudis,
          +currentNumberOfNonSaudis,
          +expectedNumberOfSaudis,
          +expectedNumberOfNonSaudis,
          +economicActivityId,
          +subEconomicActivityId
        );
      } else {
        setError("captchaError", {
          type: "custom",
          message: t("errors.captchaError"),
        });
        setLoading(false);
      }
    }
  };

  const onErrors = (errors) => {
    if (
      errors.captchaError &&
      recaptchaRef.current.getValue() &&
      recaptchaRef.current.getValue().length
    ) {
      clearErrors("captchaError");
      onSubmit(getValues());
    }
  };

  const validatePositiveInteger = (value) => {
    return parseInt(value, 10) > 0;
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    const isValidKey =
      /^\d$/.test(key) ||
      key === "Backspace" ||
      key === "Delete" ||
      key === "Tab";

    if (!isValidKey) {
      event.preventDefault();
    }
  };

  const handleSubeconomicActivityChange = (activityId) => {
    if (!economicActivityList.length) return;
    const economicActivity = activityId;
    if (economicActivity) clearErrors("subeconomicActivity");

    const currentEconomicActivity = economicActivityList.find(
      (economicActivitiy) =>
        economicActivitiy.sub_activity_id === economicActivity
    );
    if (!currentEconomicActivity) return;

    setSelectedActivity({
      option: currentEconomicActivity.sub_economic_activity,
      value: currentEconomicActivity.sub_activity_id,
    });
    setSubEconomicActivityId(currentEconomicActivity.sub_activity_id);
    setEconomicSectorValue(currentEconomicActivity.economic_activity);
    setEconomicActivityId(+currentEconomicActivity.economic_activity_id);
  };

  const onEmployeeChange = (e) => {
    const currentNumberOfNonSaudis = getValues("currentNumberOfNonSaudis")
      ? getValues("currentNumberOfNonSaudis")
      : 0;
    const currentNumberOfSaudis = getValues("currentNumberOfSaudis")
      ? getValues("currentNumberOfSaudis")
      : 0;
    const expectedNumberOfNonSaudis = getValues("expectedNumberOfNonSaudis")
      ? getValues("expectedNumberOfNonSaudis")
      : 0;
    const expectedNumberOfSaudis = getValues("expectedNumberOfSaudis")
      ? getValues("expectedNumberOfSaudis")
      : 0;

    setTotalCurrentEmployees(currentNumberOfNonSaudis + currentNumberOfSaudis);
    setTotalExpectedEmployees(
      expectedNumberOfNonSaudis + expectedNumberOfSaudis
    );
  };

  return (
    <div className="calc-wrapper">
      <div
        className={classnames({
          "calc-content": true,
          "calc-content--rtl": theme === "arabic",
        })}
        data-testid="nitaqat-calculator-calc-test"
      >
        {loading && (
          <div className="nitaqat-calculator__loader">
            <Loader />
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <Form.Group className="calc-content__form mb-4">
            <Form.Label
              data-testid="subeconomic-activity-label"
              htmlFor="calc-content-select"
            >
              {t("subeconomicActivity")}
            </Form.Label>

            <Controller
              name="subeconomicActivity"
              rules={{ required: t("required") }}
              control={control}
              render={({
                field: { onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <Select
                  ref={ref}
                  id="calc-content-select"
                  data-testid="nitaqat-calculator-select"
                  variant="business"
                  errorMessage={error?.message}
                  isError={error}
                  options={economicActivityDropdownList}
                  placeholder={t("select")}
                  maxInputWidth={"100%"}
                  isWithFilter
                  onChange={(newValue) => {
                    onChange(newValue);
                    handleSubeconomicActivityChange(newValue);
                  }}
                  onBlur={onBlur}
                  value={selectedActivity.value || ""}
                  onInputChange={onInputChange}
                  filterInputValue={searchInput}
                  areOptionsLoading={loadingActivities}
                />
              )}
            />

            {economicSectorValue && (
              <p className="calc-content__form__info">
                <span>{t("economicSectorLabel")}</span>
                <span>{economicSectorValue}</span>
              </p>
            )}
          </Form.Group>

          <Form.Group className="calc-content__form mb-4" ref={currentEmployeeMessageRef}>
            <Message
              variant={(checkTotalCurrentEmployees && totalCurrentEmployees <= 5) || (checkTotalCurrentEmployees && totalCurrentEmployees > 50000) ? "danger" : "info"}
            >
              <Box>
                <Text variant="body-m">
                {totalCurrentEmployees <= 50000 && t("totalNumberOfEmployeesLessThan5")}
                {totalCurrentEmployees > 50000 && t("totalNumberOfEmployeesMoreThan60k")}
                </Text>
              </Box>
            </Message>
            <div className="calc-content__flex">
              <Form.Group className="calc-content__form">
                <Form.Label
                  data-testid="current-number-of-saudis-label"
                  className={formState.errors.currentNumberOfNonSaudis && !formState.errors.currentNumberOfSaudis && 'calc-content__form__label--aligned'}
                  htmlFor="number-of-saudis"
                >
                  {t("currentNumberOfSaudis")}
                </Form.Label>
                <NumericField
                  data-testid="number-of-saudis-test-id"
                  ariaLabel="Numeric Field"
                  id="number-of-saudis"
                  maxInputWidth={"100%"}
                  fieldWidth={'100%'}
                  isError={formState.errors.currentNumberOfSaudis || ((checkTotalCurrentEmployees && totalCurrentEmployees <= 5) || (checkTotalCurrentEmployees && totalCurrentEmployees > 50000))}
                  errorMessage={formState.errors.currentNumberOfSaudis ? t("required") : ''}
                  min={0}
                  {...register("currentNumberOfSaudis", {
                    required: true,
                    valueAsNumber: true,
                    validate: validatePositiveInteger,
                    onChange: (e) => onEmployeeChange(e),
                  })}
                  type="number"
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
              <Form.Group className="calc-content__form">
                <Form.Label
                  data-testid="number-of-nonsaudis-label"
                  className={classnames(
                    "calc-content__form__label",
                    !formState.errors.currentNumberOfNonSaudis && formState.errors.currentNumberOfSaudis && 'calc-content__form__label--aligned'
                    )}
                  htmlFor="number-of-nonsaudis"
                >
                  {t("currentNumberOfNonSaudis")}
                </Form.Label>
                <NumericField
                  ariaLabel="Numeric Field"
                  id="number-of-nonsaudis"
                  maxInputWidth={"100%"}
                  min={0}
                  fieldWidth={'100%'}
                  isError={formState.errors.currentNumberOfNonSaudis || ((checkTotalCurrentEmployees && totalCurrentEmployees <= 5) || (checkTotalCurrentEmployees && totalCurrentEmployees > 50000))}
                  errorMessage={formState.errors.currentNumberOfNonSaudis ? t("required") : ''}
                  {...register("currentNumberOfNonSaudis", {
                    required: true,
                    valueAsNumber: true,
                    validate: validatePositiveInteger,
                    onChange: (e) => onEmployeeChange(e),
                  })}
                  type="number"
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
            </div>
            {totalCurrentEmployees > 0 && (
              <p
                className="calc-content__form__info"
                dangerouslySetInnerHTML={{
                  __html: t("totalNumberOfCurrentEmployees", {
                    interpolation: { escapeValue: false },
                    totalCurrentEmployees
                  }),
                }}
              ></p>
            )}
          </Form.Group>
          <Form.Group className="calc-content__form mb-4">
            <Form.Check
              data-testid="compare-checkbox"
              className="calc-content__form__checkbox"
              checked={checkCompare}
              id="compare-checkbox"
              type="checkbox"
              label={
                <span data-testid="compare-label" htmlFor="compare-checkbox">
                  {t("compareLabel")}
                </span>
              }
              onChange={() => setCheckCompare((prev) => !prev)}
            />
          </Form.Group>
          {checkCompare && (
            <Form.Group className="calc-content__form mb-4" ref={expectedEmployeeMessageRef}>
              {checkTotalExpectedEmployees && (totalExpectedEmployees <= 5 || totalExpectedEmployees > 50000) && <Message
                variant="danger"
              >
                <Box>
                  <Text variant="body-m">
                  {totalExpectedEmployees <= 50000 && t("totalNumberOfEmployeesLessThan5")}
                  {totalExpectedEmployees > 50000 && t("totalNumberOfEmployeesMoreThan60k")}
                  </Text>
                </Box>
              </Message>}
              <div className="calc-content__flex">
                <Form.Group className="calc-content__form">
                  <Form.Label
                    htmlFor="exp-number-of-saudis"
                    className={formState.errors.expectedNumberOfNonSaudis && !formState.errors.expectedNumberOfSaudis && 'calc-content__form__label--aligned'}
                  >
                    {t("expectedNumberOfSaudis")}
                  </Form.Label>
                  <NumericField
                    ariaLabel="Numeric Field"
                    id="exp-number-of-saudis"
                    maxInputWidth={"100%"}
                    fieldWidth={'100%'}
                    min={0}
                    isError={formState.errors.expectedNumberOfSaudis || ((checkTotalExpectedEmployees && totalExpectedEmployees <= 5) || (checkTotalExpectedEmployees && totalExpectedEmployees > 50000))}
                    errorMessage={formState.errors.expectedNumberOfSaudis ? t("required") : ''}
                    {...register("expectedNumberOfSaudis", {
                      required: true,
                      valueAsNumber: true,
                      validate: validatePositiveInteger,
                      onChange: (e) => onEmployeeChange(e),
                    })}
                    type="number"
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="calc-content__form">
                  <Form.Label
                    htmlFor="exp-number-of-non-saudis"
                    className={!formState.errors.expectedNumberOfNonSaudis && formState.errors.expectedNumberOfSaudis && 'calc-content__form__label--aligned'}
                  >
                    {t("expectedNumberOfNonSaudis")}
                  </Form.Label>
                  <NumericField
                    ariaLabel="Numeric Field"
                    id="exp-number-of-non-saudis"
                    maxInputWidth={"100%"}
                    fieldWidth={'100%'}
                    min={0}
                    isError={formState.errors.expectedNumberOfNonSaudis || ((checkTotalExpectedEmployees && totalExpectedEmployees <= 5) || (checkTotalExpectedEmployees && totalExpectedEmployees > 50000))}
                    errorMessage={formState.errors.expectedNumberOfNonSaudis ? t("required") : ''}
                    {...register("expectedNumberOfNonSaudis", {
                      required: true,
                      valueAsNumber: true,
                      validate: validatePositiveInteger,
                      onChange: (e) => onEmployeeChange(e),
                    })}
                    type="number"
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
              </div>
              {totalExpectedEmployees > 0 && (
                <p
                  className="calc-content__form__info"
                  dangerouslySetInnerHTML={{
                    __html: t("totalNumberOfExpectedEmployees", {
                      interpolation: { escapeValue: false },
                      totalExpectedEmployees
                    }),
                  }}
                ></p>
              )}
            </Form.Group>
          )}
          {formState.errors.captchaError && (
            <p className="calc-content--required">
              <Icon name="info" height="20" width="20"/>
              {t("required")}
            </p>
          )}
          <ReCAPTCHA
            sitekey="6Ld_4nYdAAAAAMpoTic6J_BuaYLtT8GSb0zaIS9L" // from .env file on BE side
            ref={recaptchaRef}
          />
          {defaultError && (
            <p className="calc-content--required">{defaultError}</p>
          )}
          <Button
            variant="business_primary_filled"
            type="submit"
            data-testid="nitaqat-calculator-submit"
          >
            {t("calculateNitaqatLevel")}
          </Button>
        </Form>
      </div>

      <Accordion className="nitaqat-accordions" shouldAutoScrollOnOpen={false} iconPlacement="end" hasBorderBottom={false}>
        {mockedData.accordionData.map((accordion) => (
          <Accordion.Item isOpened={openIndex == accordion.key} key={accordion.key} eventKey={accordion.key} onClick={() => handleAccordionChange(accordion.key)} id={'accordion-1-' + accordion.key }>
            <Accordion.Header>{t(accordion.title)}</Accordion.Header>
            <Accordion.Content>
              <div className="nitaqat-accordion-content-wrapper">
                {accordion.descriptions.map((description, index) => (
                  <p key={index}>{t(description)}</p>
                ))}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

NitaqatCalcForm.propTypes = {
  checkCompare: PropTypes.bool,
  setCheckCompare: PropTypes.func,
  setDisplayResult: PropTypes.func,
  setNitaqatCalcResult: PropTypes.func,
};

NitaqatCalcForm.defaultProps = {
  setDisplayResult: () => {
    /** */
  },
  setNitaqatCalcResult: () => {
    /** */
  },
};

export default NitaqatCalcForm;
