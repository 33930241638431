import { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { Button } from "react-bootstrap";
import "./cookies.scss";
import { MainContext } from "../components/contexts/context";
import classNames from "classnames";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
function CookieConsent() {
  const [show, setShow] = useState<boolean>(false);
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "cookies",
  });

  useEffect(() => {
    const decision = Cookies.get("cookieDecision");

    if (!decision) {
      setShow(true);
    }
  }, []);

  const handleDecision = (decision: string) => {
    Cookies.set("cookieDecision", decision, { expires: 365 });

    setShow(false);
  };

  return (
    show && (
      <div
        className={classNames({
          "cookie-consent": true,
          "cookie-consent--rtl": theme !== "english",
        })}
      >
        <div className="cookie-consent__content">
          <p className="cookie-consent__header">{t("title")}</p>
          <p className="cookie-consent__paragraph">
            <Trans i18nKey="cookies.content">
              We use cookies to deliver the best possible experience on our
              website. To learn more, visit our{" "}
              <a href={`/${localStorage.getItem("i18nextLng")}/privacy-policy`}>Privacy Policy</a>. By continuing to use
              this site, you consent to our use of cookies.
            </Trans>
          </p>
        </div>
        <div className="cookie-consent__button-container">
          <Button
            className="cookie-consent__button"
            onClick={() => handleDecision("accept")}
          >
            {t("acceptAll")}
          </Button>
        </div>
      </div>
    )
  );
}

export default CookieConsent;
