import React, { useContext } from "react";
import "./Download.scss";
import Icon from "../../../../Icon/Icon";
import { MainContext } from "../../../../contexts/context";
import classnames from "classnames";

const FileDownloadCard = ({ data }) => {
  const contextValue = useContext(MainContext);
  const { theme } = contextValue;
  const handleDownload = () => {
    window.open(data, "_pdf");
  };
  const fileName = data.substring(data.lastIndexOf('/') + 1);
  return (
    <div
      className={classnames({
        "download mb-5": true,
        "download--ltr mb-5": theme === "english",
        "download--rtl mb-5": theme !== "english",
      })}
    >
      <div className="download__header">
        <div className="download__icon-container">
          <Icon
            name="document"
            viewBox="0 0 32 32"
            width="24px"
            height="24px"
          />
        </div>
        <span
          className={classnames({
            "download__file-name": true,
            "download__file-name--ltr": theme === "english",
            "download__file-name--rtl": theme !== "english",
          })}
        >
          {fileName}
        </span>
      </div>
      <div className="download__button">
        <button className="download__button-container" onClick={handleDownload}>
          <Icon
            name="download"
            viewBox="0 0 32 32"
            width="32px"
            height="32px"
          />
          <span className="download__button-label">Download instruction</span>
        </button>
      </div>
    </div>
  );
};

export default FileDownloadCard;
