import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./LaborEducationArticleHero.scss";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Link from "../../../Link/Link";
import { MainContext } from "../../../contexts/context";
import classNames from "classnames";
import UserDetailsCard from "./UserDetailsCard";

interface BreadcrumbItem {
  url: string;
  title: string;
}

interface LaborEducationArticleHeroProps {
  data: {
    title: string;
    txt: string;
    userType: string;
    beneficiary: string;
    tags: string[];
  };
  breadcrumbs: BreadcrumbItem[];
  saudiLaborSystem: boolean;
}

const LaborEducationArticleHero: React.FC<LaborEducationArticleHeroProps> = ({
  data,
  breadcrumbs,
  saudiLaborSystem,
}) => {
  const { theme } = React.useContext(MainContext);

  if (!data) {
    return null;
  }

  return (
    <div
      className={classNames({
        "labor-education-article-hero": true,
        "labor-education-article-hero--rtl": theme !== "english",
      })}
      data-testid="labor-education-article-hero"
    >
      <Container>
        <Row>
          {/* Left Column - Hero Text */}
          <Col lg={8}>
            <Breadcrumbs theme="light" Breadcrumbs={breadcrumbs}>
              {saudiLaborSystem ? (
                <Link
                  to="/labor-law"
                  label={theme === "english" ? "Labor Law" : "نظام العمل"}
                />
              ) : (
                breadcrumbs
                  ?.slice(1, -1)
                  .map((item, index) => (
                    <Link key={index} to={item?.url} label={item?.title} />
                  ))
              )}
              {breadcrumbs?.length > 0 && (
                <span>{breadcrumbs[breadcrumbs.length - 1]?.title}</span>
              )}
            </Breadcrumbs>
            <div className="labor-education-article-hero__headline">
              {data?.title}
            </div>
            <div className="journey-article-hero__text">{data?.txt}</div>
          </Col>

          <Col lg={4}>{data.userType && <UserDetailsCard data={data} />}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default LaborEducationArticleHero;
