import React from "react";
import IconsLibrary from "./IconsLibrary";

const Icon = ({ name, className, ...props }: Props): React.ReactNode => {
  return (
    <svg
      {...props}
      className={"icon " + className}
      focusable="false"
      data-name={name}
      role="svg"
      data-testid={props.testId ? props.testId : "svg-icon-test"}
    >
      {IconsLibrary(name)}
    </svg>
  );
};

type Props = {
  className?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
  name: string;
  id?: string;
  testId?: string;
};

Icon.defaultProps = {
  className: "",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "currentColor",
};

export default Icon;
