import React from "react";
import "./Button.scss";
import { isEmpty } from "lodash";
import PropTypes from 'prop-types';

const ButtonComponent = ({ button_text, button_link, className }) => {
  if(isEmpty(button_text)) return null;

  const handleClick = () => {
    let lang = localStorage.getItem("i18nextLng");
    let prefix = button_link.startsWith('/') ? `/${lang}` : '';
    window.location.href = `${prefix}${button_link}`;
  };

  return (
    <button className={`button ${className}`} onClick={handleClick}>
      {button_text}
    </button>
  );
};

ButtonComponent.propTypes = {
  button_text: PropTypes.string, 
  button_link: PropTypes.string,
  className: PropTypes.string 
};

export default ButtonComponent;
