import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";

export const routeTitles = {
  "/en": "Qiwa",
  "/ar": "Qiwa",
  "/en/explore-qiwa": "Explore Qiwa",
  "/ar/explore-qiwa": "اكتشف قوى",
  "/en/terms-and-conditions" : "Terms of Use",
  "/ar/terms-and-conditions" : "شروط الاستخدام",
  "/en/contact" : "Contact",
  "/ar/contact" : "تواصل معنا",
  "/en/privacy-policy" : "Privacy Policy",
  "/ar/privacy-policy" : "سياسات الخصوصية",
  "/en/labor-law" : "Labor Law",
  "/en/tools-and-calculators/employees-skills-classification" : "Employees Skills Classification",
  "/ar/tools-and-calculators/employees-skills-classification" : "تصنيف المهارات المهنية",
  "/ar/labor-law" : "نظام العمل",
  "/en/tools-and-calculators" : "Tools and Calculators",
  "/ar/tools-and-calculators" : "الأدوات والحاسبات",
  "/en/tools-and-calculators/certificate" : "Certificate Validation",
  "/ar/tools-and-calculators/certificate" : "التحقق من الشهادات",
  "/en/tools-and-calculators/end-of-service-reward-calculator" : "End of Service Reward Calculator",
  "/ar/tools-and-calculators/end-of-service-reward-calculator" : "حاسبة مكافأة نهاية الخدمة",
  "/en/tools-and-calculators/linking-occupations-to-activities" : "Linking Occupations to Nitaqat Activities",
  "/ar/tools-and-calculators/linking-occupations-to-activities" : "المهن الغير مرتبطة",
  "/en/tools-and-calculators/work-permit-calculator" : "Work Permit Calculator",
  "/ar/tools-and-calculators/work-permit-calculator" : "حاسبة رخصة العمل",
  "/en/tools-and-calculators/nitaqat-calculator" : "Nitaqat Calculator",
  "/ar/tools-and-calculators/nitaqat-calculator" : "حاسبة نطاقات",
  "/en/service-level-agreement" : "Service Level Agreement",
  "/ar/service-level-agreement" : "اتفاقية مستوى الخدمة",
  "/en/search" : "Search",
  "/ar/search" : "نتائج البحث",
  "/en/customer-video-calling-service" : "Qiwa Customer Video Calling Service",
  "/ar/customer-video-calling-service" : "خدمة مكالمات الفيديو من قوى",
};

function TitleSetter() {

  const location = useLocation();
  const pageTitle = routeTitles[location.pathname] || "Qiwa";

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle + " | Qiwa"} />
    </Helmet>
  );
}

export default TitleSetter;
