import React, { useState, useRef, useEffect, useContext } from "react";
import { Toast } from "react-bootstrap";
import "./Text.scss";
import Tooltip from "../Tooltip/Tooltip";
import Icon from "../../../../Icon/Icon";
import { createRoot } from "react-dom/client";
import classnames from "classnames";
import { ToastNotification } from "@takamol/qiwa-design-system/components";
import { MainContext } from "../../../../contexts/context";
import IconTooltip from "../IconTooltip/IconTooltip";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const TextComponent = ({ text, className = "", copyText = false }) => {
  const { theme, isMobile } = useContext(MainContext);
  const [showToast, setShowToast] = useState(false);
  const contentRef = useRef(null);
  const { t } = useTranslation("translation", { keyPrefix: "article" });

  useEffect(() => {
    if (copyText && contentRef.current && typeof text === "string") {
      const headingsArray = contentRef.current.querySelectorAll("h1, h2, h3");
      headingsArray.forEach((heading) => {
        if (!heading.querySelector(".text-copy-innerHtml-icon")) {
          const iconEl = document.createElement("span");
          const id = heading.textContent
            .replace(/\s+/g, "-")
            .replace(/؟/g, "-؟")
            .toLowerCase();
          heading.id = id;

          const root = createRoot(iconEl);
          root.render(
            <IconTooltip
              iconClassName="text-copy-innerHtml-icon"
              onClick={() => handleCopy(id)}
            />
          );
          heading.appendChild(iconEl);
        }
      });
    }
  }, [text, copyText, isMobile, t]);

  const rewriteUrlsForEnvironment = (text) => {
    const environment = process.env.REACT_APP_NODE_ENV;
    let baseUrl;

    switch (environment) {
      case "development":
        baseUrl = "https://sso.qiwa.tech";
        break;
      case "stage":
        baseUrl = "https://sso.qiwa.info";
        break;
      case "uat":
        baseUrl = "https://sso.uat.qiwa.info";
        break;
      default:
        baseUrl = "https://sso.qiwa.sa";
        break;
    }

    if (typeof text === "string") {
      return text.replace(/https:\/\/sso\.qiwa\.sa/g, baseUrl);
    }

    if (Array.isArray(text)) {
      return text.map((part) =>
        typeof part === "string"
          ? part.replace(/https:\/\/sso\.qiwa\.sa/g, baseUrl)
          : part
      );
    }

    return text;
  };
  const isExternalLink = (url) => {
    const linkUrl = new URL(url, window.location.origin);
    return linkUrl.hostname !== window.location.hostname;
  };

  const renderText = (originalText) => {
    const adjustedText = rewriteUrlsForEnvironment(originalText);

    if (typeof adjustedText === "string") {
      const parser = new DOMParser();
      const doc = parser.parseFromString(adjustedText, "text/html");

      const links = doc.querySelectorAll("a");
      links.forEach((link) => {
        const href = link.getAttribute("href");
        if (href && isExternalLink(href)) {
          link.setAttribute("target", "_blank");
          link.setAttribute("rel", "noopener noreferrer");
        } else {
          link.removeAttribute("target");
        }
      });

      return (
        <div
          dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}
          ref={contentRef}
        />
      );
    } else if (Array.isArray(adjustedText)) {
      return adjustedText.map((part, index) => {
        if (typeof part === "string") {
          return part;
        } else {
          return (
            <Tooltip key={index} title={index} tooltipText={part.toolTip}>
              <span className="tooltip-container">{part.text}</span>
            </Tooltip>
          );
        }
      });
    }
  };

  const handleCopy = (id) => {
    const urlWithoutHash = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    const urlWithNewHash = `${urlWithoutHash}#${id}`;
    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = urlWithNewHash;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.body.removeChild(textArea);
    } else {
      navigator.clipboard
        .writeText(urlWithNewHash)
        .then(() => {
          setShowToast(true);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  return (
    <div
      className={classnames({
        "text-wrapper": true,
        "text-wrapper--rtl": theme !== "english",
      })}
    >
      <div className={"text-wrapper__text"}>{renderText(text)}</div>

      <Toast
        className="toast-copy-message"
        bg={"success"}
        show={showToast}
        autohide={true}
        onClose={() => setShowToast(false)}
      >
        <ToastNotification
          message={t("copyToastMessage")}
          role="alert"
          closeButtonAriaLabel="close"
          variant="success"
          handleClose={() => setShowToast(false)}
        />
      </Toast>
    </div>
  );
};

TextComponent.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      toolTip: PropTypes.string
    })),
  ]), 
  className: PropTypes.string, 
  copyText: PropTypes.bool 
};

export default TextComponent;
