import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/context";
import InfoBox from "../InfoBox/InfoBox";
import { mockedData } from "../mockedData";

import "./NitaqatCalcResult.scss";

const NitaqatCalcResult = ({
  data,
  title,
  nitaqatLevelTitle,
  className,
  isExpectedVariant,
}) => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", {
    keyPrefix: "nitaqatCalc.nitaqatCalcResult",
  });
  const resultRef = useRef(null);

  const nitaqatLevels = [
    { red: t("red") },
    { "low-green": t("low-green") },
    { "medium-green": t("medium-green") },
    { "high-green": t("high-green") },
    { platinum: t("platinum") },
  ];

  const {
    nitaqatLevel,
    saudizationRate,
    allowedQuota,
    nitaqatList,
    subeconomicActivity,
    economicSector,
    numberOfSaudis,
    numberOfNonSaudis,
  } = data;

  const nitaqatLevelKeys = nitaqatLevels.map((obj) => Object.keys(obj)[0]);
  const levelIndex = nitaqatLevelKeys.indexOf(nitaqatLevel);
  const getRateBoxItemClassName = (index) => {
    if (index <= levelIndex) {
      return classNames(
        "result-content__rate-box__line__item",
        nitaqatLevelKeys[index]
      );
    }

    return "result-content__rate-box__line__item";
  };

  const getLevelBoxItemClassNames = (index) => {
    return classNames("result-content__level-box__item", {
      current:
        (nitaqatLevel === "red" && index === 0) ||
        (nitaqatLevel === "low-green" && index === 1) ||
        (nitaqatLevel === "medium-green" && index === 2) ||
        (nitaqatLevel === "high-green" && index === 3) ||
        (nitaqatLevel === "platinum" && index === 4),
    });
  };

  const getNitaqatValue = (prop) => {
    for (const level of nitaqatLevels) {
      // eslint-disable-next-line no-prototype-builtins
      if (level.hasOwnProperty(prop)) {
        return level[prop];
      }
    }
    return "Not Found";
  };

  useEffect(() => {
    if (!isExpectedVariant) {
      resultRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div
      className={classNames({
        "result-content": true,
        "result-content--rtl": theme === "arabic",
        [className]: className,
      })}
      ref={resultRef}
    >
      <h4 data-testid="result-title-test" className="result-content__title">
        {title}
      </h4>
      <div
        data-testid="nitaqat-calc-result-test"
        className="result-content__rate-box"
      >
        <div className="result-content__rate-box__line">
          {Array.from({ length: 5 }, (_, index) => (
            <div
              data-testid="rate-box-item-test"
              key={index}
              className={getRateBoxItemClassName(index)}
            />
          ))}
        </div>

        <div className="result-content__rate-box__info p2">
          <span className="result-content__rate-box__info__text">
            {t("saudizationRate")}
          </span>
          <span
            data-testid="saudization-rate-test"
            className="result-content__rate-box__info__percent"
          >
            {saudizationRate}%
          </span>
        </div>
        <div className="result-content__rate-box__info p4">
          <span className="result-content__rate-box__info__text">
            {t("currentNitaqat")}
          </span>
          {levelIndex <= mockedData.nitaqatLevelIndex.PLATINUM &&
            levelIndex >= mockedData.nitaqatLevelIndex.RED && (
              <div className="result-content__rate-box__info__level">
                <span
                  className={classNames(
                    "result-content__rate-box__info__level__icon",
                    nitaqatLevels[levelIndex]
                  )}
                ></span>
                <span
                  data-testid="current-nitaqat-level-test"
                  className="result-content__rate-box__info__level__text"
                >
                  {getNitaqatValue(nitaqatLevel)}
                </span>
              </div>
            )}
        </div>
        <div className="result-content__rate-box__info p3">
          <div className="result-content__rate-box__info__text-container">
            <span className="result-content__rate-box__info__text">
              {t("allowedQuota")}
            </span>
            <span className="result-content__rate-box__info__text">
              {title.includes("Current") || title.includes("الحالي")
                ? t("currentBalanceETandVisa")
                : t("expectedBalanceETandVisa")}
            </span>
          </div>

          <span
            data-testid="allowed-quota-test"
            className="result-content__rate-box__info__quote"
          >
            {allowedQuota}
          </span>
        </div>
      </div>
      {levelIndex < mockedData.nitaqatLevelIndex.MEDIUM_GREEN &&
        levelIndex > mockedData.nitaqatLevelIndex.RED && (
          <InfoBox>
            <p className="result-content__note--text">{t("infoBoxNote")}</p>
          </InfoBox>
        )}
      <span className="result-content__level-title">{nitaqatLevelTitle}</span>
      <div className="result-content__level-box">
        {nitaqatList.map((resultItem, index) => (
          <div key={index} className={getLevelBoxItemClassNames(index)}>
            <div className="result-content__level-box__item__level">
              <span
                className={classNames(
                  "result-content__level-box__item__level__icon",
                  resultItem.level
                )}
              ></span>
              <span
                className={classNames({
                  "result-content__level-box__item__level__text": true,
                  light: getLevelBoxItemClassNames(index).includes("current"),
                })}
              >
                {getNitaqatValue(resultItem.level)}
              </span>
            </div>
            <span
              className={classNames({
                "result-content__level-box__item__text": true,
                light: getLevelBoxItemClassNames(index).includes("current"),
              })}
            >
              {resultItem.value}
            </span>
          </div>
        ))}
      </div>

      <div className="result-content__general-info-box">
        <div className="result-content__general-info-box__item">
          <span className="result-content__general-info-box__item__label">
            {t("subeconomicActivity")}
          </span>
          <span className="result-content__general-info-box__item__value">
            {subeconomicActivity}
          </span>
        </div>
        <div className="result-content__general-info-box__item">
          <span className="result-content__general-info-box__item__label">
            {t("economicSector")}
          </span>
          <span className="result-content__general-info-box__item__value">
            {economicSector}
          </span>
        </div>
        <div className="result-content__general-info-box__item">
          <span className="result-content__general-info-box__item__label">
            {t("currentNumberOfSaudis")}
          </span>
          <span className="result-content__general-info-box__item__value">
            {numberOfSaudis}
          </span>
        </div>
        <div className="result-content__general-info-box__item">
          <span className="result-content__general-info-box__item__label">
            {t("currentNumberOfNonSaudis")}
          </span>
          <span className="result-content__general-info-box__item__value">
            {numberOfNonSaudis}
          </span>
        </div>
      </div>
    </div>
  );
};

NitaqatCalcResult.propTypes = {
  title: PropTypes.string,
  nitaqatLevel: PropTypes.string,
  nitaqatLevelTitle: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object,
  isExpectedVariant: PropTypes.bool,
};

NitaqatCalcResult.defaultProps = {
  title: "",
  nitaqatLevel: "",
  nitaqatLevelTitle: "",
  className: "",
  isExpectedVariant: false,
};

export default NitaqatCalcResult;
