import React from 'react'

export default function Contribute() {
    return (
        <div data-testid='contribute-page-test'>
            <h3>
                Contribute Page
            </h3>
        </div>
    )
}
