export const mockedData = {
  accordionData: [
    {
      key: "1",
      title: "entityEconomicActivityInNitaqat",
      descriptions: [`ISIC4`, `Nitaqat`],
    },
    {
      key: "2",
      title: "totalNumberOfEmployeesInTheEntity",
      descriptions: [`saudiEmploymentRegulations`],
    },
  ],
  resultLevelItems: [
    { label: "red", value: "0% - 35.59%" },
    { label: "low-green", value: "36.00% - 47.10%" },
    { label: "medium-green", value: "47.11% - 58.43%" },
    { label: "high-green", value: "58.44% - 61.20%" },
    { label: "platinum", value: "61.21% - 100%" },
  ],
  nitaqatLevelIndex: {
    RED: 0,
    LOW_GREEN: 1,
    MEDIUM_GREEN: 2,
    HIGH_GREEN: 3,
    PLATINUM: 4,
  },
  recommendation: {
    highGreen: [
      {
        key: "1",
        description: "recommendation.highGreen",
        count: 1,
        percent: 56,
      },
      {
        key: "2",
        description: "recommendation.platinum",
        count: 2,
        percent: 78,
      },
    ],
    platinum: [
      {
        key: "1",
        description: "recommendation.platinum",
        count: 2,
        percent: 78,
      },
    ],
  },
};
