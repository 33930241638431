import React, { useState, useEffect, useContext, useRef } from "react";
import Hero from "../../Hero/Hero";
import { Tab, Nav } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { MainContext } from "../../contexts/context";
import BusinessTab from "../Home/HomeTabs/BusinessTab";
import IndividualsTab from "../Home/HomeTabs/IndividualsTab";
import ProvidersTab from "../Home/HomeTabs/ProvidersTab";
import IconCardsGroup from "../../IconCardsGroup/IconCardsGroup";
import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";
import useFetchContent from "../../../hooks/useFetchContent";

const ServicesPage = (): React.ReactElement => {
  const [activeTab, setActiveTab] = useState("businessowners");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showPrevIcon, setShowPrevIcon] = useState(false);
  const [showNextIcon, setShowNextIcon] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { theme, isMobile, isTablet } =
    useContext(MainContext);
  const { t } = useTranslation("translation", { keyPrefix: "homepage" });
  const { contentByType }: { contentByType: any } = useFetchContent();

  const isRtl = theme === "arabic";
  const tabSectionRef: any = useRef(null );

  const handleScroll = () => {
    const tabsList = document.querySelector(".homepage-tabs__tabs-list");
    if (!tabsList) return;
    let isAtStart, isAtEnd;

    if (isRtl) {
      const scrollOffset = Math.max(
        tabsList.scrollWidth - tabsList.clientWidth,
        0
      );
      isAtStart =
        tabsList.scrollLeft === scrollOffset || tabsList.scrollLeft >= 0;
      isAtEnd = tabsList.scrollLeft <= -30;
    } else {
      isAtStart = tabsList.scrollLeft === 0;
      isAtEnd =
        tabsList.scrollWidth - tabsList.scrollLeft === tabsList.clientWidth;
    }

    setShowPrevIcon(!isAtStart);
    setShowNextIcon(!isAtEnd);
  };

  useEffect(() => {
    const tabsList = document.querySelector(".homepage-tabs__tabs-list");
    if (tabsList) {
      tabsList.addEventListener("scroll", handleScroll);
      handleScroll();
    }
    return () => tabsList?.removeEventListener("scroll", handleScroll);
  }, [theme]);

  useEffect(() => {
    if (searchParams.get("persona")) {
      const searchTab = searchParams.get("persona");
      if (window.scrollY > 600) {
        window.scrollTo(0, 500);
      }
      setActiveTab(searchTab ? searchTab : "");
      setActiveTabIndex(tabs.findIndex((t) => t.key === searchTab));
      if (isMobile || isTablet) {
        scrollTabsNav(searchTab);
      }
    } else {
      setActiveTab("businessowners");
      window.scrollTo(0, 0);
      scrollTabsNav("businessowners");
    }
  }, [searchParams]);

  useEffect(() => {
    if (tabSectionRef.current) {
      tabSectionRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, [activeTab]);

  const tabs = [
    {
      key: "businessowners",
      title:
        isMobile || isTablet ? t("business") : t("servicesForBusinessOwners"),
    },
    {
      key: "employees",
      title: isMobile || isTablet ? t("employees") : t("servicesForEmployees"),
    },
    {
      key: "serviceproviders",
      title: isMobile || isTablet ? t("providers") : t("serviceProviders"),
    },
  ];

  const parseTabPanes = (tab: any) => {
    switch (tab) {
      case "businessowners":
        return <BusinessTab />;
      case "employees":
        return <IndividualsTab />;
      case "serviceproviders":
        return <ProvidersTab />;
      default:
        return <BusinessTab />;
    }
  };

  const scrollTabsNav = (tab: any) => {
    const activeNavLink = document.querySelector(
      `.nav-link[data-rr-ui-event-key=${tab}]`
    );
    if (activeNavLink) {
      activeNavLink.scrollIntoView({ block: "nearest", inline: "center" });
    }
  };

  const manageActiveTab = (tab: any, event: any) => {
    if (isTablet) {
      event.target.scrollIntoView({ inline: "end" });
      scrollTabsNav(tab);
    }
    setActiveTabIndex(tabs.findIndex((t) => t.key === tab));
    setSearchParams({ persona: tab });
  };

  return (
    <div className="services-page">
      <Hero data={undefined} />
      <Tab.Container
        id="homepage-tabs"
        activeKey={activeTab}
        onSelect={(tab, event) => manageActiveTab(tab, event)}
      >
        <div
          ref={tabSectionRef}
          className={`${isTablet ? "" : "container"} homepage-tabs-list`}
        >
          <Nav className="homepage-tabs__tabs-list">
            {tabs.map((tab, index) => {
              return (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={tab.key}>{tab.title}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          {showPrevIcon && activeTab !== "businessowners" && (
            <button
              className="homepage-tabs__prev-button"
              onClick={(e) => manageActiveTab(tabs[activeTabIndex - 1].key, e)}
            >
              <Icon
                name={theme === "english" ? "chevron-left" : "chevron-right"}
              />
            </button>
          )}
          {showNextIcon && activeTab !== "serviceproviders" && (
            <button
              className="homepage-tabs__next-button"
              onClick={(e) => manageActiveTab(tabs[activeTabIndex + 1].key, e)}
            >
              <Icon
                name={theme === "english" ? "chevron-right" : "chevron-left"}
              />
            </button>
          )}
        </div>
        <Tab.Content>
          {tabs.map((tab, index) => {
            return (
              <Tab.Pane
                key={index}
                eventKey={tab.key}
                title={tab.title}
                className="homepage-tab-pane"
              >
                {parseTabPanes(tab.key)}
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </Tab.Container>
      <IconCardsGroup groupData={contentByType?.why_qiwa_platform} />
    </div>
  );
};

export default ServicesPage;
