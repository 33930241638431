import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ServiceCategoryPageHero.scss";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import HeroSearchInput from "../../../Search/HeroSearchInput/HeroSearchInput.tsx";

const ServiceCategoryPageHero = ({title}) => {
  return (
    <Container fluid className="service-category-hero">
      <Breadcrumbs theme="light" ><span>{title}</span></Breadcrumbs>
      <Row className="justify-content-center">
          <p className="service-category-hero__heading">{title}</p>
      </Row>
    </Container>
  );
};

export default ServiceCategoryPageHero;
