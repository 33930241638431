import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routeTitles } from '../TitleSetter';

const usePageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    let currentUrl = location.pathname;

    // Directly navigating to '/en' or '/ar' should clear the savedUrls
    if (currentUrl === `/${localStorage.getItem("i18nextLng")}` || currentUrl === `/${localStorage.getItem("i18nextLng")}/search`) {
      sessionStorage.removeItem('savedUrls');
      return; // Stop further execution
    }

    // For other URLs, remove the '/en' or '/ar' prefix before saving
    currentUrl = currentUrl.replace(/^\/(en|ar)/, '');

    // Determine the page title from the current URL with the prefix
    const pageTitle = routeTitles[location.pathname] || "Qiwa"; 
    let savedUrls = JSON.parse(sessionStorage.getItem('savedUrls')) || [];

    // Check if the URL (without prefix) already exists in the storage
    const urlIndex = savedUrls.findIndex(urlObj => urlObj.url === currentUrl);
    if (urlIndex === -1) {
      // If the URL is not found, add it with its title
      savedUrls.push({ url: currentUrl, title: pageTitle });
    } else {
      // If the URL is found, update its title
      savedUrls[urlIndex].title = pageTitle;
      // Remove all URLs that come after this URL in the array
      savedUrls = savedUrls.slice(0, urlIndex + 1);
    }

    // Save the updated URLs list back to session storage
    sessionStorage.setItem('savedUrls', JSON.stringify(savedUrls));
  }, [location.pathname]);

  // No return statement needed as we're not rendering anything
};

export default usePageTracker;
