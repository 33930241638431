import React, { useContext } from "react";
import { NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../Icon/Icon";
import { MainContext } from "../contexts/context";
import { useTranslation } from 'react-i18next';
import { useZendesk } from 'react-use-zendesk';

const LanguageSwitcher = ({ variant }) => {
  const { theme, setTheme, setShowMobileSearch } = useContext(MainContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation("translation", { keyPrefix: "homepage" });
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const parsedTheme = {
    english: "EN",
    arabic: t("AR"),
  };

  const {
    setLocale,
    setCookies
  } = useZendesk();

  const handleLanguageSwitch = (lang) => {
    setTheme(lang);
    document.body.dir = lang === "english" ? "" : "rtl";
    document.body.lang = lang === "english" ? "en" : "ar";
    lang === 'english' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');

    const urlParts = pathname.split('/');
    urlParts[1] = lang === 'english' ? 'en' : 'ar';
    const resultUrl = urlParts.join('/') + search;
    navigate(resultUrl, { replace: true });
    if (pathname.includes('/search')) {
      const resultCleanSearchUrl = urlParts.join('/');
      navigate(resultCleanSearchUrl + '?q=', { replace: true });
    }

    if (localStorage.getItem("i18nextLng") === "en") {
      setCookies(false);
      setCookies(true);
      setLocale('en-US');
    } else {
      setCookies(false);
      setCookies(true);
      setLocale('ar');
    }
  };
  
  return (
    <NavDropdown
      className="language-switcher"
      title={
        <div className="language-switcher__dropdown-label">
          <Icon
            name="globe"
            className={`globe-icon ${
              variant === "light" ? "globe-icon__light" : ""
            }`}
          />
          <span
            className={
              variant === "light"
                ? "language-switcher__dropdown-label__theme"
                : ""
            }
          >
            {parsedTheme[theme]}
          </span>
          <Icon
            name="chevron-down"
            className={`chevron-down-icon ${
              variant === "light" ? "chevron-down-icon__light" : ""
            }`}
          />
        </div>
      }
      data-testid="language-switcher-test"
      renderMenuOnMount={true}
    >
      <NavDropdown.Item
        className={`language-switcher__option ${
          theme === "arabic" ? "language-switcher__option--active" : ""
        }`}
        as={"button"}
        onClick={() => handleLanguageSwitch("arabic")}
      >
        <div>
         <Icon
              name="arabic-flag"
              className="language-switcher__option__flag"
            />
        {t("ARExtra")}
        </div>
        {theme === "arabic" && (
          <span className="language-switcher__active-icon-container">
            <Icon
              name="checkmark"
              height="18"
              width="18"
              className="checkmark-icon"
            />
          </span>
        )}
      </NavDropdown.Item>
       <NavDropdown.Item
        className={`language-switcher__option ${
          theme === "english" ? "language-switcher__option--active" : ""
        }`}
        as={"button"}
        onClick={() => handleLanguageSwitch("english")}
      >
        <div>
        <Icon
              name="britain-flag"
              className="language-switcher__option__flag"
            />
        English (EN)
        </div>
        {theme === "english" && (
          <span className="language-switcher__active-icon-container">
            <Icon
              name="checkmark"
              height="18"
              width="18"
              className="checkmark-icon"
            />
          </span>
        )}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

LanguageSwitcher.defaultProps = {
  variant: "",
};

export default LanguageSwitcher;
