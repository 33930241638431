import React, { useState, useEffect, useContext } from "react";
import MainMenuLogin from "./MainMenuLogin";
import NavigationSearch from "../Search/NavigationSearch/NavigationSearch.tsx";
import LanguageSwitcher from "./LanguageSwitcher";
import MobileMainMenu from "./MobileMainMenu/MobileMainMenu";
import { MainContext } from "../contexts/context";
import Link from "../Link/Link";
import Icon from "../Icon/Icon";
import { Nav, Navbar } from "react-bootstrap";

import "./MainMenu.scss";

function MainMenu({ variant }) {
  const contextValue = useContext(MainContext);
  const { header } = contextValue;
  const [firstLevelLinks, setFirstLevelLinks] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [isShortLabels, setIsShortLabels] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsSticky(false);
      }
      else{
        setIsSticky(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    if (header) {
      let firstLevel = header.filter((item) => item.key);
      setFirstLevelLinks(firstLevel);
    }
  }, [header]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width > 1279 && width < 1340) {
      setIsShortLabels(true);
    } else {
      setIsShortLabels(false);
    }
  }, [width]);

  const shortenMenuLabel = (label) => {
    const allowedLabels = ['For Business Owners', 'For Employees', 'For Service Providers'];
    return allowedLabels.includes(label.trim()) ? label.substring(4) : label;
  }

  return (
    <>
      <Navbar
       className={`main-menu-navbar main-menu-navbar--desktop ${
        variant === "light"
          ? "main-menu-navbar--desktop__light"
          : "main-menu-navbar--desktop__dark"
      } sticky-header ${isSticky ? " sticky-header-visible" : ""}`}
        expand="lg"
        data-testid="main-menu-test"
      >
        <Navbar.Brand>
          <Link
            textLink={false}
            className="text-decoration-none text-white d-block"
            to="/"
          >
            <Icon
              name={variant === "light" ? "logo-light" : "logo"}
              className="d-inline-block align-top"
              viewBox="0 0 66 59"
              width="64"
              height="56"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="main-menu-navbar__left-container">
            {firstLevelLinks.map((firstLevelItem, index) => {
              return (
                <Nav.Item key={index} className="dropdown">
                  <Link
                    textLink={true}
                    underlined={false}
                    variant="white"
                    className={`nav-link dropdown-toggle nav-link--with-underline nav-link--link-container ${
                      variant === "light" ? "nav-link__light" : ""
                    }`}
                    to={firstLevelItem.url}
                  >
                    <div className="main-menu-navbar__dropdown-label">
                      <span className="c__dropdown-label--span false">{(isShortLabels && localStorage.getItem("i18nextLng") === "en") ? shortenMenuLabel(firstLevelItem.key) : firstLevelItem.key}</span>
                    </div>
                  </Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Nav className="main-menu-navbar__right-container main-menu-navbar__right-container__light">
            <NavigationSearch variant={variant} resolution="desktop" />
            <LanguageSwitcher variant={variant} />
            <Nav.Item className="main-menu-login dropdown">
              <MainMenuLogin type="desktop" variant={variant} />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <MobileMainMenu links={firstLevelLinks} />
    </>
  );
}

MainMenu.defaultProps = {
  variant: "",
};

export default MainMenu;
