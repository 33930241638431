import React, { useContext, useEffect, useState } from "react";
import NavCards from "../../../NavCardsGroup/NavCardsGroup";
import { MainContext } from "../../../contexts/context";
import HomePageVideoBanner from "../../../HomePageVideoBanner/HomePageVideoBanner";
import IconCardsGroup from "../../../IconCardsGroup/IconCardsGroup";
import useFetchContent from "../../../../hooks/useFetchContent";

const HomeTab = () => {
  const contextValue = useContext(MainContext);
  const { navCards } = contextValue;
  const { contentByType } = useFetchContent();  

  return (
    <div className="home-tab">
      {contentByType?.qiwa_in_numbers && <IconCardsGroup groupData={contentByType?.qiwa_in_numbers} />}
      <HomePageVideoBanner />
      {navCards && <NavCards cardsData={navCards} />}
    </div>
  );
};

export default HomeTab;
