import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

const MobileToc = ({headings, location}) => {
  const [activeAccordion, setActiveAccordion] = useState('');
  const { t } = useTranslation("translation", {
    keyPrefix: "article",
  });

  useEffect(() => {
    if (location.hash) {
      detectActiveAccordion();
    }
  }, [location, headings])

  const detectActiveAccordion = () => {
    let activeAccordion;
    const activeFirstLevel = headings.find((elem) => '#' + elem.id === location.hash);
    const activeSecondLevel = headings.find((el) => {
      let activeLink = el.subLinks.find((element) => '#' + element.id === location.hash);
      if (activeLink) {
        return el;
      } else return false;
    })
    if (activeFirstLevel) {
      activeAccordion = activeFirstLevel.id;
    } else if (activeSecondLevel) {
      activeAccordion = activeSecondLevel.id;
    } else {
      activeAccordion = '';
    }
    setActiveAccordion(activeAccordion);
  }
  return (
    <div data-testid='mobile-toc-test'>
      <Accordion
        activeKey={activeAccordion}
        onSelect={(key) => setActiveAccordion(key)}>
        {headings.map((h, index) => {
          return (
            <Accordion.Item key={index} eventKey={h.id}>
              <Accordion.Header><h4>{t("tableOfContent")}</h4></Accordion.Header>
              <Accordion.Body>
                {h.subLinks.length > 0 && <ul>
                  {h.subLinks.map((s, i) => {
                    let link = '#' + s.id;
                    return (
                      <li key={i} className={location.hash === link ? 'active-toc' : ''}>
                        <a className={`table-of-contents__second-level-link`} href={link}>
                          {s.text}
                        </a>
                      </li>
                    )
                  })}
                </ul>}
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </div>
  )
}

MobileToc.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string, 
      subLinks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string, 
          text: PropTypes.string, 
        })
      ),
    })
  ),
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

MobileToc.defaultProps = {
  headings: [],
  location: {}
}

export default MobileToc;