import React, { useContext,useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import "./SessionTimeout.scss";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { MainContext } from "../../../contexts/context";
import { fetchErrorData } from "../../../../FetchError.js";

function SessionTimeoutError({code, incidentID}) {
  const navigate = useNavigate();
  const { theme } = useContext(MainContext);
  const [errorData, setErrorData] = useState({});
  const { t } = useTranslation("translation", {
    keyPrefix: "errors.440error",
  });
  useEffect(() => {
    const fetchData = async () => {
      const fetchCode = code === 404 ? "404?include=field_relevant_page" : code;
      const data = await fetchErrorData(fetchCode);
      if(localStorage.getItem("i18nextLng") === "ar") {
        setErrorData(data.data[1]);
      }else{
        setErrorData(data.data[0]);
      }

    };

    fetchData();
  }, [code, localStorage.getItem("i18nextLng")]);
  const { attributes } = errorData;
  const redirectToLoginPage = () => {
    window.location.href = 'https://laborer-sso-auth.qiwa.info/sign-in';
  };

  return (
    <Container
      fluid
      className={classnames({
        "session-timeout-container": true,
        "session-timeout-container--ltr": theme === "english",
        "session-timeout-container--rtl": theme !== "english",
      })}
    >
      <Row>
        <Col className="session-timeout-content">
          <Breadcrumbs />
          <p className="session-timeout-header">{attributes?.field_label}</p>
          <p className="session-timeout-error-code">
            {t("errorCode", { code, incidentID })}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: attributes?.field_description?.value,
            }}
          ></p>
          <button
            className="session-timeout-button"
            onClick={redirectToLoginPage}
          >
            <p className="button-text">{t("button")}</p>
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default SessionTimeoutError;

SessionTimeoutError.defaultProps = {
  code: "440",
  incidentID: "0B003A15",
};