// utils/getSignInLink.js
export const getSignInLink = (env, theme) => {
    const isArabic = theme === "arabic";
  
    let baseUrl;
  
    if (env === "stage") {
      baseUrl = "https://auth.qiwa.info";
    } else if (env === "uat") {
      baseUrl = "https://sso.uat.qiwa.info";
    } else {
      baseUrl = "https://auth.qiwa.sa";
    }
  
    const langPath = isArabic ? "/ar/sign-in" : "/en/sign-in";
  
    return `${baseUrl}${langPath}`;
  };
  