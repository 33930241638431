import React from "react";
import { Divider } from "@takamol/qiwa-design-system/components";
import "./UserDetailsCard.scss";
import Icon from "../../../Icon/Icon";
import { useTranslation } from "react-i18next";

interface UserDetailsCardProps {
  data: {
    userType: string;
    beneficiary: string;
    tags: string[];
  };
}

const UserDetailsCard: React.FC<UserDetailsCardProps> = ({ data }) => {
  const { t } = useTranslation("translation", { keyPrefix: "article" });

  const userDetails = [
    {
      icon: "user",
      label: t("userType"),
      value: data.userType,
      viewBox: "0 0 24 24",
    },
    {
      icon: "salary-certificate", // Static icon for beneficiaries
      label: t("beneficiary"),
      value: Array.isArray(data.beneficiary) ? data.beneficiary.join(", ") : data.beneficiary,
      viewBox: "0 0 32 32",
    },
    {
      icon: "copy-adjustable", // Static icon for tags
      label: t("tags"),
      value: data.tags.join(", "),
      viewBox: "0 0 24 24",
    },
  ];
  

  return (
    <div className="user-details-card">
      {userDetails.map((item, index) => (
        <div key={index} className="user-details-card__row row align-items-center">
          <div className="col-auto">
            <Icon
              className="user-details-card__icon"
              name={item.icon}
              viewBox={item.viewBox}
              width="32px"
              height="32px"
            />
          </div>
          <div className="user-details-card__col col">
            <h4 className="user-details-card__title">{item.label}</h4>
            <p className="user-details-card__content">{item.value}</p>
          </div>
          {index < userDetails.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
};

export default UserDetailsCard;
