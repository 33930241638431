import React from "react";
import Link from "../Link/Link";
import { useTranslation } from "react-i18next";
import { Button } from "@takamol/qiwa-design-system/components";
import PropTypes from 'prop-types';

const MainMenuLogin = ({ type, onClose, variant }) => {

  const storedLanguage = localStorage.getItem("i18nextLng");
  const { t } = useTranslation("translation", {
    keyPrefix: "homepage",
  });
  const getBaseUrl = () => {
    switch (process.env.REACT_APP_NODE_ENV) {
      case "development":
        return "https://sso.qiwa.tech";
      case "stage":
        return "https://sso.qiwa.info";
      case "uat":
        return "https://sso.uat.qiwa.info";
      default:
        return "https://sso.qiwa.sa";
    }
  };

  const baseUrl = getBaseUrl();
  return (
    <div
      data-testid="main-menu-login-test"
      className="nav-link--link-container"
      style={{ height: "100%" }}
    >
      {type === "desktop" && (
        <div
          className={`main-menu-login__desktop-link main-menu-login__group ${
            variant === "light" && "main-menu-login__desktop-link__light"
          }`}
        >
          <div className="main-menu-login__group__register nav-item">
          <Link
            textLink={true}
            variant="white"
            to={`${baseUrl}/${storedLanguage}/sign-in`}
          >
            {t("login")}
          </Link>
          </div>
          <div className="main-menu-login__group__register nav-item">
            <Button
              variant="rounded"
              className="register-button"
              onClick={() =>
                window.open(`${baseUrl}/${storedLanguage}/registration`, "_blank")
              }
            >
             {t("joinQiwa")}
            </Button>
          </div>
        </div>
      )}

      {type !== "desktop" && (
        <ul className="main-menu-login__mobile">
          <li className="main-menu-login__mobile-link main-menu-login__mobile-link--login">
            <Link
              textLink={false}
              to={`${baseUrl}/${storedLanguage}/sign-in`}
              onClick={() => onClose()}
            >
              {t("login")}
            </Link>
          </li>
          <li className="main-menu-login__mobile-link main-menu-login__mobile-link--sign-up">
            <Link
              textLink={false}
              to={`${baseUrl}/${storedLanguage}/registration`}
              onClick={() => onClose()}
            >
              {t("joinQiwa")}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

MainMenuLogin.propTypes = {
  type: PropTypes.oneOf(['desktop', 'mobile']),  
  onClose: PropTypes.func,  
  variant: PropTypes.oneOf(['light', 'dark', '']), 
};

MainMenuLogin.defaultProps = {
  type: "desktop",
  onClose: () => {
    return;
  },
  variant: "",
};

export default MainMenuLogin;
