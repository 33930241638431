export const composeDate = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const result = day + "/" + month + "/" + year;
  return result;
};

export const composeSearchResultDate = (value) => {
  const dateValue = ((value.split(' ')[0]).trim()).split('-');
  const day = dateValue[0];
  const month = dateValue[1];
  const year = dateValue[2];
  const result = day + "." + month + "." + year;
  return result;
}
