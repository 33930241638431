import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import mockedData from "./mockeddata.json";
import mockedArabicData from "./mockedArabicData.json";
import HiringNonSaudiEmployees from "./HiringNonSaudiEmployees";
import "./ServiceCategoryPage.scss";
import ServiceCategoryPageHero from "./ServiceCategoryPageHero";
import { isEmpty, values } from "lodash";
import { useParams } from "react-router-dom";
import NotFoundError from "../../ErrorPages/NotFound/NotFound";
import { MainContext } from "../../../contexts/context";
import { checkLanguageInUrl } from "../../../../utils/checkLanguageInUrl";
import Helmet from "react-helmet";
import { PageLoader } from "../../../Loader/PageLoader";

export default function ServiceCategoryPage() {
  const { theme } = React.useContext(MainContext);
  const { alias } = useParams();
  const [dataSource, setDataSource] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedLanguage = localStorage.getItem("i18nextLng");
        const urlLanguage = checkLanguageInUrl(window.location.pathname);
        const language =
          storedLanguage === urlLanguage ? storedLanguage : urlLanguage;
        let endpoint = `${process.env.REACT_APP_FULL_URL}/api/v1/content?alias=/${alias}`;
        if (language === "ar") {
          endpoint += "&language=ar";
        }
        const response = await fetch(endpoint);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setDataSource(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [theme, alias]);
  if (error) {
    return <NotFoundError code={404} />;
  }
  const data = dataSource;
  const contentValues = values(data?.content ?? {});
  sessionStorage.setItem("pageTitle", data?.articleLink?.title);
  return (
    <Container fluid className="p-0">
      {loading && <PageLoader />}
      <Helmet>
        <title>{data?.articleLink?.title}</title>
        <meta
          property="og:title"
          content={data?.articleLink?.title + " | Qiwa"}
        />
      </Helmet>
      {isEmpty(dataSource) && !loading && <NotFoundError code={404} />}
      <ServiceCategoryPageHero title={data?.articleLink?.title} />
      <Row className="m-0">
        <Col className="service-category-page">
        {contentValues.map((item, index) => (
            <HiringNonSaudiEmployees
              key={index}
              data={item}
              index={index}
              data-testid={`card-${index}`}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
}
