export const qiwaCallOperators = [
  {
    user: "H.alqudemy@qiwa.sa",
    startHour: "3",
    startMinute: "0",
    endHour: "7",
    endMinute: "0",
  },
  {
    user: "Nouf@qiwa.sa",
    startHour: "7",
    startMinute: "0",
    endHour: "15",
    endMinute: "0",
    tillNextDay: false,
  },
  {
    user: "Abdullah@qiwa.sa",
    startHour: "7",
    startMinute: "0",
    endHour: "15",
    endMinute: "0",
    tillNextDay: false,
  },
  {
    user: "a.alqasimi@qiwa.sa",
    startHour: "15",
    startMinute: "0",
    endHour: "23",
    endMinute: "0",
    tillNextDay: false,
  },
  {
    user: "a.aldossary@qiwa.sa",
    startHour: "23",
    startMinute: "0",
    endHour: "3",
    endMinute: "0",
    tillNextDay: true,
  },
];

export const getUsers = () => {
  const currentDate = new Date();
  const users = [];

  qiwaCallOperators.forEach((operator) => {
    const startDate = new Date(currentDate);
    startDate.setHours(operator.startHour, operator.startMinute, 0, 0);

    let endDate = new Date(currentDate);
    endDate.setHours(operator.endHour, operator.endMinute, 0, 0);

    if (operator.tillNextDay || operator.startHour > operator.endHour) {
      // Shift spans across midnight
      const nextDate = new Date(startDate);
      nextDate.setDate(nextDate.getDate() + 1);

      if (operator.startHour > operator.endHour && currentDate <= endDate) {
        // If the shift starts on the previous day and ends on the current day
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(operator.endHour, operator.endMinute, 0, 0);
      } else {
        startDate.setDate(startDate.getDate() - 1);
      }
    }

    if (currentDate >= startDate && currentDate <= endDate) {
      users.push(operator.user);
    }
  });

  return users.join(",");
};
