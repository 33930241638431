import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import ArticleCard from "../ArticleCard/ArticleCard.tsx";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import { MainContext } from "../contexts/context.js";

import './ArticleCardsGroup.scss';
import Link from "../Link/Link.tsx";
import Icon from "../Icon/Icon.tsx";

const ArticleCardsGroup = ({title, description, cards, smallCards, allArticlesLink}) => {  
  const [cardHeight, setCardHeight] = useState(false);
  const { isMobile } = useContext(MainContext);

  useEffect(() => {
    setTimeout(() => {
      const articleCard = document.querySelector('.article-cards-group__cards-row .article-card');
      if (articleCard) {
        setCardHeight(articleCard.offsetHeight);
      }
    }, 1000)
  }, [])
  
  return (
    <div className={`article-cards-group ${smallCards ? 'article-cards-group--small-cards' : ''}`} data-testid="article-cards-group-test" >
      <div className="article-cards-group__wrapper">
        <Container>
          <Row>
            <Col lg={'12'} className="article-cards-group__title-col">
              <h3 className="article-cards-group__title">{title}</h3>
              {allArticlesLink.url && allArticlesLink.url.length && !isMobile && 
                <Link to={allArticlesLink.url} textLink={true} underlined={true} variant='black' label={allArticlesLink.label} className="article-cards-group__all-articles-link">
                  <Icon name={'arrow-right'} />
                </Link>}
              {description && description.length > 0 && <p className="article-cards-group__description">{description}</p>}
            </Col>
          </Row>
          <Row xs='1' md={'2'} lg={smallCards ? '3' : '2'} className="article-cards-group__cards-row gy-4">
            {!isMobile && cards.map((card, index) => {
              const showTwitter = card.showTwitter;
              if (cardHeight && showTwitter) {
                return (
                  <Col key={index} className="article-cards-group__twitter-col" data-testid="twitter-card-test">
                    <Card body className="article-card">
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="Qiwa_sa"
                        noBorders={true}
                        options={{height: cardHeight ? cardHeight : 524}}
                      />
                    </Card>
                  </Col>
                )                
              } else {
                return (
                  <Col key={index}>
                    <ArticleCard title={card.title} description={card.description} cta={card.cta} image={card.image} smallCard={false} />
                  </Col>
                )
              }
            })}
            {isMobile && !smallCards && cards.map((card, index) => {
              const showTwitter = card.showTwitter;
              if (cardHeight && showTwitter) {
                return (
                  <Col key={index} className="article-cards-group__twitter-col" data-testid="twitter-card-test">
                    <Card body className="article-card">
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="Qiwa_sa"
                        noBorders={true}
                        options={{height: cardHeight ? cardHeight : 524}}
                      />
                    </Card>
                  </Col>
                )                
              } else {
                return (
                  <Col key={index}>
                    <ArticleCard title={card.title} description={card.description} cta={card.cta} image={card.image} smallCard={false} />
                  </Col>
                )
              }
            })}
            {isMobile && smallCards && <Carousel indicators={true} wrap={false} controls={false} interval={null}>
              {cards.map((card, index) => {
                return (
                  <Carousel.Item key={index}>
                    <ArticleCard title={card.title} description={card.description} cta={card.cta} image={card.image} smallCard={true} />
                  </Carousel.Item>
                )
              })}
            </Carousel>}
            {allArticlesLink.url && allArticlesLink.url.length && isMobile && <Link label='Go to all articles' textLink={true} underlined={true} variant='black' className="article-cards-group__all-articles-link" to="/"><Icon name={'arrow-right'}/></Link>}
          </Row>
        </Container>
      </div>
    </div>
  )
};

ArticleCardsGroup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      cta: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string
      }),
      image: PropTypes.string,
      showTwitter: PropTypes.bool
    })
  ),
  smallCards: PropTypes.bool,
  allArticlesLink: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string
  })
};

ArticleCardsGroup.defaultProps = {
  title: 'Default title',
  description: 'Default description',
  cards: [],
  smallCards: false,
  allArticlesLink: false
}

export default ArticleCardsGroup;