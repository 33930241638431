import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import Link from "../Link/Link";
import { MainContext } from "../contexts/context";

import "./Banner.scss";

import heroImageENG from "./about-qiwa-hero-english.png";
import heroImageAR from "./about-qiwa-hero-arabic.png";
import heroImage from "./about-qiwa-hero.png";
import bodyImage from "./img/body-image-desktop.png";
import bodyImageAr from "./img/body-image-desktop-ar.png";
import bodyMobileImage from "./img/body-mobile.png";
import bodyMobileImageAr from "./img/body-mobile-image-ar.png";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getSignInLink } from "../../utils/getSignInLink";

const Banner = ({ variant }) => {
  const { isMobile, theme } = useContext(MainContext);
  const navigate = useNavigate();
  const { t: t1 } = useTranslation("translation", { keyPrefix: "landing" });
  const { t: t2 } = useTranslation("translation", { keyPrefix: "bannerBody" });
  const { t: t3 } = useTranslation("translation", { keyPrefix: "visitOur" });
  
  const env = process.env.REACT_APP_NODE_ENV;

  const signInLink = getSignInLink(env, theme);

  const ctaSubLinks = [
    {
      url: "/service-overview/business-owners/hire-employees/instant-work-visas",
      label: t1("instantWorkVisas"),
    },
    {
      url: "/service-overview/employees/manage-your-current-job/employment-contracts",
      label: t1("employmentContracts"),
    },
    {
      url: "/service-overview/business-owners/manage-establishment/internal-work-policy",
      label: t1("internalWorkPolicy"),
    },
    {
      url: "/service-overview/business-owners/manage-establishment/certificates",
      label: t1("certificates"),
    },
    {
      url: "/service-overview/business-owners/manage-current-employees/training-management",
      label: t1("trainingManagement"),
    },
  ];

  const variants = {
    hero: "banner-component--hero-variant",
    "grey-dots": "banner-component--grey-dots",
    body: "banner-component--body-variant",
  };

  return (
    <div
      className={`banner-component ${variants[variant]}`}
      data-testid="banner-test"
    >
      {variant === "hero" && (
        <Container>
          <div className="banner-component__main-title">
            <h2>
              <Trans
                i18nKey="banner.title"
                t={t1}
                components={[<span key="highlighted-text"></span>]}
              />
            </h2>
          </div>
          <div className="banner-component__sub-title">
            <p>{t1("banner.description")}</p>
          </div>
          <div className="banner-component__cta-links-wrapper">
            <Link
              textLink={false}
              to={signInLink}
              className="banner-component__cta-link banner-component__cta-link--business"
            >
              {t1("banner.left_button")}
            </Link>
            <Link
              textLink={false}
              to={signInLink}
              className="banner-component__cta-link banner-component__cta-link--individuals"
            >
              {t1("banner.right_button")}
            </Link>
          </div>
          <div className="banner-component__main-image banner-component__main-image--hero">
            <img
              src={theme === "arabic" ? heroImageAR : heroImageENG}
              alt="hero"
            />
          </div>
        </Container>
      )}
      {variant === "grey-dots" && (
        <Container>
          <h2 className="banner-component__main-title">{t3("title")}</h2>
          <p className="banner-component__sub-title">{t3("description")}</p>
          <div className="banner-component__cta-links-wrapper">
            <button
              className="banner-component__cta-link banner-component__cta-link--business"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem(
                    "i18nextLng"
                  )}/services?persona=businessowners`
                )
              }
            >
              {t3("guidesForBusiness")}
            </button>
            <button
              className="banner-component__cta-link banner-component__cta-link--individuals"
              onClick={() =>
                navigate(
                  `/${localStorage.getItem(
                    "i18nextLng"
                  )}/services?persona=employees`
                )
              }
            >
              {t3("guidesForEmployees")}
            </button>
          </div>
          <div className="banner-component__cta-sublinks-block">
            <h6>{t3("popularTopics")}</h6>
            <div className="banner-component__cta-sublinks-wrapper">
              {ctaSubLinks.map((link, index) => {
                return (
                  <Link
                    textLink={true}
                    underlined={true}
                    variant="business"
                    label={link.label}
                    key={index}
                    to={link.url}
                  ></Link>
                );
              })}
            </div>
          </div>
        </Container>
      )}
      {variant === "body" && (
        <Container>
          <Row className="banner-component__content-row">
            <Col lg="6" className="banner-component__content-wrapper">
              <h2 className="banner-component__main-title">
                <Trans
                  i18nKey="title"
                  t={t2}
                  components={[<span key="highlighted-text"></span>]}
                />
              </h2>
              <p className="banner-component__sub-title">{t2("description")}</p>
              <Link
                textLink={false}
                to={signInLink}
                className="banner-component__cta-link banner-component__cta-link--business"
              >
                {t2("tryNow")}
              </Link>
            </Col>
            <div className="banner-component__main-image banner-component__main-image--body banner-component__main-image__bottom">
              {isMobile ? (
                <img
                  className="banner-component__main-image__main-image-position-mobile"
                  src={theme === "arabic" ? bodyMobileImageAr : bodyMobileImage}
                  alt="body mobile background"
                />
              ) : (
                <img
                  className="banner-component__main-image__main-image-position"
                  src={theme === "arabic" ? bodyImageAr : bodyImage}
                  alt="body background"
                />
              )}
            </div>
          </Row>
        </Container>
      )}
    </div>
  );
};

Banner.propTypes = {
  variant: PropTypes.oneOf(['hero', 'grey-dots', 'body']),
};

export default Banner;
