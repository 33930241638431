import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../../contexts/context";
import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import Link from "../../../../Link/Link";
import Feedback from "../../../../Feedback/Feedback";
import Banner from "../../../../Banner/Banner";
import WorkPermitCalcContainer from "../../../../WorkPermitCalc/WorkPermitCalcContainer";
import useNavigationHistory from "../../../../../hooks/useNavigationHistory";
import { extractSpecialUrlsFromHistory } from "../../../../../utils/extractSpecialUrlsFromHistory";

import "./WorkPermitCalcPage.scss";

const WorkPermitCalcPage = () => {
  const [showNote, setShowNote] = useState(false);
  const { setHeaderData, headerData, isMobile, isTablet } =
    useContext(MainContext);
  const { t } = useTranslation();
  const navigationHistory = useNavigationHistory();
  const specialUrls = extractSpecialUrlsFromHistory(navigationHistory);

  useEffect(() => {
    setHeaderData({ ...headerData, variant: "light" });
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      setHeaderData({ ...headerData, variant: "dark" });
    };
  }, [setHeaderData]);

  return (
    <div className="wp-calc-page" data-testid="wp-calc-page-test">
      <Container>
        <Breadcrumbs theme="dark">
          {specialUrls?.toolsAndCalculators && (
            <Link
              to={specialUrls?.toolsAndCalculators}
              label={t("breadcrumbs.tools")}
            />
          )}
          <span>{t("breadcrumbs.wpCalc")}</span>
        </Breadcrumbs>
        <WorkPermitCalcContainer showNote={setShowNote} />
      </Container>
      {showNote && (
        <div className="wp-calc-container__note">
          <Container>
            <ul className="wp-calc-container__note-list">
              <p>
                <span className="wp-calc-container__note-list__header">
                  {isMobile || isTablet
                    ? t("wpCalc.note.boldListHeaderMobile")
                    : t("wpCalc.note.boldListHeader")}
                </span>
                {t("wpCalc.note.regularListHeader")}
              </p>
              <li>{t("wpCalc.note.listItem1")}</li>
              <li>{t("wpCalc.note.listItem2")}</li>
            </ul>
            <p className="wp-calc-container__note-footer">
              {t("wpCalc.note.footerNote")}{" "}
              <Link
                underlined={true}
                to="/service-overview/business-owners/hire-employees/work-permits"
                label={t("wpCalc.note.footerNoteLink")}
              />
            </p>
          </Container>
        </div>
      )}
      <Feedback />
      <Banner variant={"body"} />
    </div>
  );
};

export default WorkPermitCalcPage;
