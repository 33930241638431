import React, { useRef, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../Loader/Loader";
import axios from "axios";
import { useForm } from "react-hook-form";
import WrapperRadioGroup from "./RadioGroup/WrapperRadioGroup";
import RangeInput from "./RangeInput/RangeInput";
import { mockedData } from "./mockedData";
import { useTranslation } from "react-i18next";

import { NumericField, Button, Message, Box, Text } from "@takamol/qiwa-design-system/components";
import Icon from "../Icon/Icon";

export const submitCalcData = (
  data,
  setData,
  showErrorMsg,
  setLoading,
  setErrorMsg
) => {
  const businessOwner =
    data.businessOwner === "No" ||
    data.businessOwner === "لا" ||
    data.businessOwner === ""
      ? 0
      : 1;
  const url =
    `${process.env.REACT_APP_FULL_URL}/work_permit_calculator/api-request?` +
    "Saudi=" +
    data.saudisEmployee +
    "&" +
    "NonSaudi=" +
    data.nonSaudisEmployee +
    "&" +
    "TotalEmployeesCount=" +
    Number(data.saudisEmployee + data.nonSaudisEmployee) +
    "&" +
    "ExtraFinancialCompensation=" +
    0 +
    "&" +
    "TwinFinancialCompensation=" +
    0 +
    "&" +
    "SpecialForeigners=" +
    data.privateExpatriate +
    "&" +
    "ExpulsionExemptedLaborers=" +
    data.exemptFromDeportation +
    "&" +
    "DisplacedTribes=" +
    data.displacedTribes +
    "&" +
    "RecruitmentCompaniesWorkers=" +
    data.recruitmentCompanies +
    "&" +
    "OwnerRegisteredInGOSI=" +
    businessOwner +
    "&" +
    "GulfLaborers=" +
    data.gulfCooperationCouncil +
    "&" +
    "FarmersLaborers=" +
    data.farmers +
    "&" +
    "RequestedPeriod=" +
    12;

  return axios
    .post(url)
    .then((resp) => {
      if (resp.status === 200) {
        const { Header, Body } = resp.data.PublicWorkPermitCalculatorRs;
        if (Header.ResponseStatus.Status === "SUCCESS") {
          setLoading(false);
          setData({
            ...Body,
            businessOwner,
            totalEmployee: Body.SaudiCount + Body.NonSaudiCount + businessOwner,
          });
          showErrorMsg(false);
          return resp.data;
        } else if (Header.ResponseStatus.Status === "ERROR") {
          setLoading(false);
          setErrorMsg(Header.ResponseStatus.EnglishMsg);
          showErrorMsg(true);
          console.log(
            "Server responded with ERROR:",
            Header.ResponseStatus.EnglishMsg
          ); 
          return resp;
        }
      } else {
        setLoading(false);
        showErrorMsg(true);
        console.log('Unexpected response status:', resp.status); 
        return resp;
      }
    })
    .catch((err) => {
      setLoading(false);
      showErrorMsg(true);
      console.log('Error caught:', err); 
      return err;
    });
};

const WorkPermitCalc = ({ setData }) => {
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [businessOwner, setBusinessOwner] = useState("");
  const { t } = useTranslation("translation", { keyPrefix: "wpCalc.calc" });
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [defaultError, setDefaultError] = useState(t("defaultError"));
  const [loading, setLoading] = useState(false);
  const [hasSaudisChanged, setHasSaudisChanged] = useState(false);
  const [hasNonSaudisChanged, setHasNonSaudisChanged] = useState(false);
  const recaptchaRef = useRef(null);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displacedTribes: 0,
      exemptFromDeportation: 0,
      farmers: 0,
      gulfCooperationCouncil: 0,
      nonSaudisEmployee: null,
      privateExpatriate: 0,
      recruitmentCompanies: 0,
      saudisEmployee: null,
    },
  });

  useEffect(() => {
    if (recaptchaRef) {
      recaptchaRef.current.captcha
        ? (recaptchaRef.current.captcha.style = "margin-bottom: 24px;")
        : (recaptchaRef.current.style = "margin-bottom: 24px;");
    }
  }, []);

  useEffect(() => {
    if (totalEmployee > 9) {
      setValue("businessOwner", "");
      setBusinessOwner("");
    }
  }, [totalEmployee]);

  const onEmployeeInput = (e, id) => {
    let value = !!e.target.value && e.target.value >= 0 ? e.target.value : null;
    setValue(id, value);
    if (id === 'saudisEmployee') {
      setHasSaudisChanged(true)
    }
    if (id === 'nonSaudisEmployee') {
      setHasNonSaudisChanged(true)
    }
    const saudis = getValues("saudisEmployee");
    const nonSaudis = getValues("nonSaudisEmployee");
    const limit = getLimit();
    setTotalEmployee((saudis ? saudis : 0) + (nonSaudis ? nonSaudis : 0));

    if (limit === 0) {
      clearErrors("limitReached");
    } else if (limit < 0) {
      setError("limitReached", {
        type: "custom",
        message: t("bottomBlock.errors.limitReached"),
      });
    } else {
      clearErrors("limitReached");
    }
  };

  const onRadioChange = (e) => {
    setValue("businessOwner", e);
    setBusinessOwner(e);
    clearErrors("businessOwner");
  };

  const onSubmit = () => {
    setLoading(true);
    setShowErrorMsg(false);
    if (getValues("farmers") > 6) {
      setError("farmersCountExceedLimit", {
        type: "custom",
        message: t("bottomBlock.errors.farmersCountExceedLimit"),
      });
      setLoading(false);
    } else {
      clearErrors("farmersCountExceedLimit");
      setLoading(false);
    }
    if (getLimit() < 0) {
      setError("totalCountExceedLimit", {
        type: "custom",
        message: t("bottomBlock.errors.totalCountExceedLimit"),
      });
      setLoading(false);
    } 
    if( getLimit() >= 0) {
      clearErrors("totalCountExceedLimit");
      setLoading(false);
    }
    if (totalEmployee === 0 && hasNonSaudisChanged && hasSaudisChanged) {
      setError("totalEmployees", {
        type: "custom",
        message: t("bottomBlock.errors.totalEmployees"),
      });
      setLoading(false);
    } else {
      clearErrors("totalEmployees");
      setLoading(false);
    }
    if (recaptchaRef.current &&
      !(recaptchaRef.current.getValue() &&
      recaptchaRef.current.getValue().length)
    ) {
      setError("captchaError", {
        type: "custom",
        message: t("bottomBlock.errors.captchaError"),
      });
      setLoading(false);
    } else {
      clearErrors("captchaError");
      setLoading(false);
    }
    if (recaptchaRef.current &&
      recaptchaRef.current.getValue() &&
      recaptchaRef.current.getValue().length &&
      totalEmployee > 0 &&
      getValues("farmers") < 6 &&
      getValues("farmers") >= 0 &&
      getLimit() >= 0
    ) {
      submitCalcData(
        getValues(),
        setData,
        setShowErrorMsg,
        setLoading,
        setDefaultError
      );
    }
  };
  
  const onErrors = (errors) => {
    if (errors.totalEmployees && totalEmployee !== 0) {
      clearErrors("totalEmployees");
    } 
    if (errors.totalCountExceedLimit && getLimit() >= 0) {
      clearErrors("totalCountExceedLimit");
    }
    // } else if (
    //   errors.captchaError &&
    //   recaptchaRef.current.getValue() &&
    //   recaptchaRef.current.getValue().length
    // ) {
    //   clearErrors("captchaError");
    // }
  };


  const getLimit = (excludeId) => {
    const data = getValues();
    const nonSaudis = data.nonSaudisEmployee;
    let currentTotal = 0;
    mockedData.nonSaudisInputs.forEach((input) => {
      currentTotal =
        excludeId && excludeId === input.id
          ? currentTotal
          : currentTotal + data[input.id];
    });
    let result = nonSaudis - currentTotal;
    return result;
  };

  return (
    <div
      className="certificate-validation-tool__calc-content wp-calculator"
      data-testid="wp-calc-test"
    >
      {loading && (
        <div className="certificate-validation-tool__loader">
          <Loader />
        </div>
      )}
      <Form
        onSubmit={handleSubmit(onSubmit, onErrors)}
        data-testid="certificate-calc-form"
      >
        <div className="wp-calculator__top-block">
          <p>{t("topBlock.title")}:</p>
          {errors.totalEmployees && (
            <p className="certificate-validation-tool__error-msg">
              <Icon name={"info"} width="20" height="20" />{" "}
              {t("bottomBlock.errors.totalEmployees")}
            </p>
          )}
          <div className="wp-calculator__top-inputs-wrapper">
            <Form.Group
              className="wp-calculator__top-control"
              controlId="saudisEmployee"
            >
              <Form.Label className={`wp-calculator__top-control__${errors.nonSaudisEmployee && "non-saudi-error"}`}>{t("topBlock.saudiLabel")}</Form.Label>
              <NumericField
                {...register("saudisEmployee", {
                  valueAsNumber: true,
                  min: 0,
                  required: true,
                })}
                fieldWidth={"100%"}
                min={0}
                isError={errors.saudisEmployee || errors.totalEmployees}
                errorMessage={t("bottomBlock.errors.captchaError")}
                onChange={(e) => {
                  onEmployeeInput(e, "saudisEmployee");
                }}
                data-testid="wp-calc-saudis-input-test"
                onKeyDown={(e) =>
                  e.code === "Minus" || e.code === "Equal"
                    ? e.preventDefault()
                    : null
                }
              />
            </Form.Group>
            <Form.Group
              className="wp-calculator__top-control"
              controlId="nonSaudisEmployee"
            >
              <Form.Label className={`wp-calculator__top-control__${errors.saudisEmployee && "saudi-error"}`}>{t("topBlock.nonSaudiLabel")}</Form.Label>
              <NumericField
                {...register("nonSaudisEmployee", {
                  valueAsNumber: true,
                  min: 0,
                  required: true,
                })}
                fieldWidth={"100%"}
                min={0}
                isError={errors.nonSaudisEmployee  || errors.totalEmployees}
                errorMessage={t("bottomBlock.errors.captchaError")}
                onChange={(e) => {
                  onEmployeeInput(e, "nonSaudisEmployee");
                }}
                data-testid="wp-calc-non-saudis-input-test"
                onKeyDown={(e) =>
                  e.code === "Minus" || e.code === "Equal"
                    ? e.preventDefault()
                    : null
                }
              />
            </Form.Group>
          </div>
          <p
            className="wp-calculator__total-employee"
            data-testid="total-employee-value-test"
            data-totalvalue={totalEmployee}
            dangerouslySetInnerHTML={{
              __html: t("topBlock.totalEmployee", {
                interpolation: { escapeValue: false },
                totalEmployee
              }),
            }}
          ></p>
        </div>

        {totalEmployee > 0 && totalEmployee < 10 && (
          <div className="wp-calculator__mid-block">
            <h6 className="wp-calculator__block_title">
              {t("midBlock.title")}
            </h6>
            <p className="wp-calculator__block_description">
              {t("midBlock.description")}
            </p>
            {errors.businessOwner && (
              <p className="certificate-validation-tool__error-msg">
                <Icon name={"info"} width="20" height="20" />{" "}
                {t("bottomBlock.errors.businessOwner")}
                {t("bottomBlock.errors.businessOwner")}
              </p>
            )}
            <div className="wp-calculator__radio-inputs-wrapper">
              <WrapperRadioGroup
                name="businessOwner"
                label={t("midBlock.description")}
                control={control}
                options={[t("midBlock.options.yes"), t("midBlock.options.no")]}
                onRadioChange={onRadioChange}
                selectedValue={businessOwner}
                requiredMessage={t("bottomBlock.errors.businessOwner")}
                errors={errors}
              />
            </div>
          </div>
        )}

        <div className="wp-calculator__bottom-block">
          <h6 className="wp-calculator__block_title">
            {t("bottomBlock.description")}
          </h6>
          <Message
              variant={errors?.totalCountExceedLimit ? "danger" : "info"}
            >
              <Box>
                <Text variant="body-m">
                  {t("bottomBlock.errors.totalCountExceedLimit")}
                </Text>
              </Box>
            </Message>
          {mockedData.nonSaudisInputs.map((input, index) => (
            <RangeInput
              getValues={getValues}
              setValue={setValue}
              register={register}
              key={index}
              farmersCountExceedLimit={errors?.farmersCountExceedLimit}
              totalCountExceedLimit={errors?.totalCountExceedLimit}
              {...input}
            />
          ))}
        </div>

        <Form.Group>
          {errors.captchaError && (
            <p className="certificate-validation-tool__error-msg">
              <Icon name={"info"} width="20" height="20" />{" "}
              {t("bottomBlock.errors.captchaError")}
            </p>
          )}
          <ReCAPTCHA
            sitekey="6Ld_4nYdAAAAAMpoTic6J_BuaYLtT8GSb0zaIS9L" // from .env file on BE side
            ref={recaptchaRef}
            onChange={(value) => {
              if (value.length) {
                clearErrors("captchaError");
              }
            }}
            onExpired={() => console.log('expired captcha')}
          />
        </Form.Group>
        {showErrorMsg && (
          <p className="certificate-validation-tool__error-msg">
            <Icon name={"info"} width="20" height="20" /> {defaultError}
          </p>
        )}
        <Button
          variant="business_primary_filled"
          type="submit"
          data-testid="wp-calc-submit-test"
        >
          {t("bottomBlock.submit")}
        </Button>
      </Form>
    </div>
  );
};

WorkPermitCalc.defaultProps = {
  setData: () => {
    return;
  },
};

export default WorkPermitCalc;
