import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";

import './NavCard.scss';

const NavCard = ({variant, title, description, icon}) => {
  return (
    <div className={`nav-card nav-card--${variant}`}>
      <div className="nav-card__icon-container">
        <Icon name={icon} />
      </div>
      <h6 className="nav-card__title">{title}</h6>
      <p className="nav-card__description">{description}</p>
    </div>
  )
};

NavCard.propTypes = {
  variant: PropTypes.string, 
  title: PropTypes.string, 
  description: PropTypes.string,
  icon: PropTypes.string,
};

NavCard.defaultProps = {
  variant: 'business',
  title: '',
  description: '',
  icon: 'user'
}

export default NavCard;