import React, { useState, useEffect, useContext } from "react";
import "./LearnMore.scss";
import Icon from "../../../../Icon/Icon";
import classnames from "classnames";
import { MainContext } from "../../../../contexts/context";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const LearnMore = ({ data, title }) => {
  const { theme } = useContext(MainContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 375);
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isExternalLink = (link) => {
    return link.startsWith("https://");
  };
  const rewriteUrlsForEnvironmentAndLanguage = (text) => {
    const environment = process.env.REACT_APP_NODE_ENV;
    let baseUrl;
  
    // Determine the base URL based on the environment
    switch (environment) {
      case "development":
        baseUrl = "https://auth.qiwa.tech";
        break;
      case "stage":
        baseUrl = "https://auth.qiwa.info";
        break;
      case "uat":
        baseUrl = "https://auth.uat.qiwa.info";
        break;
      default:
        baseUrl = "https://auth.qiwa.sa"; 
        break;
    }
  
    const language = localStorage.getItem("i18nextLng");
    const languagePath = language === 'ar' ? 'ar' : 'en'; 
  
    const pattern = new RegExp(`https:\/\/(auth)\\.(qiwa\\.tech|qiwa\\.info|uat\\.qiwa\\.info|qiwa\\.sa)\/(en|ar)?`);
  
    const newUrl = text.replace(pattern, ( subdomain) => {

      return `${baseUrl.replace('sso', subdomain)}/${languagePath}`;
    });
  
    return newUrl;
  };
  
  
  

  const generateHref = (link) => {
    if (isExternalLink(link)) {
      return rewriteUrlsForEnvironmentAndLanguage(link);
    } else {
      return `/${localStorage.getItem("i18nextLng")}${link}`;
    }
  };

  return (
    <div
      className={classnames({
        "learn-more": true,
        "learn-more--ltr": theme === "english",
        "learn-more--rtl": theme !== "english",
      })}
    >
      {isMobile ? (
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="learn-more-header">
              <h2 className="header-text">{t("learnMore")}</h2>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {data?.questions?.map((question, index) => (
                  <li key={index}>
                    <a
                      href={`/${localStorage.getItem("i18nextLng")}${
                        question?.link
                      }`}
                    >
                      <Icon name="link-arrow" className="learn-more__icon" />
                      {question.text}
                    </a>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div>
          <hr />
          <div className="learn-more-header">
            <h2 className="header-text">{t("learnMore")}</h2>
          </div>
          <ul>
            {data?.questions?.map((question, index) => (
              <li key={index}>
                <a
                  href={generateHref(question?.link)}
                  rel={
                    isExternalLink(question?.link) ? "noopener noreferrer" : ""
                  }
                  target={isExternalLink(question?.link) ? "_blank" : ""}
                >
                  <Icon name="link-arrow" className="learn-more__icon" />
                  {question.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

LearnMore.propTypes = {
  data: PropTypes.shape({
    questions: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string
    }))
  }), // Data is expected to have a structure with questions that have links and text
  title: PropTypes.string // Title is a string, but it is not actually used in your component. Consider removing if unnecessary.
};

export default LearnMore;
