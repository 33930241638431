import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ServiceOverviewPage.scss";
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs";
import Link from "../../../Link/Link";
import transformHeroTitle from "../transformNameToURL";
import { useTranslation } from "react-i18next";
import { findLast } from "lodash";
import { Button } from "@takamol/qiwa-design-system/components";

interface HeroSectionProps {
  title: string;
  breadcrumbs: any;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  breadcrumbs,
}): React.ReactNode => {
  const lastItemOfBreadcrumb: any = findLast(breadcrumbs);
  const currentBreadcrumbs = [lastItemOfBreadcrumb, title];
  const transformedHeroTitle = transformHeroTitle(lastItemOfBreadcrumb?.link);
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const getBaseUrl = () => {
    switch (process.env.REACT_APP_NODE_ENV) {
      case "development":
        return "https://sso.qiwa.tech";
      case "stage":
        return "https://sso.qiwa.info";
      case "uat":
        return "https://sso.uat.qiwa.info";
      default:
        return "https://sso.qiwa.sa"; 
    }
  };

  const baseUrl = getBaseUrl();
  return (
    <div className="service-overview-hero-section">
      <Container data-testid="service-overview-hero-section">
        <Row>
          <Col lg={12} className=" ml-auto">
            <Breadcrumbs theme="light" Breadcrumbs={breadcrumbs}>
            {breadcrumbs?.slice(1, -1).map((item: any, index: any) => (
            <Link
              key={index}
              to={item?.url}
              label={item?.title}
            />
          ))}
          {breadcrumbs?.length > 0 && (
            <span>{breadcrumbs[breadcrumbs.length - 1]?.title }</span>
          )}
            </Breadcrumbs>
            <div className="service-overview-hero-section__headline">
              {title}
            </div>
              <div className="service-overview-hero-section__group">
                <Link
                  to={`${baseUrl}/${localStorage.getItem(
                    "i18nextLng"
                  )}/sign-in`}
                  className="service-overview-hero-section__button-link"
                >
                  {t("tryServiceOverview")}
                </Link>
              </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
