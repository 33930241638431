import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const DesktopToc = ({headings, location}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "article",
  });
  return (
    <div data-testid="desktop-toc-test">
      <h4>{t("tableOfContent")}</h4>
      <ol>
        {headings?.map((h, index) => {
          return (
            <li key={index}>
              <a className="table-of-contents__first-level-link" href={"#" + h?.id}>
                {/* {h?.text} */}
              </a>
              {h?.subLinks?.length > 0 && <ul>
                {h?.subLinks?.map((s, i) => {
                  let link = '#' + s.id;
                  return (
                    <li key={i} className={location?.hash === link ? 'active-toc' : ''}>
                      <a className={`table-of-contents__second-level-link`} href={link}>
                        {s?.text}
                      </a>
                    </li>
                  )
                })}
              </ul>}
            </li>
          )
        })}
      </ol>
    </div>
  )
}

DesktopToc.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string, 
    text: PropTypes.string,
    subLinks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string, 
      text: PropTypes.string,
    }))
  })), 
  location: PropTypes.shape({
    hash: PropTypes.string 
  })
};

DesktopToc.defaultProps = {
  headings: [],
  location: {
    hash: ''
  }
}

export default DesktopToc;