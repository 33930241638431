import React, { useContext, useEffect } from "react";
import Hero from "../../Hero/Hero";
import ActionTilesGroup from "../../ActionTilesGroup/ActionTilesGroup";
import NumbersBlock from "../../NumbersBlock/NumbersBlock";
import { MainContext } from "../../contexts/context";
import HomeTab from "./HomeTabs/HomeTab";
import useFetchContent from "../../../hooks/useFetchContent";

import "./Home.scss";

export default function Home() {
  const contextValue = useContext(MainContext);

  const { toolsAndCalculators, laborSectorNumbers } =
    contextValue;
  const { contentByType } = useFetchContent();  

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div data-testid="homepage-test">
      <Hero />
      <HomeTab />
      <div className="tools-block">
        {toolsAndCalculators && (
          <ActionTilesGroup
            title={toolsAndCalculators.title}
            description={toolsAndCalculators.description}
            cards={toolsAndCalculators.cards}
            useAccordion={false}
          />
        )}
        {contentByType?.tiles &&
          contentByType.tiles.map((tileContent, index) => (
            <ActionTilesGroup
              key={index}
              title={tileContent.title}
              titleLink={true}
              description={tileContent.subtitle}
              cards={tileContent.items?.slice(0, 3)}
              groupCta={tileContent.allArticlesLink}
              useAccordion={false}
            />
          ))}
      </div>
      {laborSectorNumbers && (
        <NumbersBlock
          title={laborSectorNumbers.title}
          tabs={laborSectorNumbers.tabs}
          image={laborSectorNumbers.image}
        />
      )}
    </div>
  );
}
