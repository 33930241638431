import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { MainContext } from "../../contexts/context";
import SearchFilters from "../../Search/GlobalSearch/SearchFilters.tsx";
import SearchResults from "../../Search/GlobalSearch/SearchResults.tsx";
import fetchResults from "../../Search/SearchApi.js";
import { useSearchParams } from "react-router-dom";
import Pager from "../../Pager/Pager.tsx";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import "./SearchPage.scss";
import { Search, Select } from "@takamol/qiwa-design-system/components";
import Feedback from "../../Feedback/Feedback.jsx";

type TopicsType = {
  [key: string]: string;
};

type ParamsType = {
  items_per_page: number;
  materials: string;
  page: number;
  sort_by: string;
  topics: TopicsType;
  show_more: number;
};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const SearchPage = (): React.ReactNode => {
  const [query, setQuery] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState(false);
  const [resultData, setResultData] = useState<any>({});
  const [materials, setMaterials] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activeMaterial, setActiveMaterial] = useState("!");
  const [activePage, setActivePage] = useState(0);
  const [activeSort, setActiveSort] = useState("relevance_descending");
  const [topicsSelected, setTopicsSelected] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showMoreServices, setShowMoreServices] = useState<number>(0);
  const {
    setHeaderData,
    headerData,
    isTablet,
    isMobile,
    searchUpdateValue,
    setSearchUpdateValue,
  } = useContext(MainContext);
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const resultsRef = useRef<Element>(null);

  const { handleSubmit, getValues, setValue } = useForm({
    defaultValues: { searchQuery: "" },
  });

  useEffect(() => {
    setHeaderData({ ...headerData, variant: "light" });
    window.scrollTo(0, 0);

    return () => {
      setHeaderData({ ...headerData, variant: "dark" });
    };
  }, [setHeaderData]);


  const fetchData = async (searchQuery: string | null) => {
    setIsLoading(true);

    const params: ParamsType = {
      items_per_page: itemsPerPage,
      materials: activeMaterial === "!" ? `!()` : activeMaterial,
      page: activePage,
      sort_by: activeSort,
      topics: {},
      show_more: showMoreServices,
    };

    topicsSelected.forEach((topic, index) => {
      params.topics[`f[${index + 1}]`] = "topics:" + topic;
    });

    try {
      const data: any = await fetchResults(
        searchQuery ? searchQuery : "",
        params
      );

      setIsLoading(false);

      if (data) {
        setResultData(data);
        setQuery(searchQuery);

        if (data.facets_metadata) {
          const materialsMetadata = data.facets_metadata.materials;
          delete materialsMetadata[0];
          delete materialsMetadata.url_alias;
          delete materialsMetadata.weight;
          const materialsValues: never[] = Object.values(materialsMetadata);
          setMaterials(materialsValues);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setValue("searchQuery", searchParams.get("q") || "");
  }, [searchParams.get("q")]);

  useEffect(() => {
    fetchData(searchParams.get("q") || "").then(() => {
      const resultsEl = resultsRef.current;
      if (resultsEl && isMobile) {
        resultsEl.scrollIntoView({ behavior: "instant", block: "start" });
      }
    });
  }, [searchUpdateValue, localStorage.getItem("i18nextLng")]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query) {
        setSearchUpdateValue("");
        setQuery("");
        setSearchParams({ q: "" });
        setResultData({});
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [localStorage.getItem("i18nextLng")]);
  useEffect(() => {
    if (
      searchParams.get("show_all_services") !== null &&
      searchParams.get("show_all_services") === "1"
    ) {
      setShowMoreServices(1);
      setTimeout(() => {
        setSearchUpdateValue(searchUpdateValue + 1);
      }, 50);
    } else {
      setShowMoreServices(0);
    }
  }, [searchParams.get("show_all_services")]);

  useEffect(() => {
    if (searchParams.get("materials")) {
      const parseMaterialParam = (material: string | null) => {
        switch (material) {
          case "am":
            return ["!", "!"];
          case "fbo":
            return ["For Business Owners", "لأصحاب الأعمال"];
          case "fe":
            return ["For Employees", "للموظفين"];
          case "fsp":
            return ["For Service Providers", "لمقدمي الخدمات"];
          case "sls":
            return ["Labor Law", "نظام العمل"];
          default:
            return ["!", "!"];
        }
      };

      const parsedMaterial = parseMaterialParam(searchParams.get("materials"));

      setTimeout(() => {
        onMaterialChange(
          localStorage.getItem("i18nextLng") === "en"
            ? parsedMaterial[0]
            : parsedMaterial[1]
        );
      }, 500);
    }
  }, [searchParams.get("materials")]);

  const scrollToResultsTop = () => {
    const resultsEl = resultsRef?.current;
    if (resultsEl) {
      resultsEl.scrollIntoView({ block: "start", behavior: "instant" });
    }
  };  

  useEffect(() => {
    if (isMobile || isTablet) scrollToResultsTop();
  }, [resultData]);

  const onMaterialChange = (material: string) => {
    setActiveMaterial(material);
    setTopicsSelected([]);
    setActivePage(0);
    setSearchUpdateValue(searchUpdateValue + 1);
  };

  const onTopicsChange = (topics: never[]) => {
    setTopicsSelected(topics);
    setActivePage(0);
    setSearchUpdateValue(searchUpdateValue + 1);
  };

  const onSortChange = (sort: string) => {
    setActiveSort(sort);
    setSearchUpdateValue(searchUpdateValue + 1);
  };

  const onMobileFiltersApply = (data: { material: string; topics: [] }) => {
    setActiveMaterial(data.material);
    setTopicsSelected(data.topics);
    setActivePage(0);
    setSearchUpdateValue(searchUpdateValue + 1);
  };

  const onSearchInput = async (value: any) => {
    setValue("searchQuery", value);
    setSearchParams({ q: value });
  };

  const onSearchSubmit = () => {
    setActivePage(0);
    const data = getValues();
    fetchData(data.searchQuery);

    if (data.searchQuery) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "KC_Search",
        query_content: data.searchQuery,
      });
    }
  };

  const onPageChange = (page: number) => {
    setActivePage(page);
    setSearchUpdateValue(searchUpdateValue + 1);
    scrollToResultsTop();
  };

  const onItemsOnPageChange = (items: number) => {
    setItemsPerPage(items);
    setActivePage(0);
    setSearchUpdateValue(searchUpdateValue + 1);
  };

  return (
    <div className="search-page">
      <div className="search-page__hero">
        <Container>
          <Breadcrumbs theme="dark">
            <span>{t("searchResults")}</span>
          </Breadcrumbs>
          <h2 className="search-page__title">{t("searchResults")}</h2>
          <Row>
            <Col lg="7">
              <div
                className={`hero-search-input__wrapper ${
                  isInputFocused ? "focused" : ""
                }`}
              >
                <Form onSubmit={handleSubmit(onSearchSubmit)}>
                  <Search
                    id="hero-search-input-id"
                    onChange={(e) => {
                      onSearchInput(e.target.value);
                    }}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => {
                      setIsInputFocused(false);
                    }}
                    onClear={() => onSearchInput("")}
                    placeholder={isInputFocused ? "" : t("placeholder")}
                    size="large"
                    value={
                      searchParams.get("q") ? String(searchParams.get("q")) : ""
                    }
                    variant="business"
                    searchButtonLabel={t("search")}
                  />
                </Form>
              </div>
            </Col>
          </Row>
          {(isMobile || isTablet) && (
            <Row>
              <Col>
                <div className="search-page__mobile-filters-wrapper">
                  <Form.Group className="search-page__sort-group">
                    <Form.Label>{t("sortBy")}</Form.Label>
                    <Select
                      id="results-sort-select"
                      data-testid="result-sort-select-test"
                      variant="business"
                      options={[
                        {
                          value: "relevance_descending",
                          option: t("relevance"),
                        },
                        { value: "changed_desc", option: t("newestFirst") },
                        { value: "changed_asc", option: t("oldestFirst") },
                      ]}
                      maxInputWidth={"100%"}
                      onChange={(newValue) => {
                        onSortChange(newValue);
                      }}
                      value={activeSort}
                    />
                  </Form.Group>
                  <SearchFilters
                    materials={materials}
                    response={resultData}
                    setActiveMaterial={onMaterialChange}
                    activeMaterial={activeMaterial}
                    getTopics={onTopicsChange}
                    onMobileFiltersApply={onMobileFiltersApply}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <div className="search-page__content">
        <Container>
          <Row>
            <Col>
              {!isMobile && !isTablet && (
                <SearchFilters
                  materials={materials}
                  response={resultData}
                  setActiveMaterial={onMaterialChange}
                  activeMaterial={activeMaterial}
                  getTopics={onTopicsChange}
                  onMobileFiltersApply={onMobileFiltersApply}
                />
              )}
            </Col>
            <Col lg="8" ref={resultsRef}>
              <SearchResults
                query={query}
                response={resultData}
                loading={isLoading}
                onSortChange={onSortChange}
                activeSort={activeSort}
                showMoreServices={showMoreServices}
              />
              {resultData.pager && (
                <Pager
                  currentPage={resultData.pager.current_page}
                  totalItems={resultData.pager.total_items}
                  totalPages={resultData.pager.total_pages}
                  itemsPerPage={resultData.pager.items_per_page}
                  onItemsChange={onItemsOnPageChange}
                  onPageChange={onPageChange}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Feedback />
    </div>
  );
};

export default SearchPage;
