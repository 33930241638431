import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import Icon from "../Icon/Icon";
import ReactPaginate from "react-paginate";
import toInteger from "lodash/toInteger";
import { useTranslation } from "react-i18next";
import { MainContext } from "../contexts/context";

import { Select } from "@takamol/qiwa-design-system/components";

import "./Pager.scss";

const Pager = ({
  currentPage,
  totalItems,
  totalPages,
  itemsPerPage,
  onPageChange,
  onItemsChange,
}: Props) => {
  const { theme } = useContext(MainContext);
  const { t } = useTranslation("translation", { keyPrefix: "pager" });

  const parseRangeString = (
    currentPage: number,
    itemsPerPage: string,
    totalItems: string
  ) => {
    const startAmount = currentPage === 0 ? "1" : currentPage * 10 + 1;
    let endAmount = currentPage === 0 ? itemsPerPage : (currentPage + 1) * 10;
    endAmount =
      toInteger(totalItems) > toInteger(endAmount) ? endAmount : totalItems;
    return t("rangeItems", { startAmount, endAmount, totalItems: totalItems });
  };

  return (
    <div className="pagination-pager">
      <Form.Group className="pagination-pager__items-select-wrapper">
        <Form.Label>{t("rowsPerPage")}</Form.Label>
        <Select
          id="pager-items-per-page-select"
          data-testid="pager-items-per-page-select-test"
          variant="business"
          options={[
            { value: "10", option: "10" },
            { value: "20", option: "20" },
            { value: "50", option: "50" },
            { value: "100", option: "100" },
          ]}
          maxInputWidth={"95px"}
          onChange={(newValue) => {
            onItemsChange(newValue);
          }}
          value={itemsPerPage}
        />
      </Form.Group>
      <div className="pagination-pager__info-wrapper">
        <div>{parseRangeString(currentPage, itemsPerPage, totalItems)}</div>
        <div>
          {toInteger(totalItems) > toInteger(itemsPerPage) && (
            <ReactPaginate
              nextLabel={
                <span>
                  {t("next")}
                  <Icon
                    className="next-page-icon"
                    name={
                      theme === "english" ? "chevron-right" : "chevron-left"
                    }
                  />
                </span>
              }
              onPageChange={(e) => {
                onPageChange(e.selected);
              }}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={totalPages}
              previousLabel={
                <span>
                  <Icon
                    className="previous-page-icon"
                    name={
                      theme === "english" ? "chevron-left" : "chevron-right"
                    }
                  />
                  {t("previous")}
                </span>
              }
              pageClassName="pagination-pager__page-item"
              pageLinkClassName="pagination-pager__page-link"
              previousClassName="pagination-pager__page-item"
              previousLinkClassName="pagination-pager__page-link"
              nextClassName="pagination-pager__page-item"
              nextLinkClassName="pagination-pager__page-link"
              breakLabel="..."
              breakClassName="pagination-pager__page-item"
              breakLinkClassName="pagination-pager__page-link"
              containerClassName="pagination-pager__pagination"
              activeClassName="pagination-pager__active-page"
              renderOnZeroPageCount={null}
              forcePage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  currentPage: number;
  totalItems: string;
  totalPages: number;
  itemsPerPage: string;
  onPageChange: any;
  onItemsChange: any;
};

export default Pager;
