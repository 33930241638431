import React from "react";
import phonesENG from "./img/phones-english.png";
import phonesAR from "./img/phones-arabic.png";
import Link from "../Link/Link";
import { useTranslation } from "react-i18next";

import './MobileFriendlyBanner.scss'

const MobileFriendlyBanner = ({signInLink}) => {
    const { t } = useTranslation("translation", { keyPrefix: "landing.mobileFriendly" });
    
    return(
        <div className="mobile-banner-wrapper" data-testid="mobile-friendly-banner-test">
            <div className="mobile-banner">
                <img src={localStorage.getItem("i18nextLng") === "ar" ? phonesAR : phonesENG} alt={""} className="mobile-banner__img" />
                <div className="mobile-banner__text-box">
                    <p className="mobile-banner__text-box__title">{t("title")}</p>
                    <h2>{t("description")}</h2>
                    <p className="mobile-banner__text-box__article">
                    {t("secondDescription")}
                    </p>
                    <div className="mobile-banner__text-box__button-wrapper">
                        <Link to={signInLink} className="mobile-banner__text-box__button-wrapper__blue-button">
                            {t("tryForBusiness")}
                        </Link>
                        <Link to={signInLink} className="mobile-banner__text-box__button-wrapper__green-button">
                        {t("tryForEmployees")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileFriendlyBanner;