import React from "react";
import MainMenu from "../MainMenu/MainMenu";

export default function Header({ variant }) {
  return (
    <>
      <MainMenu variant={variant} />
      <div className="dark-overlay" />
    </>
  );
}

Header.defaultProps = {
  variant: "",
};
