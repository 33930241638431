import React, { useState, useContext } from 'react';
import { Container } from 'react-bootstrap';
import Link from '../Link/Link';
import { MainContext } from '../contexts/context';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import './Feedback.scss';

const Feedback = () => {
    const { theme } = useContext(MainContext);
    const { t } = useTranslation('translation', {
        keyPrefix: 'feedback',
    });

    const [feedbackGiven, setFeedbackGiven] = useState(false);

    const handleFeedbackClick = () => {
        setFeedbackGiven(true);
    };
    return (
        <div className="feedback">
            <Container
                data-testid="feedback-test"
                className={classnames({
                    feedback_container: true,
                    'feedback_container--ltr': theme === 'english',
                    'feedback_container--rtl': theme !== 'english',
                })}
            >
                <div className="feedback_container__page-useful">
                    {feedbackGiven ? (
                        <p className="feedback_container__text">{t('afterSubmit')}</p>
                    ) : (
                        <>
                            <p className="feedback_container__text">{t('title')}</p>
                            <div className="feedback_container__actions">
                                <button className="feedback_container__button" onClick={handleFeedbackClick}>
                                {t('yes')}
                                </button>
                                <button className="feedback_container__button" onClick={handleFeedbackClick}>
                                {t('no')}
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <div className="feedback_container__report">
                    <Link to="/contact" className="feedback_container__button feedback_container__report-button">
                    {t('report')}
                    </Link>
                </div>
            </Container>
        </div>
    );
};

export default Feedback;
