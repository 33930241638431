import React from "react";
import "./DidYouKnow.scss";
import image from "../../../../../../images/quotes.png";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';

const DidYouKnow = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <div className="didyouknow">
      <Container fluid className="didyouknow__banner-container">
        <Row>
          <Col lg={6} className="didyouknow__banner-container__heading">{data?.title}</Col>
          <Col lg={6} className="didyouknow__banner-container__quote">
            <div className="didyouknow__banner-container__text">{data?.content}</div>
            <div className="didyouknow__banner-container__icon-container">
              <div className="didyouknow__banner-container__icon">
                <img src={image} alt="Did you know" />
              </div>
            </div>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

DidYouKnow.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,  
    content: PropTypes.string 
  }) 
};

export default DidYouKnow;
