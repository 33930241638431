import React, { useContext } from "react";
import Icon from "../../Icon/Icon";
import Link from "../../Link/Link";
import classnames from "classnames";
import { MainContext } from "../../contexts/context";
import { useTranslation } from "react-i18next";
import { composeSearchResultDate } from "../../../utils/composeDate";

import "./ResultRow.scss";

enum resultTypeIconVariants {
  service = "schedule",
  calculators = "calculator",
  knowledge = "info",
}

type resultTypeIconVariantsKeys = keyof typeof resultTypeIconVariants;

function resultIcon(type: resultTypeIconVariantsKeys) {
  return resultTypeIconVariants[type];
}

const ResultRow = ({
  title,
  materials,
  child_materials,
  kc_article_service,
  kc_article_type,
  type,
  field_saudi_labor_chapter,
  body,
  alias,
  url,
  changed,
  activeSort,
  dateShowOpen,
}: Prop) => {
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const { setShowMobileSearch } = useContext(MainContext);

  const shouldShowDate = activeSort !== "relevance_descending";

  return (
    <Link
      to={alias || url}
      onClick={() => setShowMobileSearch(false)}
      className="search-results__result-link"
    >
      <div className={classnames("result-row", "result-row--" + type)}>
        <Icon className="result-icon" name={resultIcon(type)} />
        <div className="result-row__result-content">
          <h6
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className="result-row__result-content-title"
          />
          <p
            dangerouslySetInnerHTML={{
              __html: body,
            }}
            className="result-row__result-content-excerpt"
          />
          <div className="result-row__result-footer">
            {(materials !== "" || child_materials !== "") && (
              <div
                className={classnames(
                  "result-row__material-tag",
                  child_materials &&
                    child_materials !== "" &&
                    kc_article_type !== "content" &&
                    "result-row__material-tag--persona-material"
                )}
              >
                <Icon
                  name="radio-checkbox"
                  viewBox="0 0 64 64"
                  height="10"
                  width="10"
                  className="result-row__tag-icon"
                />
                <span
                  className={
                    child_materials &&
                    child_materials !== "" &&
                    kc_article_type !== "content"
                      ? "result-row__materials-content result-row__materials-content--persona"
                      : "result-row__materials-content"
                  }
                >
                  {child_materials && child_materials !== ""
                    ? child_materials
                    : materials}
                </span>
              </div>
            )}
            {kc_article_type === "content" && (
              <div className="result-row__definitions-tag">
                {t("definition")}
              </div>
            )}
            {kc_article_service !== "" && (
              <div
                className={classnames(
                  "result-row__service-content",
                  child_materials &&
                    child_materials !== "" &&
                    "result-row__service-content--with-child-materials"
                )}
              >
                <div className="result-row__footer-divider">|</div>
                <div>{kc_article_service}</div>
              </div>
            )}
            {field_saudi_labor_chapter !== "" && (
              <div
                className={classnames(
                  "result-row__service-content",
                  child_materials &&
                    child_materials !== "" &&
                    "result-row__service-content--with-child-materials"
                )}
              >
                <div className="result-row__footer-divider">|</div>
                <div>{field_saudi_labor_chapter}</div>
              </div>
            )}
          </div>
          {type === "knowledge" &&
            changed &&
            changed !== "" &&
            shouldShowDate &&
            dateShowOpen && (
              <div className="result-row__result-footer-date">
                {composeSearchResultDate(changed)}
              </div>
            )}
        </div>
      </div>
    </Link>
  );
};

type Prop = {
  title: string;
  materials: string;
  child_materials: string;
  kc_article_service: string;
  kc_article_type: string;
  type: resultTypeIconVariantsKeys;
  field_saudi_labor_chapter: string;
  nid: number | string;
  body: string;
  alias: string;
  url: string;
  changed?: string;
  activeSort?: string;
  dateShowOpen?: boolean;
};

ResultRow.defaultProps = {
  title: "",
  materials: "",
  child_materials: "",
  kc_article_service: "",
  kc_article_type: "article",
  type: "service",
  field_saudi_labor_chapter: "",
  nid: "",
  alias: "",
  url: "",
  body: "",
  changed: "08-03-2024 14:26",
  dateShowOpen: true,
  activeSort: "relevance_descending",
};

export default ResultRow;
