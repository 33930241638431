import React, { useEffect, useRef, useState } from "react";
import Icon from "../../Icon/Icon";
import Link from "../../Link/Link";
import classnames from "classnames";
import { MainContext } from "../../contexts/context.js";
import HeroSearchInput from "../HeroSearchInput/HeroSearchInput.tsx";
import { useTranslation } from "react-i18next";
import { checkLanguageInUrl } from "../../../utils/checkLanguageInUrl.js";
import { PageLoader } from "../../Loader/PageLoader.tsx";

// Define interfaces for your data structure
interface CategoryItem {
  id: number;
  title: string;
  link: string;
}

interface Category {
  title: string;
  poqItem: CategoryItem[];
}

interface FetchDataResponse {
  categories: Category[];
}

const SearchPopup = ({ onClose, openState }: Prop): React.ReactNode => {
  const { t } = useTranslation("translation", { keyPrefix: "search" });
  const { theme } = React.useContext(MainContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedLanguage = localStorage.getItem("i18nextLng");
        const urlLanguage = checkLanguageInUrl(window.location.pathname);
        const language =
          storedLanguage === urlLanguage ? storedLanguage : urlLanguage;
        let endpoint = `${process.env.REACT_APP_FULL_URL}/api/v1/config`;
        if (language === "ar") {
          endpoint += "?language=ar";
        }
        const response = await fetch(endpoint);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data: any = await response.json();

        setCategories(data.popularOnQiwa.categories);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [theme]);

  useEffect(() => {
    if (openState) {
      closeButtonRef.current?.focus();
    }
  }, [openState]);

  const renderCategoryItems = (items: CategoryItem[]) =>
    items?.map((item) => (
      <li key={item.id}>
        <Link to={item.link} onClick={onClose} label={item.title}></Link>
      </li>
    ));

  return (
    <>
      {openState && (
        <button
          className="search-popup__close-popup-btn search-popup__close-popup-btn--mobile"
          onClick={onClose}
        >
          <Icon className="close-icon" name="close" />
        </button>
      )}
      <div
        className={classnames(
          "navigation-search__search-popup",
          "search-popup",
          openState === true && "search-popup--open"
        )}
      >
        {isLoading && <PageLoader />}
          <>
            <div className="search-popup__hero">
              <div className="search-popup__container">
                <button
                  className="search-popup__close-popup-btn search-popup__close-popup-btn--desktop"
                  onClick={onClose}
                  ref={closeButtonRef}
                >
                  <Icon className="close-icon" name="close" />
                </button>
                <h6 className="search-popup__title">{t("lookingFor")}</h6>
                <HeroSearchInput
                  id="navigation-search-input-id"
                  isSaudiLabor={false}
                />
              </div>
            </div>
            <div className="search-popup__content">
              <div className="search-popup__container">
                <p className="search-popup__content-subtitle">
                  {t("popularOnQiwa")}
                </p>
                {categories?.map((category) => (
                  <div
                    className="search-popup__category-container"
                    key={category.title}
                  >
                    <h4 className="search-popup__category-title">
                      {category.title}
                    </h4>
                    <ul className="search-popup__popular-list">
                      {renderCategoryItems(category.poqItem)}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </>
      </div>
    </>
  );
};

type Prop = {
  onClose: any;
  openState: boolean;
};

SearchPopup.defaultProps = {
  onClose: () => {
    return;
  },
  openState: false,
};

export default SearchPopup;
