import React, { useState, useEffect } from "react";
import WorkPermitCalc from "./WorkPermitCalc";
import WorkPermitResult from "./WorkPermitResult";
import { useTranslation } from "react-i18next";

import './WorkPermitCalc.scss';

const WorkPermitCalcContainer = ({showNote}) => {
  const [calcVisible, setCalcVisible] = useState(true);
  const [resultData, setResultData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (resultData) {
      setCalcVisible(false);
      showNote(true);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [resultData])

  const onReturn = () => {
    setCalcVisible(true);
    showNote(false);
    setResultData(null);
  }

  return (
    <div className="certificate-validation-tool wp-calc-container" data-testid="wp-calc-container-test">
      <div className="certificate-validation-tool__content">
        <div className="certificate-validation-tool__text-content">
          <h2 className="certificate-validation-tool__title">{t('wpCalc.title')}</h2>
          <p className="certificate-validation-tool__description">{t('wpCalc.description')}</p>
        </div>
        {calcVisible && <WorkPermitCalc setData={setResultData}/>}
        {!calcVisible && <WorkPermitResult data={resultData} onReturn={onReturn} />}
      </div>
    </div>
  )
}

export default WorkPermitCalcContainer;